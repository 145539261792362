import { useEffect, useState } from "react";
import classProdSezzione from "../fetch/classProdSezzione";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import InputForm from "../riutilizabili/forms/InputForm";
import Switch from "../riutilizabili/forms/Switch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import QuestionModal from "../riutilizabili/QuestionModal";
import SelezionaSezioneDest from "../prodotti/prodottiSezione/sezioni/SelezionaSezioneDest";
import InfoModal from "../riutilizabili/InfoModal";
import ErrorModal from "../riutilizabili/ErrorModal";
import ViewImgPreUpload from "../riutilizabili/ViewImgPreUpload";
import ImgTabella from "../prodotti/ImgTabella";
import ProdottiSezione from "../sezioni/ProdottiSezione";



export default function AssegnaSezioneProd({
    prodSfusi = false
}) {
    const [open , setOpen] = useState(false);
    const [prods , setProds] = useState([]);
    const [prodsPageTot , setProdsPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)
    const [inputsFiltra , setInputsFiltra] = useState({qtDisp : 1})
    const [inputsSposta , setInputsSposta] = useState(false)
    const [viewSelezionaDest , setViewSelezionaDest] = useState(false)
    const [errorMessage , setErrorMessage] = useState(false)

    useEffect(()=>{
        if (!!prodSfusi) {
            prodDisp({qtDisp : 1 , artNonAllocati : 1})
        } else {
            prodDisp({qtDisp : 1})
        }
    },[])

    const prodDisp = async (params)=> {
        const arrayProds= await classProdSezzione.listaAssProdSezione({pageSize , page ,...params})
        setProds(arrayProds.data.data)
        setProdsPageTot(arrayProds.data.pageTot)
        setFu(fu+1)
    }

    const colonne = [
        {
            id : 1, 
            label : 'Immagine' , 
            nome : 'nome',
            altCol: (item)=> {
                return(
                    <div style={{width: '10rem'}} className='m-auto' >
                      <ImgTabella prodId={item.prodottoId} />
                    </div>
                )
            }
        },
        {
            id : 1, 
            label : 'Nome' , 
            nome : 'nome'
        },
        {
            id : 2, 
            label : 'Codice a barre' , 
            nome : 'codBarre'
        },
        {
            id : 2, 
            label : 'Unità di misura' , 
            nome : 'nome',
            altCol : (item)=> {
                return item.infoUnitaMisura.nome
            }
        },
        {
            id : 2, 
            label : 'Quantità disponibile' , 
            nome : 'quantitaResidua'
        },
        {
            id : 2, 
            label : 'Costo' , 
            nome : 'costo' ,
            altCol : (item)=> {
                return item.costo+' €'
            }
        },
        {
            id : 2, 
            label : 'Sezione' , 
            nome : 'InfoSezione',
            altCol : (item)=> {
                let percorso =''
                if (!!item.infoSezione) {
                    percorso = item?.infoSezione?.nome
                    let og = item.infoSezione
                    while (og.padreId !== null) {
                        percorso = og.sezionePadre.nome+ ' / '+percorso
                        og = og.sezionePadre
                    }
                }
                return(
                    <>
                        <ProdottiSezione
                            sezioneId={item.infoSezione.id}
                            nomeSezione={!!item.infoSezione ? percorso : 'Sezione non assegnata'}
                            icona={!!item.infoSezione ? percorso : 'Sezione non assegnata'}
                        />
                    </>
                )
            }
        },
    ]

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await prodDisp({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }
    
    const azzera = async ()  =>{
        setInputsFiltra({qtDisp :1})
        setAzzeraVar(1)
        if (!!prodSfusi) {
            await prodDisp({pageSize , page:1 ,qtDisp : 1, artNonAllocati :1})
        } else {
            await prodDisp({pageSize , page:1 ,qtDisp : 1})
        }
    }

    const onChangeFiltra = async (name , value) => {
        setInputsFiltra(values => ({...values, [name]: value})) ; 
        await prodDisp({...inputsFiltra , [name]: value , page :1}) 
    }

    const onChangeFiltraSwitch = async (valore , nome) => {
        let tmp = {...inputsFiltra}
        if (!!valore) {
            tmp[nome] = 1
        } else {
            delete tmp[nome]
        }
        setInputsFiltra(tmp)
        await prodDisp({...tmp , page :1}) 
    }

    const Pulsanti = ({element})=> {
        console.log(element , ' element');
        
        return(
            <td className="py-0 my-0">
                <button className="btn py-0 my-0" onClick={()=>setInputsSposta({...element , sezioneNome :element.infoSezione.nome})}>
                    <FontAwesomeIcon icon={faAnglesRight} />
                </button>
            </td>
        )
    }

    const onChangeSposta = (name , value) => {
        console.log(name , value , ' name , value');
        
        setInputsSposta(values => ({...values, [name]: value})) ; 
    }

    const onChangeSelezionaSezione = (element) => {
        onChangeSposta('sezioneId', element.sezioneId)
        onChangeSposta('sezioneNome', element.nome)
        setViewSelezionaDest(false)
    }

    const PulsantiSelezionaSezioneDest = (id)=> {
        console.log(id.id.original, ' element');
        
        return(
            <td>
                <button className="btn" onClick={()=>{onChangeSelezionaSezione(id.id.original)}}>
                    <FontAwesomeIcon icon={faCheck} />
                </button>
            </td>
        )
    }

    const onCloseSpostaProd = (stato) => {
        if (!!stato) {
            classProdSezzione.spostaProd({prodId : inputsSposta.id ,qt : inputsSposta.quantita ,...inputsSposta})
            .then(respose => {
                prodDisp({qtDisp : 1})
                setInputsSposta(false)
            })
            .catch(errors => {
                setErrorMessage(errors.response.data)
            })
        } else {
            setInputsSposta(false)
        }
    }

    const onCloseError = (stato) => {
        if (!!stato) {
            classProdSezzione.spostaProd({prodId : inputsSposta.id ,qt : inputsSposta.quantita ,...inputsSposta , forza : 1})
            .then(respose => {
                prodDisp({qtDisp : 1})
                setInputsSposta(false)
                setErrorMessage(false)
            })
        }else {
            setErrorMessage(false)
        }
    }

    console.log(inputsSposta , ' inputsSposta');
    

    return(
        <div className="p-2">
            <PaginazioneTitoloPulsanteCrea
              titolo={'Sposta prodotti'}
              funCrea={null}
            />
            <div className="mx-2 px-5" style={{paddingTop : '1.3rem'}} >
                <div className='border_filtra p-3 ms-2 me-1 mb-4' >
                    <div className="d-flex">
                        <InputForm
                            label='nome'
                            type='text'
                            placeholder='Inserisci nome'
                            name='nome'
                            value={inputsFiltra.nome || ''}
                            onChange={(name , value) => onChangeFiltra(name , value)}
                            errors={{}}
                            classi={' col-2 '}
                        />
                        <InputForm
                            label='Codice a barre'
                            type='text'
                            placeholder='Inserisci Codice a barre'
                            name='codBarre'
                            value={inputsFiltra.codBarre || ''}
                            onChange={(name , value) => onChangeFiltra(name , value)}
                            errors={{}}
                            classi={' col-2 '}
                        />
                        <Switch
                            label={'Articoli non allocati'}
                            number='artNonAllocati'
                            stato={inputsFiltra.artNonAllocati}
                            onChangeValue={onChangeFiltraSwitch}
                            classe=' mt-3'
                        />
                    </div>
                    <button className="btn btn-primary ms-3" onClick={azzera}>
                        AZZERA
                    </button>
                </div>
                <div className="pt-4">
                    <TabellaPaginazione
                        colonne={colonne}
                        dati={prods}
                        totalePag={prodsPageTot}
                        azzera={azzeraVar}
                        impaginazione={impaginazione}
                        fu={fu}
                        Pulsanti={Pulsanti}
                    />
                </div>

                {!!inputsSposta && <QuestionModal
                    title={'Sposta prodotto '+inputsSposta.nome+' ('+inputsSposta.infoProd.rifInterno +')'}
                    text={<div className="d-flex align-items-center">

                        <table>
                            <thead>
                                <tr className="border bg_secodary">
                                        <th className="p-2" >Quantità</th>
                                        <th className="p-2">Prodotto</th>
                                        <th className="p-2">Destinazione</th>
                                        <th className="p-2"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border">
                                        <td className="p-2">
                                        <InputForm
                                                label=' '
                                                type='number'
                                                placeholder='Inserisci quantità'
                                                name='quantita'
                                                value={inputsSposta.quantita}
                                                max={inputsSposta.quantitaResidua}
                                                onChange={(name , value) => onChangeSposta(name , value)}
                                                errors={{}}
                                                classi={' col-12 ms-0'}
                                            />
                                        </td>
                                        <td className="p-2"> {inputsSposta.nome} </td>
                                        <td className="p-2 fw-bold">
                                            
                                            {!!inputsSposta?.sezioneNome ? 
                                                <ProdottiSezione
                                                    icona={
                                                        <span className="fw-bold">
                                                            {inputsSposta?.sezioneNome}
                                                        </span>
                                                     }
                                                    nomeSezione={inputsSposta?.sezioneNome }
                                                    sezioneId={inputsSposta?.sezioneId}
                                                />
                                            :
                                                'Nessuna sezione selezionata'
                                            }
                                        </td>
                                        <td className="p-2">
                                        <button className="btn btn-primary" onClick={()=>setViewSelezionaDest(true)}>
                                            Saleziona destinazione
                                        </button>
                                        </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="d-flex align-items-center">
                            <button className="btn btn-primary" onClick={()=>setViewSelezionaDest(true)}>
                                Saleziona destinazione
                            </button>
                            <p className="p-0 m-0 ps-3 "> {'Sezione selezionata : '+ !!inputsSposta?.sezioneNome ? inputsSposta?.sezioneNome :'Nessuna sezione selezionata'} </p>
                        </div> */}

                        
                    </div>}
                    YesLabel="Sposta"
                    onClose={onCloseSpostaProd}
                />}

                {!!viewSelezionaDest && <InfoModal
                    title={'Seleziona sezione di destinazione'}
                    text={<SelezionaSezioneDest
                            Pulsanti={PulsantiSelezionaSezioneDest}
                            quantita={null}
                            unitaMisuraId={null}
                            sezioneId={inputsSposta?.sezioneId}
                        />
                    }
                    canClose
                    handleClose={()=>setViewSelezionaDest(false)}
                    size='xl'
                />}
            </div>

            {!!errorMessage &&<QuestionModal
                title={'Errore'}
                text={<div className="p-4 my-5">
                    {errorMessage}
                </div>}
                onClose={onCloseError}
            />}
        </div>
    )
}