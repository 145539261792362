import caricaFile_fetch from "../fetch/caricaFile_fetch";

export default function UploadDocProd({
    onSuccess
}) {
    
    const salvaDoc = async (event) => {

        await caricaFile_fetch.allegaDocProdotto(event.target.files[0])
        .then(response => {
            //console.log(response.data , ' foto');
            onSuccess(response.data)
        })
    }

    return (
        <div className="d-flex align-items-center border p-3 rounded mt-5" >
            <p className="fw-bold py-0 my-0 pe-4 " >Documento articolo</p>
            <input type="file" className="form-control w-50" onChange={salvaDoc} />
        </div>
    )
}