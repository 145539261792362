import React from 'react';
import FormError from '../FormError';

export default function InputForm({
    label , 
    type , 
    placeholder , 
    name , 
    value , 
    onChange ,  
    onBlur ,
    onClick ,
    classi , 
    errors ,
    maxLenght, 
    max ,
    min,
    classInput,
    disabled=false,
    verNumber = false,
    labelAlt 
}) {
    const segnalaErrore = errors[name] ? 'is-invalid' : ''

    const preOnChange = (nome , valore) => {
        
        if (!!verNumber) {
         let ver = true;
         let valoreString =valore+''
 
         for (let index = 0; index < valoreString.length; index++) {
             const element = valoreString[index];
             console.log(ver , ' ver ' , element , isNaN(parseInt(element)));
             if ((typeof parseInt(element) !== 'number' || isNaN(parseInt(element)) && element != ',' )) {
                 ver = false
             }
         }
         
         if (!!ver) {
         onChange(nome , valore)
         }
  
        }else {
         onChange(nome , valore)
        }
     
     }

    return(
        <div className={"m-3 "+classi}>
            <label htmlFor={name} className="form-label fw-bold ps-4 ms-1"> { label[0].toUpperCase() + label.substr(1) } {labelAlt}</label>
            <input 
                value={value} 
                //defaultValue={value} 
                type={type} 
                className={" form-control ms-0 " + segnalaErrore +' '+classInput} 
                name={name} 
                id={name} 
                placeholder={placeholder}
                onChange={(event) => preOnChange(name, event.target.value)}
                onBlur={onBlur}
                aria-describedby="validationServer03Feedback"
                maxLength={maxLenght}
                onClick={onClick}
                max={max}
                min={min}
                disabled={disabled}
            />
            <div className='ps-4 ms-1' >
                <FormError errors={errors} field={name} />
            </div>
        </div>
    )
}