import FormError from "../FormError";

export default function RadiusForm({
    arrayElement = [], // { label , valore }
    label ,
    name ,
    value ,
    onChange , 
    classi , 
    errors,
    visualizza = false
}) {
    
    return (

        <div className={"d-flex align-items-center m-3 "+ classi} >
             <label className="form-label fw-bold pe-3 mb-0">
                {label}
             </label>
            <div className={" bg-primary "} style={{ display: 'flex', borderRadius: '25px', overflow: 'hidden' }}>
                {arrayElement.map((element, index) => (
                    <div key={index} className="form-check px-4 " style={{ borderRight: index !== arrayElement.length - 1 ? '1px solid #ccc' : 'none' }}>
                        <input
                            className="form-check-input ms-1 me-2 p-3"
                            type="radio"
                            name={name}
                            id={`${name}-${index}`}
                            value={element.valore}
                            checked={value === element.valore}
                            onChange={()=>onChange(name, element.valore)}
                            style={{ backgroundColor: value === element.valore ? '#8FC746' : 'white' }}
                        />
                        <label className="form-check-label text-white ps-2" htmlFor={`${name}-${index}`}>
                            {element.label}
                        </label>
                    </div>
                ))}
            </div>
            
            <FormError errors={errors} field={name} />
   
        </div>
    );
}