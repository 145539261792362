import http from "./controllerFetch";

class ProdSituazione {
  get( params = {}) {
    return http.get('ProdSituazione/readProdSituazione', { params });
  }
  readStoricoProd( params = {}) {
    return http.get('ProdSituazione/readStoricoProd', { params });
  }
  readProdSituazioneMagazzino( params = {}) {
    return http.get('ProdSituazione/readProdSituazioneMagazzino', { params });
  }
  spostaProdSituazione(data) {
    return http.post('ProdSituazione/spostaProdSituazione', data);
}

}

export default new ProdSituazione();