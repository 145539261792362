import anCliente_fetch from "../../fetch/anCliente_fetch";
import bollaService from "../../fetch/bolla_fetch";
import create_autocompleteWithService from "../../riutilizabili/create_autocompleteWithService";
import GeneraCampiText from "../../riutilizabili/forms/GeneraCampiText";
import InputForm from "../../riutilizabili/forms/InputForm";


export default function FiltraBollaUscita({
    inputs ,
    onChangeValue ,
    errors
}) {

    const ClienteAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anCliente_fetch.autocompliteAn({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.ragioneSociale ? item.ragioneSociale : item.nome+' '+item.cognome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Cliente',
        placeholder: 'inserisci Cliente',
        value : inputs?.nomeCliente ,
        onChange : ({value, item}) => onChangeCliente(item)
    });

    const onChangeCliente = (item) => {
        if (item) {
            onChangeValue('clienteId' , item.id)
            onChangeValue('nomeCliente' , item.ragioneSociale ? item.ragioneSociale : item.nome+' '+item.cognome) ; 
        }else {
            onChangeValue('nomeCliente' , "") ;
            onChangeValue('clienteId' , "" )
        }
    }

    const campi = {
        text : [
            {
                name : 'id',
                label : 'numero DDT',
                lungCamp : '20%'
            },
            {
                name : 'codCliente',
                label : 'Codice cliente',
                lungCamp : '30%'
            },
            {
                name : 'seriale',
                label : 'Seriale',
                lungCamp : '45%'
            }
        ]
    }

    const assMetPag = ({item , value})=> {
        //console.log(item , ' item');
       // console.log(value , ' value');
        
        onChangeValue('tipoPagId', item?.id)
        onChangeValue('tipoPag', value)
    }

    const MetodiPagAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => bollaService.getMetodiPag({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome,
        itemToString: item => item.nome,
        value: inputs?.tipoPag,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Metodo di pagamento',
        placeholder: 'inserisci metodo di pagamento',
        onChange : assMetPag,
        className :'w-25'
    });
    
    return(
        <div>
            <div className="d-flex flex-wrap">
                <div className="w-25 p-3" >
                    <ClienteAutocomplite />
                </div>

                <div className="w-75 p-3">
                    <GeneraCampiText
                        campi={campi}
                        inputs={inputs}
                        onChange={onChangeValue}
                        errors={errors}
                    />
                </div>
                
            </div>


        </div>
    )
}