
import impostazioni from "../../cofig.js";
import htp from "../../../fetch/controllerFetch.js"
const api_url = impostazioni.api_url

class caricaFile {

    uploadFotoProdotto = async (file , df ) => {
        const formData = new FormData();
        formData.append('file', file); 
        if (df === 1) {
            formData.append('default', 1);
        }
        console.log(file, formData);
        return htp.post(api_url + 'Prodotto/allegaFotoProdotto',
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization' : 'Bearer ' + localStorage.getItem('token')
                }
            })
    }

    allegaDocProdotto = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        console.log(file, formData);
        return htp.post(api_url + 'Prodotto/allegaDocProdotto',
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization' : 'Bearer ' + localStorage.getItem('token')
                }
            })
    }

    visualizzaFoto = async (id) => {
        return htp.get(api_url + 'Prodotto/visualizzaFoto?id='+id)
    }

    readDocProd = async (id) => {
        return htp.get(api_url + 'Prodotto/readDocProd?prodottoId='+id)
    }

    eliminaFoto = async (id) => {
        return htp.delete(api_url + 'Prodotto/eliminaFoto?id='+id)
    }

    eliminaDoc = async (id) => {
        return htp.delete(api_url + 'Prodotto/eliminaDoc?id='+id)
    }

    modificaFoto = async (param) => {
        return htp.put(api_url + 'Prodotto/modificaFoto', param)
    }

    modificaDocumento = async (param) => {
        return htp.put(api_url + 'Prodotto/modificaDocumento', param)
    }

    visualizzaFotoProd = async (id) => {
        return htp.get(api_url + 'Prodotto/visualizzaFotoProd?id='+id)
    }
    scaricaDocumento = async (id) => {
        return htp.get(api_url + 'Prodotto/scaricaDocumento?id='+id)
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new caricaFile();