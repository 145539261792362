import { faBoxArchive, faBoxesStacked, faEdit, faEye, faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import prodotti_fetch from '../fetch/prodotti_fetch';
import SelezionaMagazzino from '../Magazzino/SelezionaMagazzino';
import ConfermaEliminazione from '../riutilizabili/ConfermaEliminazione';
import InputForm from '../riutilizabili/forms/InputForm';
import SelectForm from '../riutilizabili/forms/SelectForm';
import InfoModal from '../riutilizabili/InfoModal';
import PulsantiFiltra from '../riutilizabili/PulsantiFiltra'; 
import QuestionModal from '../riutilizabili/QuestionModal';
import SelectLivello from '../riutilizabili/SelectLivello';
import Tabella from '../riutilizabili/Tabella';
import AssegnaMg from './AssegnaMg';
import CampiProdotti from './CampiProdotti';
import PaginazioneTitoloPulsanteCrea from '../riutilizabili/PaginazioneTitoloPulsanteCrea';
import UploadFile from '../riutilizabili/UploadFile';
import ViewImgPreUpload from '../riutilizabili/ViewImgPreUpload';
import caricaFile_fetch from '../fetch/caricaFile_fetch';
import ImgsProd from './ImgsProd.js';
import ImgTabella from './ImgTabella.js';
import TabellaPaginazione from '../riutilizabili/TabellaPaginazione.js';
import UploadDocProd from './UploadDocProd.js';
import ViewDocProd from '../riutilizabili/ViewDocProd.js';
import ViewProdDisp from './ViewProdDisp.js';
import ViewProdPopUp from './ViewProdPopUp.js';
 


export default function ReadProdotti() {
    const navigate = useNavigate();

    const [error, setError] = useState({});
    const [inputs, setInputs] = useState({});
    const [datiProd, setDatiProd] = useState([]);
    const [datiCrea , setDatiCrea] = useState({automaticReorder : 1});
    const [datiModifica , setDatiModifica] = useState({});
    const [datiAssegnaMagazzino , setDatiAssegnaMagazzino] = useState({});
    const [datiView , setDatiView] = useState({});
    const [imgProd , setImgProd] = useState([]);
    const [imgProdMod , setImgProdMod] = useState([]);
    const [viewCrea , setViewCrea] = useState(false);
    const [viewModifica , setViewModifica] = useState(false);
    const [viewAssegnaMagazzino , setViewAssegnaMagazzino] = useState(false);
    const [viewView , setViewView ] = useState(false);
    const [viewEliminaProd, setViewEliminaProd] = useState(false);

    const [docProd , setDocProd] = useState([])

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)
    const [inputsFiltra , setInputsFiltra] = useState({})

    useEffect( ()=>{
        readProdotti()
      },[])

    const readProdotti = async () => {
        const response = await prodotti_fetch.get({pageSize , page ,stato : 1})
        let tmp = [...response.data.data]
        for (let index = 0; index < tmp.length; index++) {
            const element = tmp[index];
            element.nomeUnitaMisura = element?.infoUnitaMisura?.nome
        }
        setDatiProd(tmp)
        setPageTot(response.data.pageTot)
        setFu(fu+1)
        setInputs({})
        setError({})
    }

    const columns = [
         {
                label: 'riferimento interno',
                nome: 'rifInterno',
              },
              {
                label: 'Immagine',
                nome: 'img',
                altCol : (item)=> {
                    return(
                        <div style={{width: '10rem'}} className='m-auto' >
                          <ImgTabella prodId={item.id} />
                        </div>
                    )
                }
              },
               {
                      label: 'Descrizione',
                      nome: 'Descrizione',
                      altCol: (item)=> {
                          return(
                              <>
                                  <ViewProdPopUp
                                      icona={item?.descrizione}
                                      prodAnId={item?.id}
                                      classiButton='d-flex'
                                  />
                              </>
                          )
                          }
                      },
              {
                label: 'Categoria',
                nome: 'infoCategoria.nome',
                altCol: (item)=> {
                    return(
                        <div style={{width: '10rem'}} className='m-auto' >
                          {item?.infoCategoria?.nome +(!!item?.infoSottoCategoria ? ' => ' +item?.infoSottoCategoria?.nome : ' ')}
                        </div>
                    )
                }
              },
              {
                label: 'Codice a barre',
                nome: 'codBarre',
              },
              {
                label: 'ASIN',
                nome: 'asin',
              }
        ]
    const handleChangeFiltra = (name , value) => {
        //console.log(name , value);
        setInputs(values => ({ ...values, [name]: value }))
        filtra({[name]: value})
    }
    
    const handleChangeModifica = (name , value) => {
        //console.log(name , value);
        setDatiModifica(values => ({ ...values, [name]: value }))
    }
    
    const handleChangeAssegnaPosizione = (name , value) => {
        //console.log(name , value);
        setDatiAssegnaMagazzino(values => ({ ...values, [name]: value }))
    }
    
    const handleChangeCrea = (name , value) => {
        //console.log(name , value);
        setDatiCrea(values => ({ ...values, [name]: value }))
    }

    const filtra = async (param) => {
        await prodotti_fetch.get({pageSize , page ,...inputs ,  stato : 1 , ...param})
        .then(response => {
            //console.log(response.data , ' response.data');
            setDatiProd(response.data.data)
            setPageTot(response.data.pageTot)
            setFu(fu+1)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    const modificaProdotto = async (stato) => {
        if (stato) {
            await prodotti_fetch.update(imgProd ,datiModifica , docProd)
            .then(response => {
                readProdotti()
                setViewModifica(false)
                setDatiModifica({})
            })
            .catch(error=> {
                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)
                }
                //console.log(error.response.data , ' error');
            })
        }else {
            setViewModifica(false)
        }
    }

    const creaProdotto = async (stato) => {
        if (stato) {
            //console.log(datiCrea , 'formdata 0');
            await prodotti_fetch.create({imgProd , datiCrea ,docProd})
            .then(response => {
                readProdotti()
                setViewCrea(false)
                setDatiCrea({})
                setImgProd([])
            })
            .catch(error=> {
                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)
                }
                //console.log(error.response.data , ' error');
            })
        }else {
            setViewCrea(false)
        }
    }
    
    const viewPr = async (id) => {
        const an = await prodotti_fetch.get({id : id})
        
        const array = an.data[0];
        //console.log(array , 'array');
        let newArray = {}
        const datiPrendere = [
            'nome', 
            'descrizione', 
            'infoCategoria' ,
            'infoSottoCategoria' ,
            'produttore' ,
            'infoFornitore' ,
            'codBarre' ,
            'prezzoVendita' ,
            'costo' ,
            'rifInterno' ,
            'note' ,
            'asin' ,
            'qtMin' ,
            'automaticReorder' ,
            'posizioneMg' ,
            'codForitore' ,
        ]
        for (let index = 0; index < Object.keys(array).length; index++) {
            const element = Object.keys(array)[index];
            //console.log(element , ' element');
            if (datiPrendere.includes(element)) {
                if (element === 'infoCategoria') {
                    newArray['Categoria'] = array[element].nome
                }else if (element === 'infoSottoCategoria') {
                    newArray['SottoCategoria'] = array[element]?.nome
                }else if (element === 'infoFornitore') {
                    newArray['Fornitore'] = array[element]?.viewName
                }else if (element === 'codForitore') {
                    newArray['Codice foritore'] = array[element]
                }else if (element === 'codBarre') {
                    newArray['Codice a barre'] = array[element]
                }else if (element === 'prezzoVendita') {
                    newArray['Prezzo di vendita'] = array[element]
                }else if (element === 'rifInterno') {
                    newArray['Riferimento interno'] = array[element]
                }else if (element === 'qtMin') {
                    newArray['Quantita minima'] = array[element]
                }else if (element === 'produttore') {
                    newArray['Produttore'] = array[element]
                }else if (element === 'nome') {
                    newArray['Nome'] = array[element]
                }else if (element === 'automaticReorder') {
                    newArray['Riordino automatico'] = array[element] ? 'si' : 'no'
                }else if (element === 'posizioneMg') {
                    // newArray['Posizione prodotti in magazzino'] = 
                    // <ul>
                    //     {array[element].map(item =>{
                    //         return(
                    //             <li>
                    //             il prodotto con codice {item.codiceProd}  è nel magazzino
                    //             {' "'+ item.infoMagazzino.nome}" in posizione {" "+item.livello1 && item.livello1}
                    //             {item.livello2 && item.livello2}
                    //             {item.livello3 && item.livello3}
                    //             {item.livello4 && item.livello4}
                    //             {item.livello5 && item.livello5}
                    //             {item.livello6 && item.livello6}
                    //             </li>
                    //         )
                    //     } )}

                    // </ul>
                }else {
                    if (!datiPrendere.includes(element)) {
                        newArray[element] = array[element]
                        
                    }
                }
            }
        }

        console.log(newArray , ' newArray');
        setDatiView(newArray)
        setViewView(id)
    }

    const modificaPr = async (id) => {
        const img = await caricaFile_fetch.visualizzaFotoProd(id)
        console.log(img.data ,' imgimg');
        
        setImgProdMod(img.data)
        await prodotti_fetch.get({id : id})
        .then(an => {
            let tmp = {...an.data[0]}
            console.log(tmp , ' tmp');
            
            tmp.nomeUnitaMisura = tmp?.infoUnitaMisura?.nome
            tmp.categoria_nome = tmp?.infoCategoria?.nome
            if (tmp?.infoSottoCategoria) {
                tmp.sottoCategoria_nome = tmp?.infoSottoCategoria?.nome
            }
            if (tmp?.infoFornitore) {
                tmp.fornitore_nome = tmp?.infoFornitore?.viewName
            }
            setDatiModifica(tmp)
            setViewModifica(true)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");;
            }else{
                setError(error.response.data)
            }
        })
    }

    const eliminaProd =async (stato) => {
        if (stato) {

            try {
                await prodotti_fetch.update([] ,{
                    id: viewEliminaProd.id ,
                    stato : '0'
                })
                await filtra();
            } catch (error) {
                if (error.response.status === 406) {
                    navigate("/login");;
                }else{
                    setError(error.response.data)
                }
            }
            // await prodotti_fetch.update([] ,{
            //     id: viewEliminaProd.id ,
            //     stato : '0'
            // })
            // .then(response => {
            //     //console.log(response.data);
            //     filtra();
            // })
            // .catch(error => {
            //     if (error.response.status === 406) {
            //         navigate("/login");;
            //     }else{
            //         setError(error.response.data)
            //     }
            // })
            
        }
        setViewEliminaProd(false)
    }
    
    const Pulsanti = ({element}) => {
        console.log(element , ' element');
        
        return(
            <td>
                <button className='btn  py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0 text_bluDue' icon={faEye} onClick={()=>viewPr(element.id)} />
                </button>

                <button className='btn  py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0 text_bluDue' icon={faEdit} onClick={()=>modificaPr(element.id)} />
                </button>

                {/* <button className='btn  py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0 text_bluDue' icon={faBoxesStacked} onClick={()=>viewAssegnaMg(element.id , element.nome)} />
                </button> */}

                <button className='btn  py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0 text_bluDue' icon={faTrash} onClick={()=>setViewEliminaProd(element)} />
                </button>
                <ViewProdDisp 
                    iconButton={<FontAwesomeIcon icon={faBoxesStacked} />} 
                    prodottoId={element.id} 
                    classButton={''} 
                    nomeProd={element.nome}
                    unitaMisura={element.infoUnitaMisura.nome}
                />
            </td>
        )
    }

    const viewAssegnaMg = (id , nome) => {
        setDatiAssegnaMagazzino(values => ({ ...values, ['prodottoId']: id , ['nomeProd'] : nome }))
        setViewAssegnaMagazzino(true)
    }

    const assegnaMg = async (stato) => {
        if (stato) {
            await prodotti_fetch.createAssPosMg({
                prodottoId : datiAssegnaMagazzino.prodottoId,
                codiceProd : datiAssegnaMagazzino.codiceProd,
                magazzinoId : datiAssegnaMagazzino.magazzinoId,
                livello1 : datiAssegnaMagazzino.livello1Res,
                livello2 : datiAssegnaMagazzino.livello2Res,
                livello3 : datiAssegnaMagazzino.livello3Res,
                livello4 : datiAssegnaMagazzino.livello4Res,
                livello5 : datiAssegnaMagazzino.livello5Res,
                livello6 : datiAssegnaMagazzino.livello6Res,
                stato : 1
    
            })
            .then(response => {
                setDatiAssegnaMagazzino({})
                setViewAssegnaMagazzino(false)
            })
            .catch(error=> {
                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)
                }
                //console.log(error.response.data , ' error');
            })
        } else {
            setViewAssegnaMagazzino(false)
        }
    }

    const aggiungiImgMod = async (file) =>{
        await caricaFile_fetch.modificaFoto({
            id : file.id ,
            prodId : datiModifica.id
        } )
        setImgProdMod(values => ( [...values,  file ]))
    }

    const aggiungiDocMod = async (file) =>{
        console.log(file , ' file');
        
        await caricaFile_fetch.modificaDocumento({
            id : file.id ,
            prodottoId : datiModifica.id
        } )
        setImgProdMod(values => ( [...values,  file ]))
    }

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtra({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }

    console.log(datiView , ' datiView');
    
    //console.log(imgProd , ' imgProd' );
    return(
        <div className='p-2'>
            <PaginazioneTitoloPulsanteCrea titolo={'Anagrafica articoli'}  funCrea={() =>setViewCrea(true)} />

            <div className='m-4 ps-3 pt-4 pe-3' >
                <div className='border_filtra p-3 ms-4'style={{width : '98.3%'}} >
                    {/* <h3 className='text-primary' >Filtra</h3> */}
                    {/* <CampiProdotti 
                        value={inputs}
                        errors={{}}
                        onChange={handleChangeFiltra}
                        classiDiv='d-flex flex-wrap'
                    /> */}
                    <div className="p-2 d-flex flex-wrap">

                        <InputForm 
                            label='descrizione' 
                            type='text' 
                            placeholder='Inserisci descrizione'
                            name='descrizione'
                            value={!!inputs?.descrizione ? inputs.descrizione : ''}
                            onChange={(name , value) => handleChangeFiltra(name , value)}
                            classi=' w-25 m-2'
                            errors={{}}
                        />
                        <InputForm 
                            label='Codice a barre' 
                            type='text' 
                            placeholder='Inserisci Codice a barre'
                            name='codBarre'
                            value={!!inputs?.codBarre ? inputs.codBarre : ''}
                            onChange={(name , value) => handleChangeFiltra(name , value)}
                            classi=' w-25 m-2'
                            errors={{}}
                        />
                        <InputForm 
                            label='Codice produttore *' 
                            type='text' 
                            placeholder='Inserisci codice produttore'
                            name='codProd'
                            value={!!inputs.codProd ? inputs.codProd : ''}
                            onChange={(name , value) =>{ handleChangeFiltra(name , value)}}
                            classi='w-25 m-2'
                            errors={{}}
                        />
                        <InputForm 
                            label='Produttore' 
                            type='text' 
                            placeholder='Inserisci produttore'
                            name='produttore'
                            value={!!inputs.produttore ? inputs.produttore : ''}
                            onChange={(name , value) =>{ handleChangeFiltra(name , value)}}
                            classi='w-25 m-2'
                            errors={{}}
                        />
                        <InputForm 
                            label='Codice interno ' 
                            type='text' 
                            placeholder='Inserisci codice interno'
                            name='rifInterno'
                            value={!!inputs.rifInterno ? inputs.rifInterno : ''}
                            onChange={(name , value) => handleChangeFiltra(name , value)}
                            classi='w-25 m-2'
                            errors={{}}
                        />
                    </div>
                    <div className='ps-3'>
                        <PulsantiFiltra
                            filtra={filtra}
                            azzera={readProdotti}
                        />
                    </div>
                </div>

                {/* <Tabella 
                    data={datiProd}
                    columns={columns}
                    Pulsanti={Pulsanti}
                /> */}

                <div className='p-3 pt-5 pe-4'>
                    <TabellaPaginazione
                        colonne={columns}
                        dati={datiProd}
                        totalePag={pageTot}
                        azzera={azzeraVar}
                        impaginazione={impaginazione}
                        fu={fu}
                        Pulsanti={Pulsanti}
                    />
                </div>


            </div>

                {viewCrea && <QuestionModal 
                    title = 'Crea articolo'
                    text = {
                    <>
                        <CampiProdotti 
                            value={datiCrea} 
                            onChange={handleChangeCrea} 
                            errors={error} 
                            classiDiv={'d-flex flex-wrap w-100'} 
                        />
                        <div style={{width : '69rem'}} className='ps-4'>
                            <UploadFile
                                txtInfo='Immagine articolo '
                                onSuccess={(file)=>setImgProd(values => ( [...values,  file ]))}
                                imgs={imgProd}
                            />
                        </div>

                        <ViewImgPreUpload
                            imgs={imgProd}
                            onChangeImg={(imgs)=> setImgProd(imgs)}
                            prodId={datiModifica.id}
                        />
                        <div style={{width : '69rem'}} className='ps-4'>
                            <UploadDocProd 
                                onSuccess={(file)=>setDocProd(values => ( [...values,  file ]))}
                            />
                        </div>
                        <ViewDocProd prodottoId={datiModifica.id} />
                    </>
                    }
                    YesLabel = 'Crea'
                    onClose = {creaProdotto}
                />}

                {viewModifica&& <QuestionModal 
                    title = 'Modifica articolo'
                    text = {
                    <>
                        <CampiProdotti 
                            value={datiModifica} 
                            onChange={handleChangeModifica} 
                            errors={error} 
                            classiDiv={'d-flex flex-wrap'} 
                            edit={true}
                        />
                        <div style={{width : '69rem'}} className='ps-4'>
                            <UploadFile
                                txtInfo='Immagine articolo'
                                onSuccess={(file)=>aggiungiImgMod(file)}
                                imgs={imgProdMod}
                            />
                        </div>
                        <ViewImgPreUpload
                            imgs={imgProdMod}
                            onChangeImg={(imgs)=> setImgProdMod(imgs)}
                            prodId={datiModifica.id}
                        />
                        <div style={{width : '69rem'}} className='ps-4'>
                            <UploadDocProd 
                                onSuccess={(file)=>aggiungiDocMod(file)}
                            />
                        </div>
                        <ViewDocProd prodottoId={datiModifica.id} />
                    </>
                    }
                    YesLabel = 'Salva'
                    onClose = {modificaProdotto}
                />}

                {viewView && <InfoModal
                  size='xl'
                  title={'Articolo : ' + datiView.Nome}
                  handleClose={()=>setViewView(false)}
                  canClose='Chiudi'
                  text={
                    <div>
                       <div className="mb-5">
                            {Object.keys(datiView).map((item , key) => {
                                //console.log(item, ' itemc ', datiView[item]);
                                return(
                                    <span className='d-inline-flex flex-column m-3 flex-wrap col-2' key={key}>
                                        <span className='fw-bold' > {item} </span>
                                        <span className='pt-2 ' >{datiView[item] && datiView[item] }</span>
                                    </span>
                                )
                            })}
                       </div>
                        <div className='ps-3' >
                            <p className="col-2 fw-bold"> Immagini </p>
                            <ImgsProd prodId={viewView} />
                        </div>
                        <p className="ps-3 mt-5 mb-0 pb-0 col-2 fw-bold"> Documenti </p>
                        <ViewDocProd prodottoId={viewView} visualizza />
                    </div>
                  }
                />}
                
                {viewAssegnaMagazzino && <QuestionModal 
                    title = {'Assegna magazzino a "'+ datiAssegnaMagazzino.nomeProd +'"'}
                    text = {
                        <div>
                            <AssegnaMg 
                                values={datiAssegnaMagazzino} 
                                onChange={handleChangeAssegnaPosizione}
                                errors={error}
                            />
                        </div>
                    }
                    YesLabel = 'Modifica'
                    onClose = {assegnaMg}
                />}

                <ConfermaEliminazione nome={'Articolo '+viewEliminaProd.nome} stato={viewEliminaProd} conferma={(stato)=>eliminaProd(stato)} />
        </div>
    )
    
}