import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import permessi_fetch from "../fetch/permessi_fetch";
import { useNavigate } from "react-router";
import { useState } from "react";
import Switch from "../riutilizabili/forms/Switch";
import InfoModal from "../riutilizabili/InfoModal";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";

export default function PermessiGruppi({gruppoId , nomeGruppo}) {
    const navigate = useNavigate();

    const [permessi , setPermessi] = useState([])
    const [permessiView , setPermessiView] = useState(false)
    const [permessiUtNegati , setPermessiUtNegati ] = useState([])
    const [permessiUtConsentiti , setPermessiUtConsentiti] = useState([])

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [page , setPage] = useState(1);
    const [fu , setFu] = useState(0)

    const readPermGroup = async () => {
        try {
            let response = await permessi_fetch.get({stato:1 })
            setPermessi(response.data)
            
        } catch (error) {
            if (error.response.status === 406) {
                navigate("/");
            }
        }
        const permessiUtOk = await permessi_fetch.readpermessiGruppo({gruppoId , stato : 1})
        setPermessiUtConsentiti(permessiUtOk.data.map(item =>item.permessoId))
        const permessiUtKO = await permessi_fetch.readpermessiGruppo({gruppoId , stato : 0})
        setPermessiUtNegati(permessiUtKO.data.map(item =>item.permessoId))
        setFu(fu+1)
        setPermessiView(true)
        // setUtente(permessiUtKO.data[0].utente) 
    }
    
    const filtra = (param) => {
        permessi_fetch.get({pageSize , page , ...param})
        .then(response => {
            //console.log(response.data , ' response.data');
            setPermessi(response.data.data)
            setPageTot(response.data.pageTot)
            setFu(fu+1)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }
    
    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtra({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag ,stato:1 })
            setAzzeraVar(0)
        }
    }

    const onChangeValueNega =async (stato , permessoId) => {
        console.log(stato , permessoId , ' stato , permessoId');
        if (stato) {
            await permessi_fetch.createAssociazioneGruppo({
                stato : '0',
                permessoId,
                gruppoId
            })
        } else {
            await permessi_fetch.updateAssociazioneGruppo({
                stato : 2,
                permessoId,
                gruppoId
            })
        }
        await readPermGroup()
    }

    const onChangeValueConsenti =async (stato , permessoId) => {
        
        if (!!stato) {
            await permessi_fetch.createAssociazioneGruppo({
                stato : 1,
                permessoId,
                gruppoId
            })
        } else {
            await permessi_fetch.updateAssociazioneGruppo({
                stato : 2,
                permessoId,
                gruppoId
            })
        }
        await readPermGroup()
    }

    const colonne = [
        {
            id : 1, 
            label : 'Voci menù' , 
            nome : 'nome'
        },
        {
            id : 2, 
            label : 'Nega' , 
            nome : 'Nega',
            altCol: (item)=> {
                return(
                    <div style={{width: '10rem'}} className='m-auto d-flex justify-content-center align-items-center' >
                        <Switch
                            stato={permessiUtNegati.includes(item.id)}
                            number={item.id}
                            label=' '
                            classe=' p-0 m-0 '
                            onChangeValue={onChangeValueNega}
                        />
                    </div>
                )
            }
        },
        {
            id : 2, 
            label : 'Consenti' , 
            nome : 'Consenti',
            altCol: (item)=> {
                return(
                    <div style={{width: '10rem'}} className='my-0 py-0 d-flex justify-content-center align-items-center' >
                        <Switch
                            stato={permessiUtConsentiti.includes(item.id)}
                            number={item.id}
                            label=' '
                            classe=' p-0 m-0 '
                            onChangeValue={onChangeValueConsenti}
                        />
                    </div>
                )
            }
        },
    ]
    
    return(
        <>
            <button className="btn my-0 py-0" onClick={readPermGroup} >
                <FontAwesomeIcon className="p-0 m-0" icon={faKey} />
            </button>

            {!!permessiView &&<InfoModal
                title={'Permessi gruppo '+nomeGruppo}
                text={<div>
                    <TabellaPaginazione
                        colonne={colonne}
                        dati={permessi}
                        totalePag={pageTot}
                        impaginazione={null}
                        fu={fu}
                    />
                </div>}
                canClose
                handleClose={()=>setPermessiView(false)}
            />}
        </>
    )
}