import { useState } from "react";
import prodSituazione_fetch from "../fetch/prodSituazione_fetch";
import QuestionModal from "../riutilizabili/QuestionModal";
import InputForm from "../riutilizabili/forms/InputForm";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import Switch2 from "../riutilizabili/Switch2";
import InfoModal from "../riutilizabili/InfoModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import create_autocompleteWithService from "../riutilizabili/autocomplite/create_autocompleteWithService";
import classSezione from "../fetch/classSezione";


export default function SelezionaProdSituazioneDisp({
    prodsIds =[],
    classiBtn ,
    iconButton ,
    selezionaProdotto ,
    magazzinoId ,
    inputs
}) {
    const [viewProds , setViewProds] = useState(false);
    const [prods , setProds] = useState([]);
    const [inputsFiltra , setInputsFiltra] = useState({})
    const [mostraSeriali , setMostraSeriali] = useState(false) ;
    

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)

    const readProds = async (param) => {
        const response = await prodSituazione_fetch.get({page , pageSize , ...param ,qtDisp : 1 , prodMag : magazzinoId})
        setProds(response.data.data)
        setPageTot(response.data.pageTot)
        setFu(fu+1)
    } 

    const filtra = (nome , valore) => {
        let tmp = {...inputsFiltra}
        tmp[nome] = valore
        setInputsFiltra(tmp)
        readProds(tmp)
    }

    const azzeraFiltra = () => {
        setInputsFiltra({})
        readProds({page : 1 , pageSize})
    }

    const columns = [
        {
            id : 10,
            label: 'Cod.',
            nome: 'Nome',
            altCol: (item)=>item?.Prodotti?.rifInterno
        },
        {
            id : 1,
            label: 'descrizione',
            nome: 'descrizione',
            altCol: (item)=>item?.Prodotti?.descrizione
        },
        {
            id : 2,
            label: 'Codice identificativo',
            nome: 'rifInterno',
            altCol: (item)=>item?.Prodotti?.rifInterno
        },
        {
            id : 3,
            label: 'Codice produttore',
            nome: 'codProd',
            altCol: (item)=>item?.Prodotti?.codProd
        },
        {
            id : 4,
            label: 'Sezione',
            nome: 'sezione',
            altCol: (item)=>item?.Sezioni?.nome
        },
        {
            id : 5,
            label: 'Quantità',
            nome: 'qta',
            altCol : (item)=> <span className={parseInt(item.qta) < item.Prodotti.qtMin && ' text-danger fw-bold '} > {item.qta} </span>
        },
        {
            id : 5,
            label: 'Seriali',
            nome: 'Seriali',
            altCol: (item)=> {
                let array = []
                item.Prodotti.Prod_posizione_seziones.map(element => array.push(...element.ProdSerialis))
                console.log( array, ' item seriali');
                
                return (
                    <button className="btn" onClick={()=> setMostraSeriali({seriali :array ,nome : item.Prodotti.nome , rifInterno : item.Prodotti.rifInterno})}>
                        <FontAwesomeIcon icon={faList} />
                    </button>
                )
            }
        },
    ]

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await readProds({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }

    const View = async () => {
        setViewProds(true)
        readProds()
    }

    const Pulsanti = ({element}) => {
        return(
            <td className=" my-0 py-0 d-flex">
                <Switch2
                    stato={prodsIds.includes(element.id)}
                    number='prod'
                    //onChangeValue={()=>onChange(element)}
                    onChangeValue={()=>selezionaProdotto({...element , iva : element?.Prodotti?.iva , umId : element?.Prodotti?.umId } )}
                />
            </td>
        )
    }

    console.log(mostraSeriali , ' mostraSeriali');
    
    const onChangeSezione = async ({item , value}) => {
        if (item) {
            console.log(item , value);
            await filtra('sezioneId' , item.id)
            setInputsFiltra(values => ({...values, ['nomeSezionePadre']:  item.nome}))
        }
    }

    
    const SezioneAutocomplite = create_autocompleteWithService({
            freeinput: false,
            service: (myValue, serviceValues) => classSezione.get({
                autoComplite : 1,
                magazzinoId,
                nome: myValue,
                ...serviceValues,
            }),
            itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
            itemToLi: item => item.nome ,
            itemToString: item => item.nome,
            itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
            label :'Seleziona sezione padre' ,
            value: inputsFiltra.nomeSezionePadre ,
            onChange : onChangeSezione,
            placeholder: 'inserisci nome sezione padre',
    });


    return (
        <>
           {!viewProds ? 
           <button disabled={!magazzinoId} onClick={View} className={"btn "+classiBtn}>
                {iconButton}
            </button>
            :
            <InfoModal
                title={'Seleziona articolo dal '+inputs?.magazzinoNome+(inputs?.tipoMag !== 'Magazzino' ? (' - Magazzino di tipo '+inputs?.tipoMag ) :'') }
                text={
                    <div>
                        <div className="px-4 pe-5">
                            <div className="border_filtra p-3 my-5  w-100  d-flex flex-wrap" >
                                <InputForm
                                    label={'descrizione'}
                                    type={'text'} 
                                    placeholder={'Inserisci descrizione'}
                                    name={'descrizione'}
                                    value={!!inputsFiltra?.descrizione ? inputsFiltra.descrizione :''}
                                    onChange={(name , value) => filtra(name , value)}
                                    classi=' col-2 '
                                    errors={{}}
                                />
                                <InputForm
                                    label={'Codice a barre'}
                                    type={'text'} 
                                    placeholder={'Inserisci Codice a barre'}
                                    name={'codBarre'}
                                    value={!!inputsFiltra?.codBarre ? inputsFiltra.codBarre :''}
                                    onChange={(name , value) => filtra(name , value)}
                                    classi=' col-2 '
                                    errors={{}}
                                />
                                <InputForm
                                    label={'Codice produttore'}
                                    type={'text'} 
                                    placeholder={'Inserisci Codice produttore'}
                                    name={'codProd'}
                                    value={!!inputsFiltra?.codProd ? inputsFiltra.codProd :''}
                                    onChange={(name , value) => filtra(name , value)}
                                    classi=' col-2 '
                                    errors={{}}
                                />

                                <div className="w-25 m-3" >
                                    <SezioneAutocomplite />
                                </div>

                                <div className="ms-4 w-100">
                                    <PulsantiFiltra
                                        azzera={azzeraFiltra}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-100 ms-2 ps-1 pe-5">
                            <TabellaPaginazione
                                colonne={columns}
                                dati={prods}
                                totalePag={pageTot}
                                azzera={azzeraVar}
                                impaginazione={impaginazione}
                                fu={fu}
                                Pulsanti={Pulsanti}
                            />
                        </div>

                    </div>
                }
                canClose
                handleClose={()=>setViewProds(false)}
                classi={'modal-xxl'}
            />}

    {!!mostraSeriali && 
             <InfoModal
                size='xl'
                classi={' modal-top-5 '}
                title={'Seriali prodotto '+mostraSeriali.nome+' - '+mostraSeriali.rifInterno}
                text={
                    <div>
                        {mostraSeriali?.seriali.length > 0 ?
                        <ul>
                            {mostraSeriali?.seriali.map(seriale => {
                                return(
                                    <li>
                                        {seriale.seriale}
                                    </li>
                                )
                                
                            })

                            } 
                        </ul>
                        :
                        <p>
                            Nessun seriale associato
                        </p>
                        }
                    </div>
                }
                canClose={true}
                handleClose={()=>setMostraSeriali(false)}
             />
            }
        </>
    )
}