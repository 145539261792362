import http from "./controllerFetch";

class sezioneService { 
    getAll() {
        return http.get('Sezioni/readSezioni');
    }
    getMgVuoti() {
        return http.get('Sezioni/sezioniDispObsoleto');
    }
    get( params = {}) {
        return http.get('Sezioni/readSezioni', { params :{...params ,obsoleto: 0} });
    }
    getInventarioMg( params = {}) {
        return http.get('Sezioni/inventarioMg', { params });
    }
    getUnMisura( params = {}) {
        return http.get('Sezioni/leggiUnitaMisura', { params });
    }

    create(data) {
        return http.post('Sezioni/creaSezioni', data);
    }
}
 
export default new sezioneService()