import React , { useState } from "react";
import SelectAnMultiple from "../../anagrafica/SelectAnMultiple";
import anCliente_fetch from "../../fetch/anCliente_fetch";
import QuestionModal from "../../riutilizabili/QuestionModal";
import GeneraCampiText from "../../riutilizabili/forms/GeneraCampiText";
import create_autocompleteWithService from "../../riutilizabili/create_autocompleteWithService";
import Switch from "../../riutilizabili/forms/Switch";
import FormError from "../../riutilizabili/FormError";
import classZone_fetch from "../../fetch/classZone_fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import CreaAnagrafica from "../../anagrafica/CreaAnagrafica";
import InfoModal from "../../riutilizabili/InfoModal";
import ViewAnagrafica from "../../anagrafica/ViewAnagrafica";
import classSezione from "../../fetch/classSezione";
import SwitchOr from "../../riutilizabili/forms/SwitchOr";
import anFornitore_fetch from "../../fetch/anFornitore_fetch";


export default function AnagraficaCliente({
    value,
    errors,
    onChange ,
    modifica = false
}) {
    const [viewSelezionaCliente , setViewSelezionaCliente]=useState(false)
    const [viewAnCliente , setViewAnCliente]=useState(false)
    // const [datiNewAn , setDatiNewAn]=useState({})
    // const [errorCrea , setErrorCrea]=useState({})
    const [inputs, setInputs] = useState({...value});

    const [datiNewAn , setDatiNewAn]= useState({liberoProfessionista: 1, azienda: 0, privato: 0, entePubblico: 0})
    const [errorCrea , setErrorCrea]= useState({cap:"Campo obbligatorio",comuneResidenza:"Campo obbligatorio",indirizzo:"Campo obbligatorio",numeroCivico:"Campo obbligatorio",pIva:"Campo obbligatorio",provincia:"Campo obbligatorio",ragioneSociale:"Campo obbligatorio"})


    // const onCloseSelezionaCliente = async (stato) => {
    //     if (stato) {
    //         setViewSelezionaCliente(false)
    //     } else {
    //         setViewSelezionaCliente(false) 
    //     }
    // }

    const onChangeAn = (nome , valore)=> {
        if (!!value.clienteId) {
            onChange('clienteId' , '')
        }
        onChange('an'+nome.charAt(0).toUpperCase()+nome.slice(1) , valore)
    }
    
    const selezionaUser = async (value)=> {
        if (!!inputs.clienteId && inputs.clienteId === value ) {
           let tmp = {...inputs};
           delete tmp.clienteId ;
           delete tmp['ag'+value] ;
           return setInputs(tmp)
        }
        onChange('clienteId', value)
        setInputs(values => ({ ...values, 'clienteId': value }))
        setInputs(values => ({ ...values, ['ag'+value]: true }))
        let datiCliente = await anCliente_fetch.get({id : value});
        datiCliente = datiCliente.data[0]
        delete datiCliente.id
        for (let index = 0; index < Object.keys(datiCliente).length; index++) {
            const element = Object.keys(datiCliente)[index];
            
            if (element.substring(0 ,2) !== 'an') {
                onChange('an'+element.charAt(0).toUpperCase()+element.slice(1), datiCliente[element])
            }
        }
    }

    const campi = {
        text : [
            {
                label : 'Ragione sociale',
                name : 'ragioneSociale',
                lungCamp :'40%'
            },
            {
                label : 'nome',
                name : 'nome',
                lungCamp :'25%'
            },
            {
                label : 'cognome',
                name : 'cognome',
                lungCamp :'25%'
            },
            {
                name : 'indirizzo',
                label : 'indirizzo',
                lungCamp :'50%'
            },
            {
                label : 'comune',
                name : 'comune',
                lungCamp :'30%'
            },
            {
                label : 'provincia',
                name : 'provincia',
                lungCamp :'20%'
            },
 
            {
                label : 'sede legale',
                name : 'sedeLegale',
                lungCamp :'30%'
            },
            {
                label : 'codice fiscale',
                name : 'codiceFiscale',
                lungCamp :'40%'
            },
            {
                label : 'paetita iva',
                name : 'pIva',
                lungCamp :'40%'
            },
            {
                label : 'email',
                name : 'email',
                lungCamp :'40%'
            },

        ],
        number : [
            {
                label : 'numero civico',
                name : 'numeroCivico',
                lungCamp :'15%'
            },
            {
                label : 'cap',
                name : 'cap',
                lungCamp :'14%'
            },
            {
                label : 'numero telefono',
                name : 'numeroTelefono1',
                lungCamp :'20%'
            }
        ],
        date : [
            // {
            //     name : 'dataFattura',
            //     label : 'data fattura'
            // }
        ],
        'datetime-local' : [
            // {
            //     name : 'dataOraPartenza',
            //     label : 'data e ora partenza'
            // }
        ]
    }

    const pulisciCampiAn = (oggetto) => {
        //console.log(oggetto);
        let tmp = {};
        for (let index = 0; index < Object.keys(oggetto).length; index++) {
            const element = Object.keys(oggetto)[index];
            if (element.substring(0 ,2) !== 'an') {
                tmp[element]= oggetto[element]
            }else {
                let nome = element.substring(2 ,element.length);
                //console.log(nome);
                tmp[nome.charAt(0).toLocaleLowerCase()+nome.slice(1)]= oggetto[element]
            }
            //console.log(tmp , ' tmp');
        }
        return tmp
    }

    const onChangeTipoAn = ({item , value})=> {
        //console.log(item , ' item');
       // console.log(value , ' value');
        
        onChange('anTipo', item.id)
        onChange('anTipoAnaNome', value)
    }

    const TipoClienteAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anCliente_fetch.tipoAn({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome,
        itemToString: item => item.nome,
        value: value?.anTipoAnaNome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Tipo anagrafica',
        placeholder: 'inserisci tipo anagrafica',
        onChange : onChangeTipoAn,
        className :  errors?.tipo ? 'is-invalid w-50' : 'w-50 '
    });
    
    const campiDestinatario = {
        text : [
            {
                name : 'indirizzoDestinatario',
                label : 'indirizzo destinazione',
                lungCamp :'40%'
            },
            {
                name : 'civicoDestinatario',
                label : 'civico destinazione',
                lungCamp :'40%'
            },
            {
                name : 'capDestinatario',
                label : 'cap destinazione',
                lungCamp :'40%'
            },
            {
                name : 'ragioneSocialeDestinatario',
                label : 'Denominazione destinazione',
                lungCamp :'40%'
            },
        ]
    }

    const onChangeProdottiProvincia = async ({ value, item }) => {
        console.log(item , ' item ',value);
        onChange('provianciaDestinatario', item?.sigla)
    }
    console.log(value , ' value ss');

    const AutocompleteProdottiProvincia = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classZone_fetch.provinciaLike({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome + ' (' + item.sigla + ')',
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Seleziona provincia',
        placeholder: 'inserisci provincia',
        onChange: onChangeProdottiProvincia,
        value: value?.provianciaDestinatario
    });

    const onChangeProdottiComune = async ({ value, item }) => {
        onChange('comuneDestinatario', item?.nome)
    }

    const eliminaAn = () => {
        onChange('clienteId',null)
        onChange('anNominazioneCliente',null)
    }
    
    const onChangeMagazzino = async ({ value, item }) => {
        onChange('magazzinoNome', item?.nome)
        onChange('magazzinoId', item?.id)
        onChange('codiceMag', item?.codice)
        
        let tipoMag = 'Magazzino'
        if (item?.opera === 1) {
            tipoMag = ' operativo'
        }
        if (item?.mobile === 1) {
            tipoMag = ' mobile'
        }
        if (item?.bts === 1) {
            tipoMag = ' POP/BTS'
        }
        onChange('tipoMag', tipoMag)

        onChange('prods', [])
    }

    const AutocompleteMagazzino = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classSezione.get({
            magazzinoPrincipale :1 ,
            nome: myValue,
            ...serviceValues,
            autoComplite: 1
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Seleziona magazzino',
        placeholder: 'inserisci magazzino',
        onChange: onChangeMagazzino,
        value: value?.magazzinoNome,
        disabled : modifica
    });

    const AutocompleteProdottiComune = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classZone_fetch.comuneLike({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Seleziona comune',
        placeholder: 'inserisci comune',
        onChange: onChangeProdottiComune,
        value: value?.comuneDestinatario
    });

    const onChangeCliente = ({item , value}) => {
        console.log(item , value);
        if (!!item) {
            onChange('anNominazioneCliente' , value)
            onChange('clienteId', item.id)
            Object.keys(item).forEach(element => {
                // console.log('an'+element.charAt(0).toUpperCase() + element.slice(1));
                onChange('an'+element.charAt(0).toUpperCase() + element.slice(1) ,item[element] )
            });
            
        }
    }

    const AutocompleteAn = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anCliente_fetch.autocompliteAn({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.viewName?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.viewName,
        itemToString: item => item.viewName,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.viewName.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        onChange: onChangeCliente ,
        //redflag : redflagFonia,
        label: 'Seleziona cliente :*',
        placeholder: 'inserisci cliente',
        value: value?.anNominazioneCliente,
    });

    const onCloseSelezionaCliente = async (stato) => {
        if (stato) {
            try {
                const an = await anCliente_fetch.create(datiNewAn)
                console.log(an.data ,' an.data');
                onChange('anNominazioneCliente' , an.data.viewName)
                onChange('clienteId', an.data.id)
                Object.keys(an.data).forEach(element => {
                    // console.log('an'+element.charAt(0).toUpperCase() + element.slice(1));
                    onChange('an'+element.charAt(0).toUpperCase() + element.slice(1) ,an.data[element] )
                });
                setViewSelezionaCliente(false)
                
            } catch (error) {
                setErrorCrea(error.response.data)
            }
        } else {
            setViewSelezionaCliente(false) 
            setDatiNewAn({}) 
        }
    }
    const onChangeTipoAnCrea = (tipoAn) => {
        switch (tipoAn) {
          case 'liberoProfessionista':
            setDatiNewAn(values => ({ ...values, liberoProfessionista: 1 }))
            setDatiNewAn(values => ({ ...values, azienda: 0 }))
            setDatiNewAn(values => ({ ...values, privato: 0 }))
            setDatiNewAn(values => ({ ...values, entePubblico: 0 }))
          break;
          case 'azienda':
            console.log(' values azienda');
            
            setDatiNewAn(values => ({ ...values, liberoProfessionista: 0 }))
            setDatiNewAn(values => ({ ...values, azienda: 1 }))
            setDatiNewAn(values => ({ ...values, privato: 0 }))
            setDatiNewAn(values => ({ ...values, entePubblico: 0 }))
          break;
          case 'privato':
            setDatiNewAn(values => ({ ...values, liberoProfessionista: 0 }))
            setDatiNewAn(values => ({ ...values, azienda: 0 }))
            setDatiNewAn(values => ({ ...values, privato: 1 }))
            setDatiNewAn(values => ({ ...values, entePubblico: 0 }))
          break;
          case 'entePubblico':
            setDatiNewAn(values => ({ ...values, liberoProfessionista: 0 }))
            setDatiNewAn(values => ({ ...values, azienda: 0 }))
            setDatiNewAn(values => ({ ...values, privato: 0 }))
            setDatiNewAn(values => ({ ...values, entePubblico: 1 }))
          break;
      }
    }

    
    const onChangeFornitore = ({item , value}) => {
        if (item) {
            console.log(item , value);
            onChange('anNominazioneCliente' , value)
            onChange('clienteId', item.id)
            Object.keys(item).forEach(element => {
                // console.log('an'+element.charAt(0).toUpperCase() + element.slice(1));
                onChange('an'+element.charAt(0).toUpperCase() + element.slice(1) ,item[element] )
            });
        }
    }
    
    const AutocompleteFornitore = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anFornitore_fetch.autocompliteAn({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.viewName?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.viewName,
        itemToString: item => item.viewName,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.viewName.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        onChange: onChangeFornitore ,
        //redflag : redflagFonia,
        label: 'Seleziona Fornitore :*',
        placeholder: 'inserisci Fornitore',
        value: value?.anNominazioneCliente,
    });
    
    console.log(errorCrea , ' errorCrea');
    
    return(
        <div>
            <div className="border border-primary border-3 p-3 rounded-4 m-3" >
                <h3 className="text-primary ms-2" >Seleziona anagrafica Destinatario </h3>
                <div className="w-100">
                    <SwitchOr
                        label={'Rientro'}
                        stato={value.mittenteEntita === 'f'}
                        number='mittenteEntita'
                        onChangeValue={(stato , nome)=> {onChange('mittenteEntita' , !stato ? 'c' : 'f'); onChange('anNominazioneCliente' ,''); onChange('clienteId' ,'')}}
                        classe={' ps-1 ms-4 py-3'}
                        width="7%"
                
                    />
                </div>
                <div className="d-flex align-items-center">
                    <span className="col-4 ms-2">
                        {value.mittenteEntita !== 'c'?<AutocompleteFornitore /> : <AutocompleteAn />}
                    </span>
                    {!!value?.clienteId && 
                    <>
                        <button className="btn" onClick={()=>setViewAnCliente({ id : value.clienteId ,viewName : value.anNominazioneCliente })} >
                            <FontAwesomeIcon icon={faEye} />
                        </button>
                        <button className="btn" onClick={eliminaAn}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </>
                    }
                    <button onClick={()=>setViewSelezionaCliente(true)} className="border p-2 rounded-5 m-3 px-3 bg-primary text-white">
                            <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <FormError errors={errors} field='clienteId' />
                <div className="col-3 d-inline-block ms-2">
                    <AutocompleteMagazzino />
                </div>
                </div>
                
            </div>

            {viewSelezionaCliente && <QuestionModal
                    title='Crea cliente'
                    text={
                        <CreaAnagrafica 
                            onChange={(nome , valore)=> setDatiNewAn(values => ({...values , [nome]: valore}))}
                            values={datiNewAn}
                            error={errorCrea}
                            onChangeError={(error)=> setErrorCrea(error)}
                            onChangeTipoAn={onChangeTipoAnCrea}
                        />
                    }
                    yesDisabled={Object.keys(errorCrea).length > 0}
                    onClose={onCloseSelezionaCliente}
            />}

            {!!viewAnCliente &&
            <InfoModal
                classi={' modal-xxl '}
                title={'Anagrafica cliente '+viewAnCliente.viewName}
                text={<ViewAnagrafica id={viewAnCliente.id} />}
                canClose
                handleClose={()=>setViewAnCliente(false)}
            />}

            

            {/* <div className="border border-primary border-3 p-3 rounded-4 m-3" >
                <h3 className="text-primary ms-2" >Anagrafica Destinatario </h3>
                <div className="w-25 ms-2 m-5">
                    <TipoClienteAutocomplite />
                </div>

                <GeneraCampiText
                    campi={campi}
                    inputs={pulisciCampiAn(value)}
                    onChange={onChangeAn}
                    errors={errors}
                />

                <Switch
                    label='Destinazione diversa da destinatario'
                    stato={!!value.indirizzoDestinazioneDiverso }
                    number='indirizzoDestinazioneDiverso'
                    onChangeValue={(valore , nome)=>onChange(nome , valore+'')}
                    width='32%'
                    classe='m-3 mt-5 mb-5 pb-5 ps-0'
                />
                {!!value.indirizzoDestinazioneDiverso  &&
                    <>
                        <h3 className="text-primary ps-2">Destinazione</h3>
                            <div className='col-4 d-inline-block ps-3'>
                                <AutocompleteProdottiProvincia />
                                <FormError errors={errors} field="provianciaDestinatario" />
                            </div>
                            <div className='col-4 ms-5 d-inline-block'>
                                <AutocompleteProdottiComune disabled={!value?.provianciaDestinatario} serviceValues={{ prov: value?.provianciaDestinatario }} />
                                <FormError errors={errors} field="comune" />
                            </div>
                            <GeneraCampiText 
                                campi={campiDestinatario}
                                inputs={value}
                                onChange={onChange}
                                errors={errors}
                            />
                            
                    </>
                }

                {viewSelezionaCliente && <QuestionModal
                    title='Seleziona cliente'
                    text={<SelectAnMultiple onChange={selezionaUser} values={inputs} />}
                    onClose={onCloseSelezionaCliente}
                />}
            </div> */}
        </div>
    )
}