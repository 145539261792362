import { useEffect, useState } from "react"
import InputForm from "../../riutilizabili/forms/InputForm"
import FormError from "../../riutilizabili/FormError"


export default function CodiciForms({
    item ,
    onChange ,
    prods =[] ,
    errors = {}
}) {
    
    const [countRigaCodici , setCountRigaCodici] = useState({})
    const [codici , setCodici] = useState({})

    useEffect(()=> {
        editSerialiProdUscta()
    },[])

    
    const editSerialiProdUscta = async () => {
        let codice = {}
        let righe = {}

        if (prods && prods.length > 0) {
            prods.forEach(element => {
                if (element.ProdSerialis) {
                    element.ProdSerialis.forEach((cod, key) => {
                        if (cod?.seriale) {
                            codice[cod.prodSezioneId + 'S' + parseInt(key)] = cod.seriale;
                        }
                        if (cod?.provisioning) {
                            codice[cod.prodSezioneId + 'P' + parseInt(key)] = cod.provisioning;
                        }
                        if (!righe[cod.prodSezioneId]) {
                            righe[cod.prodSezioneId] = {}
                        }
                        righe[cod.prodSezioneId][parseInt(key)] =1
                    });
                }
            });
        }

           
        setCountRigaCodici(righe)
        setCodici(codice)
    }
    
    const addRigaCodici = (id ,codice , qt)=> {
        let count = {...countRigaCodici}
        console.log(parseInt(qt) ,parseInt(codice), ' qt ,codice');
        
        if (parseInt(qt) > (parseInt(codice))) {
            
            if (!count[id]) {
                count[id] = {
                    1 : 1 , 
                    2 : 1 , 
                }
            }else {
                count[id][codice] = 1
            }
        }
        
        setCountRigaCodici(count)
    }

    const addCodiceSeriale = (id , posizione , seriale) => {
        let tmp = {...codici}
        
        tmp[id+'S'+posizione] = seriale
        setCodici(tmp)
        onChange('codici' , tmp)
    }

    const addCodiceProvisioning = (id , posizione , codice) => {
        let tmp = {...codici}
        
        tmp[id+'P'+posizione] = codice
        setCodici(tmp)
        onChange('codici' , tmp)
    }
    console.log(item , ' item');
    
    return (
        <td colSpan="11">
        <div className="d-flex flex-wrap align-items-center">
            <div className="col-12 d-flex flex-wrap align-items-center">
                <p className="p-0 m-0 col-2 ps-5 ms-5 me-5 ">
                    Seriali :
                </p>
                <p className="p-0 m-0 col-3 ps-5 ms-5">
                    Provisioning :
                </p>

            </div>
            <div className="col-8 py-3">
                {/* <textarea className="form-control" name="" id="" onChange={(e)=>copiaSeriali(e ,item.id)} >
                    {item.seriali}
                </textarea> */}
                {countRigaCodici[item.id || item.prodottoId] ? 
                <>
                {Object.keys(countRigaCodici[item.id || item.prodottoId ]).map(codice => {
                    console.log(codice , ' codice' , (item.id || item.prodottoId)+'S'+codice);
                    
                    return(
                        <div className="d-flex flex-wrap" key={codice}>
                            <InputForm
                                name={'seriali'}
                                type='text'
                                label={' '}
                                value={codici[(item.id || item.prodottoId)+'S'+codice]}
                                onChange={(nome , valore)=>addCodiceSeriale(item.id || item.prodottoId , codice , valore)}
                                classi=" col-4 m-0 p-0 d-flex "
                                errors={{}}
                                onClick={()=>addRigaCodici(item.id || item.prodottoId ,parseInt(codice)+1 ,item.quantita )}
                            />
                            
                            <InputForm
                                name={'seriali'}
                                type='text'
                                label={' '}
                                value={codici[(item.id || item.prodottoId)+'P'+codice]}
                                onChange={(nome , valore)=>addCodiceProvisioning(item.id || item.prodottoId , codice , valore)}
                                classi=" col-4 m-0 p-0 d-flex "
                                errors={{}}
                                onClick={()=>addRigaCodici(item.id || item.prodottoId ,parseInt(codice)+1,item.quantita)}
                            />
                        </div>
                    )
                })
                }
                </>
                :
                <div className="d-flex flex-wrap" >
                            <InputForm
                                name={'seriali'}
                                type='text'
                                label={' '}
                                value={codici[(item.id || item.prodottoId)+'S'+0]}
                                onChange={(nome , valore)=>addCodiceSeriale(item.id || item.prodottoId , 1 , valore)}
                                classi=" col-4 m-0 p-0 d-flex "
                                errors={{}}
                                onClick={()=>{addRigaCodici(item.id ||item.prodottoId , 1 , item.quantita )}}
                            />
                            <InputForm
                                name={'seriali'}
                                type='text'
                                label={' '}
                                value={codici[(item.id || item.prodottoId)+'P'+0]}
                                onChange={(nome , valore)=>addCodiceProvisioning(item.id || item.prodottoId , 1 , valore)}
                                classi=" col-4 m-0 p-0 d-flex "
                                errors={{}}
                                onClick={()=>addRigaCodici(item.id ||item.prodottoId , 1 , item.quantita)}
                            />
                </div>
                }
            </div>
        </div>
        <FormError errors={errors} field='seriali' />
    </td>
    )
}