import { useState } from "react";
import InfoModal from "../riutilizabili/InfoModal";
import create_autocompleteWithService from "../riutilizabili/autocomplite/create_autocompleteWithService";
import classSezione from "../fetch/classSezione";
import TabellaProdotti from "./TabellaProdotti";
import prodSituazione_fetch from "../fetch/prodSituazione_fetch";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import Tabella from "../riutilizabili/Tabella";


export default function ViewProdDisp({
    prodottoId ,
    classButton ,
    iconButton ,
    nomeProd , 
    unitaMisura
}) {
    const [view , setView] = useState(false)
    const [sezione , setSezione] = useState({})
    const [prods , setProds] = useState([]);


    const viewPDisp = async () => {
        setView(true)
        await readProds()
    }

    const readProds = async (param) => {
        console.log(prodottoId , ' prodottoId');
        
        const response = await prodSituazione_fetch.readProdSituazioneMagazzino({ ...param ,qtDisp : 1 , prodottoId})
        setProds(response.data)
    } 
    
    const columns = [
        {
            Header: 'Codice interno',
            accessor: 'codice'
        },
        {
            Header: 'Magazzino',
            accessor: 'magazzino'
        },
        {
            Header: 'Tipo magazzino',
            accessor: 'tipo'
        },
        {
            Header: 'Quantità',
            accessor: 'qta',
        },
        {
            Header: 'Unità di misura',
            original: 'umId',
            Cell: ({ row: { original } }) =>unitaMisura

        },
    ]

    return(
        <>
            <button className={"btn "+classButton} onClick={viewPDisp} title="Articoli disponibili" >
                {iconButton}
            </button>

           {!!view && <InfoModal
                title={<span>Prodotti disponibili per il prodotto <strong>{nomeProd}</strong></span>}
                text={<>
                    <div className="col-11 ms-3 pt-3">
                        <Tabella
                            columns={columns}
                            data={prods}
                        />
                    </div>
                </>}
                classi={'modal-xxl'}
                canClose
                handleClose={()=>{
                    setView(false)
                    setSezione({})
                }}
            />}
        </>
    )
}