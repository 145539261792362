import { useState } from "react";
import bolla_fetch from "../fetch/bolla_fetch";
import InfoModal from "../riutilizabili/InfoModal";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti";
import QuestionModal from "../riutilizabili/QuestionModal";
import { format } from "date-fns";
import { daEuCent } from "../riutilizabili/daEurCent";
import CampiBollaUscita from "./uscita/CampiBollaUscita";
import ConfermaCreazione from "../riutilizabili/ConfermaCreazione";
import classSezione from "../fetch/classSezione";




export default function CreaBollaUscitaDaEntrata({
    bollaEntrataId ,
    iconaButtom ,
    classButtom
}) {
    const [ prods, setProds ] = useState([])
    const [errors , setErrors] = useState({});

    const [inputs , setInputs] = useState({
            dataFattura : format(new Date(), 'yyyy-MM-dd') , 
            dataOraPartenza :format(new Date(), "yyyy-MM-dd'T'HH:mm"),
            magazzinoId : 1,
            magazzinoNome : "Magazzino Ancona"
        });
    const [statoAvanti , setStatoAvanti] = useState(false);
    const [campiObSegnale , setCampiObSegnale] = useState({});

    const [inputsFiltra , setInputsFiltra] = useState({orderMoreRecente : 'DESC'});
    const [arrayBolla , setArrayBolla] = useState([]);

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)

    const [viewAttendere , setViewAttendere] = useState(false);
    const [viewCrea , setViewCrea] = useState(false);
    const [viewCreaAllert , setViewCreaAllert] = useState(false);

    const campiObbligatori = [
        'dataFattura',
    ]
    
    const readProd = async ()=> {
        const prodsBollaUscita = await bolla_fetch.creaBollaUscitaDaEntrata({bollaEntrataId})
        const bolla = await bolla_fetch.get({id : bollaEntrataId})
        console.log(bolla.data[0].magazzinoId, ' bolla');
        const magazzino = await classSezione.get({id : bolla.data[0].magazzinoId})
        console.log(magazzino.data[0].nome , ' bolla magazzino');
        
        console.log(prodsBollaUscita.data , ' prodsBollaUscita');
        setViewCrea(true)
        setInputs(values => ({ ...values, prods: prodsBollaUscita.data , magazzinoId : bolla.data[0].magazzinoId , magazzinoNome : magazzino.data[0].nome }))
    }
        
    const onChangeValue = (nome , valore) => {
        //console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputs(values => ({ ...values, [nome]: cent }))
            setInputs(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputs(values => ({ ...values, [nome]: valore }))
        }
        setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , funzioniRiccorrenti.pulisciCampiAn(inputs) , campiObSegnale , setCampiObSegnale))
    }


    const readBolle = async () => {
        const array = await bolla_fetch.getBollaUscita({page, pageSize ,orderMoreRecente : 'DESC'});
        setInputsFiltra({})
        setArrayBolla(array.data.data);
        setPageTot(array.data.pageTot)
        setFu(fu+1)
    }

    const onChangeCreaBolla = async (stato) => {
        if (stato) {
            setViewAttendere(true)
            setViewCrea(false)
            await bolla_fetch.creaBollaUscita(inputs)
            .then(response => {
                setInputs({})
                readBolle()
                setErrors({})  
                setViewAttendere(false)
                setViewCrea(false)
                setViewCreaAllert(false)
            }).catch(errors => {
                setViewCrea(true)
                setErrors(errors.response.data)
                //console.log(errors.response.data , ' bollaUscita errori');
            })
            await readBolle()
        
            
        }else {
            setViewCreaAllert(false)
            setViewAttendere(false)
            setErrors({}) 
        }
        await readBolle()
    }

    const onChangeViewCreaBolla = (stato) => {
        if (!!stato) {
            setViewCreaAllert(true)
        } else {
            setViewCrea(false)
            setViewCreaAllert(false)
        }
    }

    return(
        <>
            <button className={classButtom} onClick={readProd} >
                {iconaButtom}
            </button>

            {viewCrea &&
                <QuestionModal
                    title={'Aggiungi ddt in uscita'}
                    text={
                        <div>
                            <CampiBollaUscita
                                inputs={inputs} 
                                errors={{...errors , ...campiObSegnale}}
                                onChange={onChangeValue}
                                modifica={false}
                            />
                        </div>
                    }
                    YesLabel='Salva'
                    yesDisabled={!statoAvanti}
                    onClose={onChangeViewCreaBolla}
                    classi=' modal-xxl '
                />
            }
            <ConfermaCreazione
                label={'ATTENZIONE!!! OPERAZIONE IRREVERSIBILE'}
                testo={'stai prelevando i seguenti prodotti dal magazzino '+ inputs?.magazzinoNome + ' ?'}
                conferma={onChangeCreaBolla}
                stato={viewCreaAllert}
            />
        </>
    )
}