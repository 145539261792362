import React, { useEffect, useState } from "react";
import { faAnglesRight, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import prodSezioneService from "../fetch/classProdSezzione";
import CampiProdotti from './CampiProdotti';
import TabellaProdotti from './TabellaProdotti.js';
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import QuestionModal from "../riutilizabili/QuestionModal";
import CreaProdSezione from "./prodottiSezione/CreaProdSezione";
import { daEuCent } from "../riutilizabili/daEurCent.js";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti.js";
import Switch2 from "../riutilizabili/Switch2.js";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra.js";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea.js";
import Switch from "../riutilizabili/forms/Switch.js";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InputForm from "../riutilizabili/forms/InputForm.js";
import create_autocompleteWithService from "../riutilizabili/create_autocompleteWithService.js";
import anFornitore_fetch from "../fetch/anFornitore_fetch.js";
import prodSezioneService from "../fetch/prodSituazione_fetch.js";
import SpostaProd from "../ProdSituazione/SpostaProd.js";
import classSezione from "../fetch/classSezione.js";

export default function ViewsProdottiSezioni() {
    const [inputs, setInputs] = useState({});
    const [arrayProds, setArrayProds] = useState([]);
    const [inputsModifica, setInputsModifica] = useState({});
    const [inputsFiltra, setInputsFiltra] = useState({qtDisp: 1});
    const [errors, setErrors] = useState({});
    const [viewCrea, setViewCrea] = useState(false);
    const [viewModifica, setViewModifica] = useState(false);
    const [statoAvanti , setStatoAvanti] = useState(false);
    const [statoAvantiMod , setStatoAvantiMod] = useState(false);
    const [campiObSegnale , setCampiObSegnale] = useState({
        'umId': 'campo unità di misura obbligatorio',
        'quantita': 'campo quantità obbligatorio',
        'nome': 'campo quantità obbligatorio',
        'categoriaId': 'campo quantità obbligatorio',
        'prezzoVenditaCent': 'campo quantità obbligatorio',
    });

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)

    const campiOb = [
        'umId',
        'quantita',
        'nome',
        'categoriaId',
        'prezzoVenditaCent',
    ]
    
    useEffect(()=> {
        setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiOb , inputs , campiObSegnale ,setCampiObSegnale))
    },[inputs])
    
    useEffect(()=> {
        setStatoAvantiMod(funzioniRiccorrenti.abilitaAvanti(campiOb, inputsModifica , campiObSegnale ,setCampiObSegnale))
    },[inputsModifica])

    useEffect(() => {
        readProdSezione({page , pageSize})
    }, [])
    //console.log(arrayProds, ' arrayProdss');

    const readProdSezione = async (params) => {
        const arrayProds = await prodSezioneService.get({bollaUscitaId : -1 , qtDisp: 1 , ...params});
        setInputsFiltra({qtDisp: 1})
        //console.log(arrayProds.data , ' arrayProds');
        setArrayProds(arrayProds.data.data )
        setPageTot(arrayProds.data.pageTot )
        setFu(fu+1)
    }

    const recuperaDatiModProdSez = (dati) => {
        setCampiObSegnale({})
        let tmp ={...dati};
        tmp.nomeUnitaMisura = dati?.infoUnitaMisura?.nome
        tmp.umId = dati?.infoUnitaMisura?.id
        //console.log(tmp , ' umId');
        const array = Object.keys(tmp.infoProd)
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            
            if (element !== 'id'
            && element !== 'userCreate'
            && element !== 'userLastmod'
            && element !== 'stato'
            && element !== 'dataCreate'
            && element !== 'dataLastmod') {
                
                if (element === 'infoCategoria') {
                    tmp.categoria_nome =tmp.infoProd[element]?.nome
                }else if(element === 'infoFornitore') {
                    tmp.fornitore_nome = tmp.infoProd[element]?.nome
                }else {
                    if (!!tmp.infoProd[element]) {
                        tmp[element] = tmp.infoProd[element]
                    }
                }
            }
        }
        setInputsModifica(tmp)
        //console.log(tmp, ' dati modifica')

        setViewModifica(true)
    }

    const eliminaProd = async(id) => {
        console.log(id, ' id');
        let elimina = await prodSezioneService.delete({id});
        if (elimina.status === 200) {
            readProdSezione({pageSize , page , ...inputsFiltra})
        }
        console.log(elimina, 'elimina');
    }

    const Pulsanti = ({element}) => {
        return (
            <td className="py-0 my-0">
                <SpostaProd 
                    prodottoId={element.prodottoId}
                    sezionePartenzaId={element.sezioneId}
                    qtaMax={element.qta}
                    itemButton={<FontAwesomeIcon icon={faAnglesRight} />}
                    aggiorna={()=>readProdSezione({page : 1 , pageSize})} 
                />
            </td>
        )
    }

    const onChange = (nome, valore) => {
        if (nome.slice(-4) === 'Cent') {
            //console.log(valore, ' valore allarme')
            const cent = daEuCent(valore)
            setInputs(values => ({ ...values, [nome]: cent }))
            setInputs(values => ({ ...values, [nome.slice(0, nome.length - 4) + 'Eur']: valore }))
        } else {

            setInputs(values => ({ ...values, [nome]: valore }))
        }
    }

    const onChangeModifica = (nome, valore) => {
        if (nome.slice(-4) === 'Cent') {
            //console.log(valore, ' valore allarme')
            const cent = daEuCent(valore)
            setInputsModifica(values => ({ ...values, [nome]: cent }))
            setInputsModifica(values => ({ ...values, [nome.slice(0, nome.length - 4) + 'Eur']: valore }))
        } else {

            setInputsModifica(values => ({ ...values, [nome]: valore }))
        }
    }
    //console.log(inputsModifica.infoProd)

    const onCloseCreaProd = async (stato) => {
        if (stato) {
            const prodotto = await prodSezioneService.create(inputs);
            //console.log(prodotto, ' prodotto')
            readProdSezione({inputsFiltra , page , pageSize})
            setViewCrea(false)
            setInputs({})
        } else {
            setViewCrea(false)
        }
    }

    const onCloseCreaMod = async (stato) => {
        if (stato) {
            try {
                const prodotto = await prodSezioneService.create(inputsModifica);
                console.log(prodotto, ' prodotto')
                readProdSezione({...inputsFiltra , page , pageSize})
                setViewModifica(false)
                setInputsModifica({})
            } catch (error) {
                console.log(error.response.data , ' error');
                setErrors(error.response.data[0])
                
            }
        } else {
            setViewModifica(false)
        }
    }

    const onChangeStatoFiltraSezVuota = (stato) => {
        if (stato) {
            setInputsFiltra(values => ({ ...values, sezioneId: -1 }))
            filtraProd({ sezioneId: -1})
        }else {
            let tmp = {...inputsFiltra};
            delete tmp.sezioneId
            setInputsFiltra(tmp)
            filtraProd(tmp)
        }
        
    }

    const filtraProd = async (param) => {
        //console.log(inputsFiltra , ' inputsFiltra al interno del filtra');
        const arrayProds = await prodSezioneService.get({page , pageSize ,...inputsFiltra , bollaUscitaId : -1 , ...param});
        setArrayProds(arrayProds.data.data )
        setPageTot(arrayProds.data.pageTot )
        setFu(fu+1)
    }

    console.log(inputsFiltra , ' inputsFiltra');
    console.log(arrayProds , ' arrayProds');
    
    const onChangeFiltra = async (name , value) => {
        await filtraProd({...inputsFiltra , [name]: value}) 
        setInputsFiltra(values => ({...values, [name]: value})) ; 
        setAzzeraVar(1)
        setAzzeraVar(0)
    }

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtraProd({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }
        
   const onChangeFornitore = ({item , value}) => {
    if (item) {
        console.log(item , value);
        setInputsFiltra(values => ({...values, ['anNominazioneFornitore']:  item.viewName}))
        onChangeFiltra('fornitoreId' , item.id)
    }
   }


   const AutocompleteFornitore = create_autocompleteWithService({
    freeinput: false,
    service: (myValue, serviceValues) => anFornitore_fetch.autocompliteAn({
        nome: myValue,
        ...serviceValues,
    }),
    itemCompare: (item, inputValue) => item.viewName?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
    itemToLi: item => item.viewName,
    itemToString: item => item.viewName,
    itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.viewName.toLowerCase() === value?.toLowerCase() ? curr : null), null),
    onChange: onChangeFornitore ,
    //redflag : redflagFonia,
    label: 'Seleziona Fornitore :',
    placeholder: 'inserisci Fornitore',
    value: !!inputsFiltra?.anNominazioneFornitore ? inputsFiltra.anNominazioneFornitore : ' ',
});

const onChangeSezione = ({item , value}) => {
    if (item) {
        console.log(item , value);
        setInputsFiltra(values => ({...values, ['nomeSezionePadre']:  item.nome}))
        onChangeFiltra('sezioneId' , item.id)
    }
   }

const onChangeMagazzino = ({item , value}) => {
    if (item) {
        console.log(item , value);
        setInputsFiltra(values => ({...values, ['nomeMagazzino']:  item.nome}))
        onChangeFiltra('prodMag' , item.id)
    }
   }

const SezioneAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classSezione.getAll({
            autoComplite : 1,
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona sezione padre' ,
        value: inputsFiltra.nomeSezionePadre ,
        onChange : onChangeSezione,
        placeholder: 'inserisci nome sezione padre',
    });

const Magazzinoutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classSezione.get({
            autoComplite : 1,
            magazzinoPrincipale : 1,
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona magazzino' ,
        value: inputsFiltra.nomeMagazzino ,
        onChange : onChangeMagazzino,
        placeholder: 'inserisci nome magazzino',
    });


console.log(inputsFiltra , ' inputsFiltra');


    return (
        <div className="p-2">
            <PaginazioneTitoloPulsanteCrea 
                titolo={'Articoli'} 
                //funCrea={() => {setViewCrea(true) ; setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiOb , inputs , campiObSegnale ,setCampiObSegnale))}} 
                funCrea={null}
            />

            <div className="p-4 ps-3">
                <div className="border_filtra p-3 mx-5"  >
                    {/* <CampiProdotti
                        value={inputsFiltra}
                        errors={{}}
                        onChange={(name, value) =>{ setInputsFiltra(values => ({ ...values, [name]: value })); filtraProd({[name]: value})}}
                        classiDiv='d-flex flex-wrap'
                    /> */}
                    <div className="d-flex flex-wrap">
                        <InputForm
                            label='Descrizione' 
                            type='text' 
                            placeholder='Inserisci descrizione'
                            name='descrizione'
                            value={!!inputsFiltra?.descrizione ? inputsFiltra.descrizione : ''}
                            onChange={(name , value) => onChangeFiltra(name , value)}
                            classi=' w-25 m-2'
                            errors={{}}
                        />
                        <InputForm 
                            label='Codice a barre' 
                            type='text' 
                            maxLenght='13'
                            placeholder='Inserisci Codice a barre'
                            name='codBarre'
                            value={!!inputsFiltra.codBarre ? inputsFiltra.codBarre : ''}
                            onChange={(name , value) => onChangeFiltra(name , value)}
                            classi='w-25 m-2'
                            errors={{}}
                        />
                        <InputForm 
                            label='Codice interno' 
                            type='text' 
                            placeholder='Inserisci codice interno'
                            name='rifInterno'
                            value={!!inputsFiltra.rifInterno ? inputsFiltra.rifInterno : ''}
                            onChange={(name , value) => onChangeFiltra(name , value)}
                            classi='w-25 m-2'
                            errors={errors}
                        />
                        <InputForm 
                            label='Codice produttore' 
                            type='text' 
                            placeholder='Inserisci codice produttore'
                            name='codProd'
                            value={!!inputsFiltra.codProd ? inputsFiltra.codProd : ''}
                            onChange={(name , value) => onChangeFiltra(name , value)}
                            classi='w-25 m-2'
                            errors={errors}
                        />
                        <div className="w-25 m-3" >
                            <AutocompleteFornitore />
                        </div>
                        <div className="w-25 m-3" >
                            <SezioneAutocomplite />
                        </div>
                        <div className="w-25 m-3" >
                            <Magazzinoutocomplite />
                        </div>
                        
                        {/* <InputForm 
                            label='Codice produttore *' 
                            type='text' 
                            placeholder='Inserisci codice produttore'
                            name='codProd'
                            value={!!inputsFiltra.codProd ? inputsFiltra.codProd : ''}
                            onChange={(name , value) =>{ onChangeFiltra(name , value)}}
                            classi='w-25 m-2'
                            errors={errors}
                        />
                        
                        <InputForm 
                            label='Codice interno *' 
                            type='text' 
                            placeholder='Inserisci codice interno'
                            name='rifInterno'
                            value={!!inputsFiltra.rifInterno ? inputsFiltra.rifInterno : ''}
                            onChange={(name , value) => onChangeFiltra(name , value)}
                            classi='w-25 m-2'
                            errors={errors}
                        /> */}
                        {/* <div className='w-25 m-2'>
                            <MagazzinoPrincipale />
                        </div>
                        <div className='w-25 m-2 me-5 ms-3'>
                            <Fornitore />
                        </div> */}
                    </div>
                    {/* <div className="d-flex">
                        <Switch
                            stato={!!inputsFiltra.sezioneId ? (inputsFiltra?.sezioneId === -1 ? 1 : 0) : 0 }
                            onChangeValue={(stato , nome) => onChangeStatoFiltraSezVuota(stato) }
                            label={'Articoli non allocati'}
                            number={'prodNotAl'}
                            classe={'m-0 p-0'}
                            width="12%"
                        />
                        <Switch
                            stato={!!inputsFiltra?.qtDisp && parseInt(inputsFiltra?.qtDisp)}
                            onChangeValue={(stato , nome) =>{ setInputsFiltra(values => ({ ...values, [nome]: stato+'' })); filtraProd({[nome]: stato+''})}}
                            label={'Articoli disponibili'}
                            number={'qtDisp'}
                            classe={'m-0 p-0 ms-4'}
                            width="12%"
                        />
                    </div> */}
                    <div className="ms-2 ps-1">
                        <PulsantiFiltra 
                            azzera={()=>readProdSezione({page : 1 , pageSize})} 
                            filtra={filtraProd}   
                        />
                    </div>
                </div>

                <div className='d-flex justify-content-around flex-wrap w-100 mx-3 pt-4' style={{paddingRight : '1.2rem'}}>
                    <TabellaProdotti
                        array={arrayProds}
                        Pulsanti={Pulsanti}
                        impaginazione={impaginazione}
                        pageTot={pageTot}
                        azzeraVar={azzeraVar}
                        fu={fu}
                    />
                </div>
            </div>


            {!!viewCrea && <QuestionModal
                title={<div className="ms-5 ps-3" >Crea articolo</div>}
                text={
                    <div>
                        <CreaProdSezione onChange={onChange} inputs={inputs} errors={{...errors , ...campiObSegnale}} />
                    </div>
                }
                yesDisabled={!statoAvanti}
                onClose={onCloseCreaProd}
            />}

            {!!viewModifica && <QuestionModal
                title='Modifica articolo'
                text={
                    <div>
                        <CreaProdSezione onChange={onChangeModifica} inputs={inputsModifica} errors={{...errors , ...campiObSegnale}} />
                    </div>
                }
                yesDisabled={!statoAvantiMod}
                onClose={onCloseCreaMod}
            />}
        </div>
    )
}