import React from "react";
import Tabella from "../../riutilizabili/Tabella"
import { daCentEu } from "../../riutilizabili/daEurCent"
import { format } from "date-fns";
import TabellaPaginazione from "../../riutilizabili/TabellaPaginazione";


export default function TabellaBolleUscita({
    array = [] ,
    Pulsanti ,
    pageTot ,
    azzeraVar ,
    impaginazione ,
    fu
}) {
    const columns = React.useMemo(
        () => [
            {
                id : 2,
                label: 'numero',
                nome: 'id',
                altCol: (item)=> {
                    return(
                        <>
                        {item.idProg +'/'+item.annoId +(!!item?.codiceMag ? ' - '+item.codiceMag : '') }
                        </>
                    )
                }
            },
            {
                id : 1,
                label: 'Data DDT',
                nome: 'dataFattura',
                altCol: (item)=> {
                    return(
                        <>
                        {format(item.dataFattura, 'dd/MM/yyyy')}
                        </>
                    )
                }
            },
            {
                id : 3,
                label: 'descrizione',
                nome: 'descrizione',
            },
            {
                id : 4,
                label: 'Cliente',
                nome: 'cliente',
                altCol: (item)=> {
                    return(
                        <>
                           {Object.keys(item.mittente).length > 0 && <>
                                {!!item.mittente.ragioneSociale ? item.mittente.ragioneSociale : item.mittente.cognome+' '+item.mittente.nome}
                            </>}
                        </>
                    )
                }
            },
            {
                id : 5,
                label: 'Peso totale in kg',
                nome:'totPesoKg'
            },
            {
                id : 6,
                label: 'Porto',
                nome:'porto'
            },
            {
                id : 7,
                label: 'N. colli',
                nome:'nColli'
            },
            {
                id : 8,
                label: 'Totale DDT',
                altCol: (item)=> {
                    return(
                        <>
                            {daCentEu(item.totFatturaCent)}
                        </>
                    )
                }
            },
        ],[]
    )
    return(
        <div className="w-100 p-4">
        {/* <Tabella
            data={array}
            columns={columns}
            Pulsanti={Pulsanti}
        /> */}
        <TabellaPaginazione
            dati={array} 
            colonne={columns} 
            totalePag={pageTot} 
            azzera={azzeraVar} 
            impaginazione={impaginazione}
            fu={fu}
            Pulsanti={Pulsanti} 
        />
    </div>
    )
}