import http from "./controllerFetch";

class prodSerialiService {
  get( params = {}) {
    return http.get('ProdSeriali/readProdSeriali', { params });
  }
  controllaSeriali( params = {}) {
    return http.get('ProdSeriali/controllaSeriali', { params });
  }

  create(data) {
    return http.post('ProdSeriali/creaProdSeriali', data);
  }


  elimina(data) {
    return http.post('ProdSeriali/deleteProdSeriali', {data});
  }



}

export default new prodSerialiService();