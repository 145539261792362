import axios from "axios";
import impostazioni from "../../cofig";
const http = 'https://geoshop.netoip.com/geo/ac.php';
//const http = 'http://api.mpm.studiotrau.com/geo/ac.php';
const api_url = impostazioni.api_url;

class classZone {

    comuneLike = async (param) => {
        //console.log(param, ' param');
        const resp = await axios({
            method: 'get',
            url: api_url + 'zona/comuneLike',
            responseType: 'json',
            params: param

        })
        .then(function (response) { 
            return response
        })
        .catch(function (response) {
            //console.log(JSON.stringify(response ,false,2));
            return response
        })
        return resp
    }

    regioneLike = async (param) => {
        //console.log(param, ' param');
        const resp = await axios({
            method: 'get',
            url: api_url + 'zona/regioneLike',
            responseType: 'json',
            params: param

        })
        .then(function (response) {
            return response
        })
        .catch(function (response) {
            //console.log(JSON.stringify(response ,false,2));
            return response
        })
        return resp
    }

    provinciaLike = async (param) => {
       // console.log(param, ' param');
        const resp = await axios({
            method: 'get',
            url: api_url + 'zona/provinciaLike',
            responseType: 'json',
            params: param

        })
        .then(function (response) {
            return response
        })
        .catch(function (response) {
            //console.log(JSON.stringify(response ,false,2));
            return response
        })
        return resp
    }

    nazioneLike = async (param) => {
       // console.log(param, ' param');
        const resp = await axios({
            method: 'get',
            url: api_url + 'zona/nazioneLike',
            responseType: 'json',
            params: param

        })
        .then(function (response) {
            return response
        })
        .catch(function (response) {
            //console.log(JSON.stringify(response ,false,2));
            return response
        })
        return resp
    }

    provincia = async (param) => {
        const resp = await axios({
            method: 'get',
            url: http,
            responseType: 'json',
            params: {
                ...param ,
                tipo : 'prov'
            }

        })
        return resp
    }

    comune = async (param) => {
        const resp = await axios({
            method: 'get',
            url: http,
            responseType: 'json',
            params: {
                ...param ,
                tipo : 'comune'
            }

        })
        return resp
    }
    indirizzo  = async (param) => {
        const resp = await axios({
            method: 'get',
            url: http,
            responseType: 'json',
            params: {
                ...param ,
                tipo : 'indirizzo'
            }

        })
        return resp
    }
    civico  = async (param) => {
        const resp = await axios({
            method: 'get',
            url: http,
            responseType: 'json',
            params: {
                ...param ,
                tipo : 'civico'
            }

        })
        return resp
    }
  
   
  }
  
  export default new classZone();