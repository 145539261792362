import React from "react"
import Modal from 'react-bootstrap/Modal'
import FormError from "./FormError"
import QuestionModal from "./QuestionModal"


export default function ConfermaCreazione({
    label,
    testo,
    nome,
    stato = 0 ,
    conferma ,
    error ={}
}) {

    const resp = (stato) => {
        if (stato) {
            conferma(true )
        }else {
            stato = 0
             conferma(false )
        }
    }

    return (
        <div>
            {stato &&<QuestionModal
                title={label }
                text={
                    <div>
                        <h4>
                            {testo}
                            <FormError
                                errors={error} 
                                field={nome}
                                classi='fs-6 d-block m-2'
                            />
                        </h4>
                    </div>
                }
                onClose={resp}
                AltFooter={
                    <Modal.Footer className="bg-primary text-white" >
                        <button type="button" onClick={() => resp(true)} className="btn bg-white text-primary">CONFERMA</button>
                        <button type="button" onClick={() => resp(false)} className="btn bg-white text-primary">ANNULLA</button>
                    </Modal.Footer>
                }
            />}

        </div>
    )
}