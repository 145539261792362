import React from "react";
import Tabella from "../riutilizabili/Tabella";
import { daCentEu } from "../riutilizabili/daEurCent";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import ViewProdPopUp from "./ViewProdPopUp";



export default function TabellaProdotti({
    array,
    Pulsanti ,
    impaginazione ,
    pageTot ,
    azzeraVar,
    fu
}) {
    
    const columns = [
        {
            label: 'Codice interno',
            nome: 'rifInterno',
            altCol: (item)=>{
                return(
                    <>
                        {item?.Prodotti?.rifInterno}
                    </>
                )
            }
        },
        {
        label: 'Descrizione',
        nome: 'Descrizione',
        altCol: (item)=> {
            return(
                <>
                    <ViewProdPopUp
                        icona={item?.Prodotti?.descrizione}
                        prodAnId={item?.Prodotti?.id}
                        classiButton='d-flex'
                    />
                </>
            )
            }
        },
        {
        label: 'Codice a barre',
        nome: 'codBarre',
        altCol: (item)=>item?.Prodotti?.codBarre
        },
        {
            label: 'Unità di misura',
            nome: 'umId',
            altCol: (item)=> {
                console.log(item , ' item');
                
                return item?.Prodotti?.infoUnitaMisura?.nome
            }
            },
        {
        label: 'Quantità',
        nome: 'qta',
        altCol : (item)=> <span className={parseInt(item.qta) < item.Prodotti.qtMin && ' text-danger fw-bold '} > {item.qta} </span>
        },
        {
        label: 'Prezzo',
        nome: 'prezzoUnitarioCent',
         altCol: (item)=> {
            return(
                <>
                    {daCentEu(item?.Prodotti?.prezzoUnitarioCent)}
                </>
            )
        }
        },
        {
            label: 'Fornitore',
            nome: 'Fornitore',
            altCol: (item)=> {
                console.log(item , ' item');
                
                return item?.Prodotti?.infoFornitore?.viewName
            }
        },
        {
            label: 'Magazzino',
            nome: 'magazzino',
            altCol: (item)=> {
                return(
                    <>
                        {!!item?.Sezioni?.magazzinoId ? item?.Sezioni?.magazzino.nome : item?.Sezioni?.nome}
                    </>
                )
            }
        },
        {
            label: 'Sezione padre',
            nome: 'infoSezione',
            altCol: (item)=> {
                return(
                    <>
                        {item?.Sezioni?.magazzinoId !== null ? item?.Sezioni?.nome : '-'}
                    </>
                )
            }
        },
    ]

    return(
        <div className="w-100 m-4" >
            <TabellaPaginazione
                dati={array}
                colonne={columns}
                Pulsanti={Pulsanti}
                impaginazione={impaginazione}
                totalePag={pageTot}
                azzera={azzeraVar}
                fu={fu}
            />
        </div>
    )
}