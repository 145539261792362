import React from "react";
import Tabella from "../../riutilizabili/Tabella";
import { daCentEu } from "../../riutilizabili/daEurCent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import TabellaPaginazione from "../../riutilizabili/TabellaPaginazione";

export default function TabellaBolle({
    array = [] ,
    Pulsanti ,
    FiltraBolla ,
    onChangeFiltraBolla ,
    pageTot ,
    azzeraVar,
    impaginazione ,
    fu 
}) {
    //console.log('====================================');
    //console.log(FiltraBolla,  !FiltraBolla?.data ,' FiltraBolla');
    //console.log('====================================');
    const columns = [
        {
            id : 1, 
            label: 'Prot.',
            nome: 'idProg',
            altLabel: 
                    <div className="d-flex justify-content-center align-items-center" style={{height: '2vh'}} >
                        <span>
                            NUM.
                        </span>
                    {
                        !FiltraBolla?.orderMoreRecente ? 
                        <button className="btn py-0" onClick={()=>onChangeFiltraBolla('orderMoreRecente', 'DESC')}>
                            <FontAwesomeIcon  icon={faSort} />
                        </button>
                        :(
                            FiltraBolla?.orderMoreRecente === 'DESC' ?
                            <button className="btn py-0" onClick={()=>onChangeFiltraBolla('orderMoreRecente', 'ASC')}>
                                <FontAwesomeIcon icon={faSortUp} />
                            </button>
                            :
                            <button className="btn py-0" onClick={()=>onChangeFiltraBolla('orderMoreRecente', null)}>
                                <FontAwesomeIcon icon={faSortDown} />
                            </button>
                        )
                    }
                </div>
               
        },
        {
            id : 2, 
            label: 'N.Cliente',
            nome: 'numero'
        },
        {
            id : 3, 
            label: 'descrizione',
            nome: 'descrizione',
        },
        {
            id : 4, 
            label: 'Data',
            nome: 'dataFattura',
            altCol: (original)=> {
                const arrayData = original?.dataFattura.split('-');
                return(
                    <>
                        {arrayData[2]+ '/'+arrayData[1]+'/'+arrayData[0]}
                        
                    </>
                )
            },
            altLabel:  <div className="d-flex justify-content-center align-items-center" style={{height: '2vh'}}>
                <span>Data</span>
            {
                !FiltraBolla?.dataOrder ? 
                <button className="btn py-0" onClick={()=>onChangeFiltraBolla('dataOrder', 'DESC')}>
                    <FontAwesomeIcon  icon={faSort} />
                </button>
                :(
                    FiltraBolla?.dataOrder === 'DESC' ?
                    <button className="btn py-0" onClick={()=>onChangeFiltraBolla('dataOrder', 'ASC')}>
                        <FontAwesomeIcon icon={faSortUp} />
                    </button>
                    :
                    <button className="btn py-0" onClick={()=>onChangeFiltraBolla('dataOrder', null)}>
                        <FontAwesomeIcon icon={faSortDown} />
                    </button>
                )
            }
        </div>
        },
        {
            id : 5, 
            label: 'Fornitore',
            nome: 'fornitore',
            altCol: (original)=> {
                return(
                    <>
                       {original?.mittente?.viewName}
                    </>
                )
            }
        },
        {
            id : 6, 
            label: 'Peso totale in kg',
            nome:'totPesoKg'
        },
        {
            id : 7, 
            label: 'Porto',
            nome:'porto'
        },
        {
            id : 8, 
            label: 'N. colli',
            nome:'nColli'
        },
        {
            id : 9, 
            label: 'Totale DDT',
            altCol: (original)=> {
                return(
                    <>
                        {daCentEu(original.totFatturaCent)}
                    </>
                )
            }
        },
    ]
    
    return(
        <div className="w-100" >
            {/* <Tabella
                data={array}
                columns={columns}
                Pulsanti={Pulsanti}
            /> */}
                <TabellaPaginazione
                    colonne={columns}
                    dati={array}
                    totalePag={pageTot}
                    azzera={azzeraVar}
                    impaginazione={impaginazione}
                    fu={fu}
                    Pulsanti={Pulsanti}
                />
        </div>
    )
}