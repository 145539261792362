import React, { useEffect, useState } from "react";
import SelezionaProdItem from "../../prodotti/SelezionaProdItem";
import InputForm from "../../riutilizabili/forms/InputForm";

import { daCentEu, daEuCent } from "../../riutilizabili/daEurCent";

import TableSemp from "../../riutilizabili/TableSemp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import QuestionModal from "../../riutilizabili/QuestionModal";
import CampiProdotti from "../../prodotti/CampiProdotti";
import { useNavigate } from "react-router-dom";
import prodotti_fetch from "../../fetch/prodotti_fetch";
import UploadFile from "../../riutilizabili/UploadFile";
import ViewImgPreUpload from "../../riutilizabili/ViewImgPreUpload";
import create_autocompleteWithService from "../../riutilizabili/create_autocompleteWithService";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Switch2 from "../../riutilizabili/Switch2";
import SelectForm from "../../riutilizabili/forms/SelectForm";
import classSezione from "../../fetch/classSezione";
import FormError from "../../riutilizabili/FormError";
import UploadDocProd from "../../prodotti/UploadDocProd";
import CodiciForms from "./CodiciForms";
 

export default function ProdottiBolla({
    onChange,
    inputs,
    errors
}) {
    const [arrayProds , setArrayProds] = useState([]);
    const [viewSelectProds , setViewSelectProds] = useState(false);
    const [selectProds , setSelectProds] = useState([]);
    const [selectProdsTemp , setSelectProdsTemp] = useState([]);
    const [viewCreaArt , setViewCreaArt] = useState(false);
    const [datiCreaArt , setDatiCreaArt] = useState({});
    const [errorCreaArt , setErrorCreaArt] = useState({});
    const [imgProd , setImgProd] = useState([]);
    const [arrayUnitaMisura , setArrayUnitaMisura] = useState([])
    const [docProd , setDocProd] = useState([])

    const [prodSele , setProdSele] = useState({});

    const navigate = useNavigate();

    useEffect(()=> {
        recuperaProds()
        getUnMisura()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inputs])

    const recuperaProds = ()=>{
        if (inputs?.prods) {
            //setArrayProds(inputs.prods)
            //console.log(inputs , ' inputs.prod');
            setSelectProds(inputs.prods)
        }
    }

    //console.log(inputs.prods , 'inputs?.prods ', arrayProds);
    const onCloseCreaArticolo = async (stato) => {
        if (!!stato) {
            prodotti_fetch.create({imgProd : imgProd , datiCrea : datiCreaArt ,docProd : docProd})
            .then(response => {
                //console.log(response , 'response.data');
                // onChange('prods', [...inputs.prods , response.data])
                let prod =response.data
                let tmpProd = {...prod ,prodottoId : prod.id , quantita : 1} 
                tmpProd.pId = tmpProd.id 
                delete tmpProd.id
                setSelectProds([...inputs.prods , tmpProd])
                onChange('prods', [...inputs.prods, tmpProd])
                setDatiCreaArt({})
                setErrorCreaArt({})
                setViewCreaArt(false)
            }).catch(error=> {
                //console.log(error.response ,'rror.response');
                if (!!error.response) {
                    if (error?.response?.status === 406) {
                        navigate("/login");;
                    }else if (error.response.status !== 200){
                        setErrorCreaArt(error?.response?.data)
                    }
                }else {
                    setDatiCreaArt({})
                    setErrorCreaArt({})
                    setViewCreaArt(false)
                }
                ////console.log(error.response.data , ' error');
            })
        } else {
            setDatiCreaArt({})
            setErrorCreaArt({})
            setViewCreaArt(false)
        }
    }
    
    const deleteProd = (prod) => {
        const  newProd = []
        arrayProds.forEach(element => {
          if (prod !== element) {
            newProd.push(element)
          }else {
          //  //console.log('trovato');
          }
        });
        onChange('prods', newProd)
        recuperaProds()
    }
    
    const selectProd = (prod , stato) => {
        //console.log(prod, 'ppp',inputs?.prods);
        let tmpProd = {...prod ,prodottoId : prod.id , quantita : 1} 
        tmpProd.pId = tmpProd.id 
        delete tmpProd.id 
        if (stato) {
            //setSelectProds(values => ([...values, prod]));
            if (!!inputs?.prods) {
                onChange('prods', [...inputs.prods, tmpProd])
                setSelectProds([...inputs.prods, tmpProd])
            }else {
                onChange('prods', [tmpProd])
                setSelectProds([tmpProd])
            }
        }else {
            let tpm
            // se è esistente filtra per id senno per pId
            if (prod.id) {
                tpm = selectProds.filter(item => item.id != prod.id);
            } else {
                tpm = selectProds.filter(item => item.pId != prod.pId);
            }
            //console.log(tpm , prod)
                // const index = tpm.indexOf(prod.pId)
                // tpm.splice(index,1)
            //console.log(tpm)
            //setSelectProds(tpm)
            onChange('prods', tpm)
        }

    }

    const onChangeValueProdUsato = (stato , prodId) => {
        const prods = [...selectProds];
        const prodsIds = prods.map(item=>  item.id)
        const position = prodsIds.indexOf(prodId)
        //console.log(prodsIds, position , prods[position]);
        prods[position].usato = !!stato
        if (!!stato) {
            prods[position].rifInterno = 'RF-'+prods[position].rifInterno
        }else {
            prods[position].rifInterno = prods[position].codProd
        }
        setSelectProds(prods)
    }

    const getUnMisura = async () => {
        const array = await classSezione.getUnMisura()
        //console.log(array.data , ' array.data');
        setArrayUnitaMisura(array.data)
    }

    const onChangeUmId = (prodotto , valore) => {
        let tpm = [...selectProds];
        const posizioneProd = tpm.indexOf(prodotto);
        tpm[posizioneProd].umId = parseInt(valore)
        onChange('prods', tpm)
    }

    const colonne = [
        {
            label: 'Codice interno',
            nome: 'rifInterno',
        },
        {
            label: 'Descrizione',
            nome: 'descrizione',
        },
        {
            label: 'EAN',
            nome: 'ean',
        },
        {
            label: 'Produttore',
            nome: 'produttore',
        },
        {
            label: 'quantita',
            nome: 'quantitaResidua',
            altCol : (original)=> {
                const position = selectProds.indexOf(original)
                // //console.log(original ,  position , selectProds[position] , ' original ,  position , selectProds[position]');
                let qt = 1;
                if (position !== -1 && !!selectProds[position]?.quantita) {
                    qt = selectProds[position]?.quantita
                }
     
                return(
                    <div style={{width: '5rem'}} className='m-auto' >
                        <InputForm
                            type={'number'}
                            label=' '
                            name={'quantita'}
                            value={qt}
                            onChange={(nome , valore)=>onChangeValueQt(original ,nome , valore)}
                            errors={errors}
                            classi={'w-full'}
                        />
                    </div>
                )
            }
        },
        {
            label : 'Unità di misura',
            nome: 'umId',
            altCol : (original)=> {
                //console.log(original.umId , ' original.umId');
                
                return <div className=' m-2'>
                {!!original.umId && <SelectForm
                    arrayElement={arrayUnitaMisura}
                    value={original.umId}
                    name='umId'
                    onChange={(nome , valore)=>onChangeUmId(original , valore)}
                    errors={[]}
                />}
            </div>
            }
        },
        {
            label: 'Prezzo di acquisto',
            nome: 'costoCent',
            altCol : (original)=> {
                const position = selectProds.indexOf(original)
                // //console.log(original ,  position , selectProds[position] , ' original ,  position , selectProds[position]');
                let qt = 0;

                //console.log(position , ' position' ,selectProds[position]?.costoCent);
                if (position !== -1 && !!selectProds[position]?.costoCent) {
                    qt = selectProds[position]?.costoCent
                }
        
                return(
                    <div style={{width: '6rem'}} className='m-auto' >
                        <InputForm
                            type={'number'}
                            label=' '
                            name={'costoCent'}
                            value={qt/100}
                            onChange={(nome , valore)=>onChangeValueQt(original ,nome , daEuCent(valore))}
                            errors={errors}
                            classi={'w-full'}
                        />
                    </div>
                )
            }
        },
        {
            label: 'Prodotto Usato',
            nome: 'usato',
            altCol : (original)=> {
    
                return(
                    <div className='m-auto' style={{width: '3rem'}}>
                        <Switch2
                            stato={!!original?.usato}
                            number={original.id}
                            onChangeValue={onChangeValueProdUsato}
                        />
                    </div>
                )
            }
        },
        {
            label: 'IVA %',
            nome: 'iva',
            altCol : (original)=> {
                //console.log(original , 'original');
            return(
                <div style={{width : '5rem'}} className="p-0 m-0">
                    <InputForm
                        label=' '
                        name={'iva'}
                        placeholder=''
                        type='text'
                        value={original.iva}
                        onChange={(nome , valore)=> onChangeValueQt(original ,nome,valore)}
                        className="rounded h-100 w-100 text-center"
                        errors={{}}
                    />
                </div>
            )
        }
        },
    ]

    const onChangeValueQt = (prodotto , nome , valore) => {
        let tpm = [...selectProds]
        const position = tpm.indexOf(prodotto);
        
        if (position !== -1) {
            tpm[position][nome] = valore
            //console.log(nome.length-4 , nome , nome.length ,nome.substring(nome.length-4 ,nome.length), ' nome.substring(nome.length-4 ,4)')
            if (nome.substring(nome.length-4 ,nome.length) === 'Cent') {
                const nomeNew = nome.substring(0 , nome.length-4);
                tpm[position][nomeNew] = daCentEu(valore)
            }
        }
        onChange('prods', tpm)
        setSelectProds(tpm)
    }

    const assMetPag = ({item , value})=> {
        if (!!item) {
            //console.log(item , ' item');
            //setProdSele(item)
            // let tmp = {...item ,prodottoId : item.id , quantita : 1} 
            // tmp.pId = tmp.id 
            // delete tmp.id 
            selectProd(item , true )
        }
    
    }

    const ProdottiAutocomplite = create_autocompleteWithService({
        freeinput: true,
        service: (myValue, serviceValues) => prodotti_fetch.get({
            nomeCodice: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => (item.descrizione+ ' '+item.rifInterno)?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.descrizione+' - '+ item.rifInterno+ ''+ (item.infoFornitore !== null ? ' - '+item.infoFornitore.nome : ' '),
        itemToString: item => item.descrizione+' - '+ item.rifInterno,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: '',
        placeholder: 'Selezione rapida : inserisci codice interno o descrizione articolo',
        value : !!prodSele?.descrizione ? prodSele?.descrizione  +' - '+ prodSele?.rifInterno : '',
        onChange : assMetPag ,
        className :'w-25' ,
        disabled :!inputs?.magazzinoId
    });

   //console.log(selectProdsTemp , ' selectProdsTemp');
   console.log(selectProds , ' selectProds');

   const copiaSeriali = (event , prodSezId) => {
        const prods = [...selectProds];
        const prodsIds = prods.map(item=>  item.id || item.pId)
        const position = prodsIds.indexOf(prodSezId)
        
        prods[position] ={...prods[position] , seriali : event.target.value}
        //console.log(prodsIds, prodSezId, position , prods[position] , ' prodsIds, position , prods[position]');
        setSelectProds(prods)
        onChange('prods', prods)
   }
    
    return(
        <div className="border border-primary border-3 p-3 rounded-4 m-3" >
            <div className="d-flex align-items-center">
                <h3 className="text-primary me-4" >Articoli nel DDT </h3>
                
            </div>
            <ProdottiAutocomplite />
            {/* <Tabella
                columns={columns}
                data={selectProds}
                //data={arrayProds}
                Pulsanti={Pulsanti}
            /> */}

            <TableSemp
                colonne={colonne}
                dati={selectProds}
                Pulsanti={(item)=> {
                    return(
                        <td>
                            <button className='btn button_fix' onClick={()=>selectProd(item.id , false)} >
                                <FontAwesomeIcon className='fs-4' icon={faTrash} />
                            </button>
                        </td>
                    )
                }}

                secondaRiga={ (item) => {
                    //console.log(item , 'item seriali');
                    
                    // return(
                    //     <td colSpan="10">
                    //         <div className="d-flex flex-wrap align-items-center">
                    //             <p className="p-0 m-0 col-2">
                    //                 Seriali :
                    //             </p>
                    //             <div className="col-8 py-3">
                    //                 <textarea className="form-control" name="" id="" onChange={(e)=>copiaSeriali(e ,item.id || item.pId)} >
                    //                     {item.seriali}
                    //                 </textarea>
                    //             </div>
                    //         </div>
                    //         <p>
                    //             (Inserire ";" alla fine di ogni seriale)
                    //         </p>
                    //         <FormError errors={errors} field='seriali' />
                    //     </td>
                    // )
                    
                    return (
                        <CodiciForms item={item} onChange={onChange} prods={inputs?.prods} errors={errors} />
                    )
                }}
            />


            <button className="btn btn-primary" disabled={!inputs?.magazzinoId} onClick={()=>setViewSelectProds(true)} >Seleziona articolo generico</button>
            <button onClick={()=>setViewCreaArt(true)} disabled={!inputs?.magazzinoId}  className="btn rounded-5 bg-primary ms-4 text-white">
                <FontAwesomeIcon icon={faPlus} />
            </button>

            {viewSelectProds &&<SelezionaProdItem
               onCancel ={()=>{ setViewSelectProds(false);onChange('prods', selectProds)}} 
               data={inputs} 
               prodSeleCtId={[...selectProds.map(item => item.pId) , ...selectProdsTemp.map(item => item.id)]}
               onSucces={selectProd}
            />}

            {!!viewCreaArt &&<QuestionModal
                title='Crea articolo'
                text={
                    <div>
                        <CampiProdotti 
                            classiDiv='d-flex flex-wrap align-items-center'
                            value={datiCreaArt}
                            errors={errorCreaArt}
                            onChange={(name ,value)=> setDatiCreaArt(values => ({ ...values, [name]: value }))}
                        />
                        <UploadFile
                            txtInfo='Immagine articolo'
                            onSuccess={(file)=>setImgProd(values => ( [...values,  file ]))}
                            imgs={imgProd}
                        />

                        <ViewImgPreUpload
                            imgs={imgProd}
                            onChangeImg={(imgs)=> setImgProd(imgs)}
                            prodId={viewCreaArt.id}
                        />
                        <div style={{width : '69rem'}} className='ps-4'>
                            <UploadDocProd
                                onSuccess={(file)=>setDocProd(values => ( [...values,  file ]))}
                            />
                        </div>
                    </div>
                }
                onClose={onCloseCreaArticolo}
            />}

        </div>
    )
}