import http from "./controllerFetch";

class bollaService { 
    getAll() {
        return http.get('bolla/read');
    }

    get( params = {}) {
        return http.get('bolla/read', { params });
    }
    getTabella( params = {}) {
        return http.get('bolla/readTabella', { params });
    }

    getMetodiPag( params = {}) {
        return http.get('bolla/readMetodiPag', { params });
    }

    getUnitaMisura( params = {}) {
        return http.get('bolla/readUnitaMisura', { params });
    }

    modBollaEntrata(data) {
        return http.post('bolla/modBollaEntrata', data);
    }

    creaBollaEntrata(data) {
        return http.post('bolla/creaBollaEntrata', data);
    }

    getBollaUscita( params = {}) {
        return http.get('bollaUscita/read', { params });
    }
    readTabella( params = {}) {
        return http.get('bollaUscita/readTabella', { params });
    }
    creaBollaUscitaDaEntrata( params = {}) {
        return http.get('bollaUscita/creaBollaUscitaDaEntrata', { params });
    }

    creaBollaUscita(data) {
        return http.post('bollaUscita/crea', data);
    }
    modBollaUscita(data) {
        return http.post('bollaUscita/modifica', data);
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new bollaService();