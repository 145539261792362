import React, { useEffect, useState } from "react";
import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CampiBolla from "./CampiBolla";
import QuestionModal from "../riutilizabili/QuestionModal";
import { daCentEu, daEuCent } from "../riutilizabili/daEurCent";
import bolla_fetch from "../fetch/bolla_fetch";
import TabellaBolle from "./sezioni/TabellaBolle";
import FiltraBolla from "./FiltraBolla";
import InfoModal from "../riutilizabili/InfoModal";
import ReadCampiBolla from "./sezioni/ReadCampiBolla";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti";
import { format } from "date-fns";
import CreaBollaUscitaDaEntrata from "./CreaBollaUscitaDaEntrata";
import { Button } from "react-bootstrap";
import ConfermaCreazione from "../riutilizabili/ConfermaCreazione";
import classSezione from "../fetch/classSezione";
import Attendere from "../riutilizabili/Attendere";


export default function ViewBolla(props) {
    const [magazzino, setMagazzino] = useState(null);
    useEffect(() => {
        classSezione.get({ magazzinoPrincipale: 1 }).then(response => {
            if (response.data.length !== 0) {
                setMagazzino(response.data[0])
            }
        })
    }, [])

    if (!magazzino) {
        return null;
    }

    return <Viewbolla0 {...props} magazzino={magazzino} />
}

function Viewbolla0({ magazzino, ...onChangeCreaV }) {
    const [inputs, setInputs] = useState({
        dataFattura: format(new Date(), 'yyyy-MM-dd'),
        magazzinoId: magazzino.id,
        magazzinoNome: magazzino.nome,
    });
    const [inputsModifica, setInputsModifica] = useState({});
    const [inputsFiltra, setInputsFiltra] = useState({ orderMoreRecente: 'DESC' });
    const [viewBolla, setViewBolla] = useState(false);
    const [bolla, setBolla] = useState({});
    const [errors, setErrors] = useState({});
    const [viewCrea, setViewCrea] = useState(false);
    const [viewCreaAllert, setViewCreaAllert] = useState(false);
    const [viewModifica, setViewModifica] = useState(false);
    const [arrayBolla, setArrayBolla] = useState([]);
    const [statoAvanti, setStatoAvanti] = useState(false);
    const [campiObSegnale, setCampiObSegnale] = useState({
        'indirizzo': 'campo obbligatorio',
        'provincia': 'campo obbligatorio',
        'codiceFiscale': 'campo obbligatorio',
        'numeroCivico': 'campo obbligatorio',
        'numero': 'campo obbligatorio',
        'dataFattura': 'campo obbligatorio',
        'magazzinoId': 'campo obbligatorio',
        'fornitoreId': 'campo obbligatorio'
    });
    const [viewAttendere, setViewAttendere] = useState(false); 

    const [pageTot, setPageTot] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [azzeraVar, setAzzeraVar] = useState(0)
    const [fu, setFu] = useState(0)

    useEffect(() => {
        readBolle()
    }, [])

    useEffect(() => {
        if (!!onChangeCreaV.onChangeCrea) {
            setViewCrea(true)
        }
    }, [!!onChangeCreaV])

    useEffect(() => {
        abilitaAvanti()
    }, [inputs])

    const campiOb = [
        'numero',
        'dataFattura',
        'fornitoreId',
        'magazzinoId'
    ]

    const abilitaAvanti = () => {
        //console.log(campiOb , inputs);
        let error = funzioniRiccorrenti.validazioneCampi(campiOb, inputs);
        //console.log(error);
        if (Object.keys(error).length > 0) {
            setCampiObSegnale(error)
            setStatoAvanti(false)
        } else {
            setCampiObSegnale({})
            setStatoAvanti(true)
        }
    }

    const readBolle = async () => {
        const bolle = await bolla_fetch.getTabella({ orderMoreRecente: 'DESC', pageSize, page, });
        ////console.log(bolle , ' bolle');
        setInputsFiltra({})
        setArrayBolla(bolle.data.data);
        setPageTot(bolle.data.pageTot)
        setFu(fu + 1)
    }

    const readFiltraBolle = async (param) => {
        const bolle = await bolla_fetch.getTabella({ ...inputsFiltra, pageSize, page, ...param });
        //console.log(bolle , ' bolle');
        setArrayBolla(bolle.data.data);
        setPageTot(bolle.data.pageTot)
        setFu(fu + 1)
    }

    const onChangeCreaBolla = async (stato) => {
        if (!!stato) {
            const bolla = await bolla_fetch.creaBollaEntrata(inputs);
            if (bolla.status === 200) {
                setInputs({
                    dataFattura: format(new Date(), 'yyyy-MM-dd'),
                    magazzinoId: magazzino.id, 
                    magazzinoNome: magazzino.nome
                })
                setViewCrea(false)
                setErrors({})
            }
            // //console.log(bolla , ' bolla creata');
        } else {
            // setViewCrea(false)
            // setErrors({})  
            // setInputs({
            //     dataFattura : format(new Date(), 'yyyy-MM-dd'),
            //     magazzinoId : 1,
            //     magazzinoNome : "Magazzino Ancona"
            // })  
        }
        setViewCreaAllert(false)
        readBolle()
    }

    const onChangeModificaBolla = async (stato) => {
        if (!!stato) {
            try {
                await bolla_fetch.modBollaEntrata(inputsModifica);
                setViewModifica(false)
                setErrors({})
                // //console.log(bolla , ' bolla creata');
            } catch (error) {
                //console.log(error.response.data, ' error.response.data.errors');
                setErrors(error.response.data)
            }

            readFiltraBolle({ orderMoreRecente: 'DESC', pageSize, page, ...inputsFiltra})
        } else {
            setViewModifica(false)
            setErrors({})
        }
    }

    const onChangeValue = (nome, valore) => {
        //console.log(nome);
        if (nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputs(values => ({ ...values, [nome]: cent }))
            setInputs(values => ({ ...values, [nome.slice(0, nome.length - 4) + 'Eur']: valore }))
        } else {

            setInputs(values => ({ ...values, [nome]: valore }))
        }
    }

    const onChangeValueMod = (nome, valore) => {
        //  //console.log(nome , valore , ' onChangeValueMod', inputsModifica);
        if (nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsModifica(values => ({ ...values, [nome]: cent }))
            setInputsModifica(values => ({ ...values, [nome.slice(0, nome.length - 4) + 'Eur']: valore }))
        } else {

            setInputsModifica(values => ({ ...values, [nome]: valore }))
        }
    }

    const onChangeValueFiltra = (nome, valore) => {
        //console.log(nome , valore , ' nome , valore');
        if (nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsFiltra(values => ({ ...values, [nome]: cent }))
            setInputsFiltra(values => ({ ...values, [nome.slice(0, nome.length - 4) + 'Eur']: valore }))
        } else {

            setInputsFiltra(values => ({ ...values, [nome]: valore }))
        }
        readFiltraBolle({ [nome]: valore })
    }

    const recuperaDatiModBolla = async (bollaId) => {
        setViewAttendere(true)
        const bolla = await bolla_fetch.get({id : bollaId})
        let dati = bolla.data[0]
        let oggetto = {}
        //console.log(dati , 'dati',oggetto);
        Object.keys(dati).forEach(element => {
            switch (element) {
                case 'mittente':
                    //console.log(dati.mittente , ' dati.mittente');

                    Object.keys(dati.mittente).forEach(element2 => {
                        oggetto['an' + element2.charAt(0).toUpperCase() + element2.slice(1)] = dati.mittente[element2]
                        //console.log(element2 , ' element2');

                        if (element2 === 'viewName') {
                            oggetto.anNominazioneFornitore = dati.mittente[element2]
                        }
                    });
                    break;

                case 'dataOraPartenza':
                    if (!!dati[element]) {
                        const formattedDate = format(dati[element], "yyyy-MM-dd'T'HH:mm")
                        oggetto[element] = formattedDate
                    }
                    break;

                case 'prods':
                    if (dati.prods.length) {
                        oggetto.prods = []
                        dati.prods.forEach(element2 => {
                            if (element2.stato === 1) {
                                Object.keys(element2.infoProd).forEach(element3 => {
                                    if (element3 !== 'id' && element3 !== 'costo' && element3 !== 'iva' && element3 !== 'costoCent') {
                                        element2[element3] = element2.infoProd[element3]
                                    }
                                })
                                element2.seriali = element2.ProdSerialis.map((item) => { return item.seriale }).join(';')
                                oggetto.prods.push(element2)
                            }
                        })

                    }
                    break;

                default:
                    if (element.slice(-4) === 'Cent') {
                        const cent = daCentEu(dati[element])
                        oggetto[element] = dati[element]
                        oggetto[element.slice(0, element.length - 4) + 'Eur'] = cent
                    } else {
                        oggetto[element] = dati[element]
                    }
                    break;
            }
        });
        //console.log(oggetto , 'oggetto');
        const sezione = await classSezione.get({ id: dati.magazzinoId })
        //console.log(sezione.data[0].nome , 'sezione');
        oggetto.magazzinoId = sezione.data[0].id
        oggetto.magazzinoNome = sezione.data[0].nome
        //console.log({ oggetto })
        setInputsModifica(oggetto)
        setViewModifica(true)
        setViewAttendere(false)
    }

    const readViewBolla = async (bollaId) => {
        const bolla = await bolla_fetch.get({id : bollaId})
        console.log(bolla.data[0] ,' bolla');
        
        setViewBolla(bolla.data[0])
    }

    const Pulsanti = ({ element }) => {

        return (
            <td className=" py-0 my-0">
                <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0' onClick={() => readViewBolla(element.id)} icon={faEye} />
                </button>

                <button className='btn py-0 my-0 button_fix' >
                    <FontAwesomeIcon className=' py-0 my-0' icon={faEdit} onClick={() => recuperaDatiModBolla(element.id)} />
                </button>
            </td>
        )
    }

    const impaginazione = async (impaginazione) => {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if (page !== impaginazione.numeroPag || pageSize !== impaginazione.pageSize) {
            await readFiltraBolle({ pageSize: impaginazione.pageSize, page: impaginazione.numeroPag, ...inputsFiltra })
            setAzzeraVar(0)
        }
    }

    const onChangeViewCreaBolla = (stato) => {
        if (!!stato) {
            setViewCreaAllert(true)
        } else {
            setViewCreaAllert(false)
            setViewCrea(false)
        }
    }

    return (
        <div className="pt-2">
            <PaginazioneTitoloPulsanteCrea titolo={'Ddt in entrata'} funCrea={() => setViewCrea(true)} />

            <div className="p-4 ps-5">
                <div className='border_filtra p-3 mx-4 px-4' >
                    <FiltraBolla inputs={inputsFiltra} onChangeValue={onChangeValueFiltra} />
                    <div className="ps-2">
                        <PulsantiFiltra azzera={readBolle} filtra={readFiltraBolle} />
                    </div>
                </div>
 
                <div className=' w-100 m-5 mx-0 px-3 pe-4 pt-2'>
                    <TabellaBolle
                        array={arrayBolla}
                        azzeraVar={azzeraVar}
                        pageTot={pageTot}
                        Pulsanti={Pulsanti}
                        impaginazione={impaginazione}
                        FiltraBolla={inputsFiltra}
                        fu={fu}
                        onChangeFiltraBolla={(nome, valore) => { setInputsFiltra(values => ({ ...values, [nome]: valore })); readFiltraBolle({ [nome]: valore }) }}
                    />
                </div>
            </div>

            {viewCrea && <QuestionModal
                title={<div className="ms-4" >Crea ddt in entrata </div>}
                text={
                    <CampiBolla
                        inputs={inputs}
                        errors={{ ...errors, ...campiObSegnale }}
                        onChange={onChangeValue}
                        entrata
                    />
                }
                YesLabel='Salva'
                yesDisabled={!statoAvanti}
                onClose={onChangeViewCreaBolla}
                classi=' modal-xxl '
            />}

            {viewModifica && <QuestionModal
                title={<div className="ms-3" >Modifica ddt in entrata{' N.' + inputsModifica.id + ' del ' + format(inputsModifica.dataFattura, 'dd/MM/yyyy')}</div>}
                text={
                    <CampiBolla
                        inputs={inputsModifica}
                        errors={{ ...errors }}
                        onChange={onChangeValueMod}
                        entrata
                        modifica
                    />
                }
                YesLabel='Salva'
                onClose={onChangeModificaBolla}
                classi=' modal-xxl '
            />}

            {!!viewBolla &&
                <InfoModal
                    size='xl'
                    title={'Ddt n° ' + viewBolla.numero}
                    text={
                        <ReadCampiBolla inputs={viewBolla} infoAddView={false} />
                    }
                    canClose
                    handleClose={() => setViewBolla(false)}
                    className='modal-lg'
                    alternativeFooter={
                        <div className="w-100 " style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <CreaBollaUscitaDaEntrata
                                bollaEntrataId={viewBolla.id}
                                iconaButtom='Crea DDT uscita'
                                classButtom='me-4 text-primary btn bg-white fw-bold ms-3'
                            />
                            <Button className="me-3 text-primary bg-white fw-bold" onClick={() => setViewBolla(false)}>
                                CHIUDI
                            </Button>
                        </div>}
                />}

            <ConfermaCreazione
                label={'ATTENZIONE!!! OPERAZIONE IRREVERSIBILE'}
                testo={'stai inserendo i seguenti prodotti dal magazzino ' + inputs?.magazzinoNome + ' ?'}
                conferma={onChangeCreaBolla}
                stato={viewCreaAllert}
            />
           {!!viewAttendere && <Attendere />}
        </div>
    )
}