import React from "react"
import AnagraficaFornitore from "./sezioni/AnagraficaFornitore";
import DettagliBolla from "./sezioni/DettagliBolla";
import DettagliPagamento from "./sezioni/DettagliPagamento";
import ProdottiBolla from "./sezioni/ProdottiBolla";


export default function CampiBolla({
    inputs ,
    onChange ,
    errors,
    entrata = false,
    modifica = false
}) {

    //console.log(inputs , ' inputs CampiBolla');
   // console.log(errors , ' errors CampiBolla');

    return(
        <div className="w-100">
            <AnagraficaFornitore  onChange={onChange} value={inputs} errors={errors} modifica={modifica} />
            <DettagliBolla  onChange={onChange} inputs={inputs} errors={errors} entrata={entrata} bollaUscita={false} />
            {/* <DettagliPagamento  onChange={onChange} inputs={inputs} errors={errors} /> */}
            <ProdottiBolla  onChange={onChange} inputs={inputs} errors={errors} />
        </div>
    )
}