import React, { useEffect, useState } from "react";
import prodotti_fetch from '../fetch/prodotti_fetch';
import { useNavigate } from "react-router-dom";
import InfoModal from "../riutilizabili/InfoModal";
import Tabella from "../riutilizabili/Tabella";
import Switch2 from "../riutilizabili/Switch2";
import ImgTabella from "./ImgTabella";
import InputForm from "../riutilizabili/forms/InputForm";
import create_autocompleteWithService from "../riutilizabili/create_autocompleteWithService";
import anFornitore_fetch from "../fetch/anFornitore_fetch";
import ViewProdPopUp from "./ViewProdPopUp";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";

export default function SelezionaProdItem({ 
    onCancel ,
    onSucces ,
    prodSeleCtId=[] ,
    data ,
    prodIdEsc =[]
}) {
    const [dati, setDati] = useState([]);
    const [listReady, setListReady] = useState(false);

    const navigate = useNavigate();

    useEffect( ()=>{
        filtraProds({})
    },[])

    const filtraProds =  (param)=> {
        console.log(param ,' param filtraProds');
        
         prodotti_fetch.get(param)
        .then(prods=> {
            console.log('qui si ', prods)
            if (prods.data.length === 0) {
                setDati([])
                setListReady(true)
            }else {
                if (prodIdEsc.length > 0) {
                    let prodsOut = []
                    if (prods.data.length > 0) {
                        for (let key = 0; key < prods.data.length; key++) {
                            const element = prods.data[key];
                            if (prodIdEsc.some(code => code === element.id) === false) {
                                prodsOut.push(element)
                            }
                            if (prods.data.length === key+1) {
                                setDati(prodsOut)
                                setListReady(true)
                            }
                            
                        }
                    }else {
                        setDati(prodsOut)
                        setListReady(true)
                    }
                } else {
                    //console.log(prods.data , ' prods');
                    setDati(prods.data);
                    setListReady(true)
                    
                }
            }
        })
        .catch(error => {
            console.log('qui no ')
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    const handleClose = () => {
        onCancel();
    };

    const [inputs, setInputs] = useState({});
    
    const cerca = (event) => {
        event.preventDefault();
        //console.log(inputs);
        filtraProds(inputs)
        
    }
    const azzera = (event) => {
        event.preventDefault();
        setInputs({})
        filtraProds({})
    
    }
    const handleChange = (event ,name , value) => {
        event.preventDefault();
        setInputs(values => ({...values, [name]:  value}))
        cerca(event)

    }
    
    const columns = React.useMemo(
    () => [
            {
                Header: 'Codice Netoip',
                accessor: 'rifInterno',
            },
            {
                Header: 'Codice produttore',
                accessor: 'codProd',
            },
            {
                Header: 'Descrizione',
                accessor: 'descrizione',
                Cell : ({row : { original}}) => {
                    return(
                        <>
                            <ViewProdPopUp
                                icona={original?.descrizione}
                                prodAnId={original?.id}
                            />
                        </>
                    )
                }
            },
            {
                Header: 'Fornitore',
                accessor: 'Fornitore',
                Cell : ({row : { original}}) => {
                    return original?.infoFornitore?.viewName
                }
            },
            {
                Header: 'EAN',
                accessor: 'ean',
            },
            {
                Header: 'IVA',
                accessor: 'iva',
            },
            {
                Header: 'Immagine',
                accessor: 'img',
                Cell : ({row : { original}}) => {
                    return(
                        <div style={{width: '10rem'}} className='m-auto' >
                        <ImgTabella prodId={original.id} />
                        </div>
                    )
                }
            },
    ],
    []
    )

    const selezionaProd = (stato , prodId)=> {
        console.log(stato , prodId , ' stato , prodId');
        onSucces(prodId , stato )

    }

    const Pulsanti = (id) => {
        const element = id.id.original;
        console.log(element.id , ' s ', prodSeleCtId);
        const stato = prodSeleCtId.includes(element.id)
        return(
            <td>
                <Switch2
                    stato={stato}
                    number={element}
                    onChangeValue={selezionaProd}
                />
            </td>
        )
    }
    
   const onChangeFornitore = ({item , value}) => {
    if (item) {
        console.log(item , value);
        setInputs(values => ({...values, ['anNominazioneFornitore']:  value}))
        setInputs(values => ({...values, ['fornitoreId']:  item.id}))
        filtraProds({...inputs,['fornitoreId']:  item.id})
    }
   }

   const AutocompleteFornitore = create_autocompleteWithService({
    freeinput: false,
    service: (myValue, serviceValues) => anFornitore_fetch.autocompliteAn({
        nome: myValue,
        ...serviceValues,
    }),
    itemCompare: (item, inputValue) => item.viewName?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
    itemToLi: item => item.viewName,
    itemToString: item => item.viewName,
    itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.viewName.toLowerCase() === value?.toLowerCase() ? curr : null), null),
    onChange: onChangeFornitore ,
    //redflag : redflagFonia,
    label: 'Seleziona Fornitore :*',
    placeholder: 'inserisci Fornitore',
    value: inputs?.anNominazioneFornitore,
});
  


    return(
        <InfoModal
            title='Seleziona articoli'
            text={
                <div>
                    <div className="m-4 border_filtra p-3 mb-5" >      
                        <div className="d-flex flex-wrap">
                            <div className={"ms-0 m-3 col-2"}>
                                <label htmlFor='nome' className="form-label fw-bold">Descrizione </label>
                                <input 
                                    value={inputs?.descrizione || ''} 
                                    type='text'
                                    className={"form-control "} 
                                    name='descrizione' 
                                    id='descrizione' 
                                    placeholder=''
                                    onChange={(event) => {handleChange(event ,'descrizione', event.target.value) }}
                                    aria-describedby="validationServer03Feedback"
                                    onChangeValue={cerca}
                                />
                            </div>
                    
                            <div className={"m-3 col-2"}>
                                <label htmlFor='codBarre' className="form-label fw-bold">Codice a barre </label>
                                <input 
                                    value={inputs?.codBarre} 
                                    type='text'
                                    className={"form-control "} 
                                    name='codBarre' 
                                    id='codBarre' 
                                    placeholder=''
                                    onChange={(event) => handleChange(event ,'nome', event.target.value)}
                                    aria-describedby="validationServer03Feedback"
                                />
                            </div>
                            <InputForm
                                label='Codice interno'
                                type='text'
                                placeholder=''
                                name='rifInterno'
                                value={inputs?.rifInterno}
                                onChange={ (name , value) =>{handleChange(name , value); cerca() }}
                                errors={{}}
                                classi='col-2'
                            />

                            <InputForm
                                label='Codice produttore'
                                type='text'
                                placeholder=''
                                name='codProd'
                                value={inputs?.codProd}
                                onChange={ (name , value) =>{handleChange(name , value); cerca() }}
                                errors={{}}
                                classi='col-2'
                            />

                            <InputForm
                                label='Codice foritore'
                                type='text'
                                placeholder=''
                                name='codForitore'
                                value={inputs?.codForitore}
                                onChange={ (name , value) =>{handleChange(name , value); cerca() }}
                                errors={{}}
                                classi='col-2'
                            />

                            <div className="col-2 ">
                                <AutocompleteFornitore />
                            </div>
                            </div>
                        <PulsantiFiltra
                            azzera={azzera}
                        />
                    </div>
                    <Tabella
                        columns={columns}
                        data={dati}
                        Pulsanti={Pulsanti}
                    />
                </div>
            }
            classi={' modal-xxl '}
            canClose
            handleClose={handleClose}
            closeLabel='Inserisci selezionati'
        />
    )

}