import React from 'react';
import FormError from '../FormError';

export default function TextAreaForm({
    label , 
    name , 
    value , 
    onChange , 
    classi ,  
    errors
}) {
    //console.log(errors, ' errors');
    const segnalaErrore = errors[name] ? 'is-invalid' : ''
    return(
        <div className={"m-3 " +classi}>
            <label className='form-label fw-bold  ps-4 ms-1 ' htmlFor="floatingTextarea"> { label[0].toUpperCase() + label.substr(1) } </label>
            <textarea 
                onChange={(event) => onChange(name, event.target.value)} 
                className={"form-control w-100  m-0 " + segnalaErrore} 
                id="floatingTextarea"
                defaultValue={value} 
            > 
                
            </textarea>
            <FormError errors={errors} field={name} />
        </div>
    )
}