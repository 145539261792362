import { useEffect, useState } from "react";
import bolla_fetch from "../fetch/bolla_fetch";
import CampiBolla from "./CampiBolla";
import QuestionModal from "../riutilizabili/QuestionModal";
import { daEuCent } from "../riutilizabili/daEurCent";
import ConfermaCreazione from "../riutilizabili/ConfermaCreazione";
import { format } from "date-fns";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti";


export default function CreaBollaEntrataDaUscita({
    bollaUscitaId ,
    iconaButtom ,
    classButtom
}) {
    const [ inputs , setInputs ] = useState({});
    const [viewCrea , setViewCrea] = useState(false);
    const [errors , setErrors] = useState({});
    const [campiObSegnale , setCampiObSegnale] = useState({
            'dataFattura': 'campo obbligatorio',
            'magazzinoId': 'campo obbligatorio',
            'fornitoreId': 'campo obbligatorio'
    });
    const [statoAvanti , setStatoAvanti] = useState(false);
    const [viewCreaAllert , setViewCreaAllert] = useState(false);

    const campiOb = [
        'dataFattura',
        'fornitoreId',
        'magazzinoId' 
    ]

    useEffect(()=> {
        abilitaAvanti()
    },[inputs])

    const abilitaAvanti = () => {
        console.log(campiOb , inputs);
        let error = funzioniRiccorrenti.validazioneCampi(campiOb , inputs);
        console.log(error);
        if (Object.keys(error).length > 0) {
            setCampiObSegnale(error)
            setStatoAvanti(false)
        }else {
            setCampiObSegnale({})
            setStatoAvanti(true)
        }
    }

    const readBollaUscita = async ()=> {
        const bollaUscita = await bolla_fetch.getBollaUscita({id : bollaUscitaId})
        console.log(bollaUscita.data[0] , ' bollaUscita');
        let prods = bollaUscita.data[0].prods.map(prod => ({ ...prod, id: null ,bollaUscitaId: null , bollaUscitaAnnoId : null }));
        setInputs({magazzinoId : 1,magazzinoNome : "Magazzino Ancona",...bollaUscita.data[0] , numero :bollaUscita.data[0].id+'/'+bollaUscita.data[0].annoId+(bollaUscita.data[0].codiceMag ? ' - '+ bollaUscita.data[0].codiceMag : '') , prods , id : null})
        setViewCrea(true)
    }

    const onChangeValue= (nome , valore) => {
        console.log(nome);
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputs(values => ({ ...values, [nome]: cent }))
            setInputs(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputs(values => ({ ...values, [nome]: valore }))
        }
    }  

    const onChangeViewCreaBolla = (stato) => {
        if (!!stato) {
            setViewCreaAllert(true)
        } else {
            setViewCreaAllert(false)
            setViewCrea(false)
        }
    }

    const onChangeCreaBolla = async(stato) => {
        if (!!stato) {
            const bolla = await bolla_fetch.creaBollaEntrata(inputs);
            if (bolla.status === 200) {
                setInputs({
                    dataFattura : format(new Date(), 'yyyy-MM-dd'),
                    magazzinoId : 1,
                    magazzinoNome : "Magazzino Ancona"
                })
                setViewCrea(false)
                setErrors({})                
            }
           // console.log(bolla , ' bolla creata');
        } else {
            setViewCrea(false)
            setErrors({})  
            setInputs({
                dataFattura : format(new Date(), 'yyyy-MM-dd'),
                magazzinoId : 1,
                magazzinoNome : "Magazzino Ancona"
            })  
        }
        setViewCreaAllert(false)
    }

    return(
        <>
            <button className={classButtom} onClick={readBollaUscita} >
                {iconaButtom}
            </button>

            {viewCrea &&<QuestionModal
                title={<div className="ms-4" >Crea ddt in entrata </div>}
                text={
                    <CampiBolla
                        entrata={true}
                        inputs={inputs} 
                        errors={{...errors , ...campiObSegnale}}
                        onChange={onChangeValue}
                        />
                   }
                YesLabel='Salva'
                yesDisabled={!statoAvanti}
                onClose={onChangeViewCreaBolla}
                classi=' modal-xxl '
            />}

            <ConfermaCreazione
                label={'ATTENZIONE!!! OPERAZIONE IRREVERSIBILE'}
                testo={'stai prelevando i seguenti prodotti dal magazzino '+ inputs?.magazzinoNome + ' ?'}
                conferma={onChangeCreaBolla}
                stato={viewCreaAllert}
            />
        </>
    )
}