import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';

/** 
 * messaggio mediante un modal
 * props: 
 *   se presente canClose posso chiudere? (TODO : cliccando fuori si chiude anche se canClose non e' stato passato)
 *   string title
 *   string text
 *   function  onClose gestisci chiusura
 */

function InfoModal({ 
    title, 
    text, 
    children, 
    onClose, 
    canClose, 
    noHeader, 
    noFooter, 
    alternativeFooter, 
    closeLabel = "Chiudi",
    handleClose,
    classi ,
     ...ps 
    }) {
    //console.log(ps ,' ');
    const [show, setShow] = useState(true);

    // const handleClose = () => {
    //     setShow(false);
    //     onClose && onClose();

    // }
    return (
        <Modal className={classi} show={show} onHide={handleClose} animation={false} {...ps} >
            {!noHeader && <Modal.Header className="bg-primary text-white" closeButton={canClose}>
                <Modal.Title className="text-uppercase ms-3 ps-1" >{title}</Modal.Title>
            </Modal.Header>}
            <Modal.Body>{text}{children}</Modal.Body>
            {!noFooter && (
                <Modal.Footer className="bg-primary">
                    {!!alternativeFooter
                        ? <>{alternativeFooter}</>
                        : <>
                            {canClose ? (<Button className="me-4 text-primary bg-white fw-bold" onClick={handleClose}>
                                {closeLabel}
                            </Button>) : ('')}
                        </>}
                </Modal.Footer>
            )}
        </Modal>
    );
}

export default InfoModal;