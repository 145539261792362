import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

 


export default function TabellaPaginazione({
    colonne = [] ,//[{id, label , nome}]
    dati = [] ,
    impaginazione ,
    totalePag ,
    Pulsanti ,
    fu ,
    nomePulsanti ,
    filtra ,
    valueFiltra ,
    onChangeFiltra ,
    escludiFiltra=[] ,
    filtroTopTable = false,
    classi = false,
    azzera = 0
}) {
    // redirect a pagina
    const [datiTab , setDatiTab]= useState([])
    const [numeroPag , setNumeroPag]= useState(1)
    const [pageSize , setPageSize]= useState(10)
    const [canPreviousPage , setCanPreviousPage]= useState(false)
    const [canNextPage , setCanNextPage]= useState(true)
    const [myFu , setMyFu]= useState(1)
    
    
    useEffect(()=>{
        if (impaginazione!==null) {
            gotoPage(numeroPag);
        }else {
            setDatiTab(dati)
        }
    },[pageSize , numeroPag ,dati , myFu ])

    //console.log(azzera , ' azzera');
    

    useEffect(()=>{
        if (impaginazione!==null) {
            gotoPage(1);
            impaginazione({pageSize , numeroPag: 1})
            //console.log('campia pagina');
        }
        
    },[azzera === 1])


    const gotoPage = (paginaDest) => {
        //console.log( fu , myFu , paginaDest,
          //  paginaDest !==numeroPag , fu !== myFu ,
            //' paginaDest !==numeroPag || fu !== myFu',Math.ceil(totalePag)
        //);
        if (
            paginaDest !==numeroPag 
            || fu !== myFu
        ) {
            setMyFu(fu)
            
            if (Math.ceil(totalePag) < paginaDest) {
                let paginaDest = 1;
                impaginazione({pageSize , numeroPag: paginaDest})
                setNumeroPag(paginaDest)
                setDatiTab(dati)
            }else {
                impaginazione({pageSize , numeroPag: paginaDest})
                setNumeroPag(paginaDest)
                setDatiTab(dati)
                if (Math.ceil(totalePag) === 0) {
                    setCanPreviousPage(false)
                    setCanNextPage(false)
                }else {
                    if (Math.ceil(totalePag) === 1) {
                        setCanPreviousPage(false)
                        setCanNextPage(false)
                    }else if (paginaDest <= 1) {
                        setCanPreviousPage(false)
                        setCanNextPage(true)
                    }else if (paginaDest === Math.ceil(totalePag)) {
                        setCanPreviousPage(true)
                        setCanNextPage(false)
                    }else if (1 < paginaDest < Math.ceil(totalePag)) {
                        setCanPreviousPage(true)
                        setCanNextPage(true)
                    }
    
                }
            }
                
            
        }
    }

    const nextPage =() => {
        gotoPage(numeroPag+1)
    }
    const previousPage =() => {
        gotoPage(numeroPag-1)
    }
    
    return(
        <div className={!!classi ? classi :(filtroTopTable ? ' ps-2 mt-0 pt-0 ' : " ps-2 mt-2 ")} >
            <table className={"border overflow-hidden border-1 border-primary rounded table  table-bordered w-100 table-striped table-hover "} >
                <thead>
                    <tr className={"border bg_secondary text-white text-uppercase "+(filtroTopTable && ' border-top-0')} >
                        {colonne.map((item,index) => {
                            
                            return(
                                <th key={index} className={(item.altLabel ?" ps-1 border fw-bold " : " py-2 ps-1 border fw-bold ")+' '+(filtroTopTable && ' border-top-0')} id={item.id}>
                                    {item.altLabel ? item.altLabel : item.label}
                                </th>
                            )
                        })}
                         {Pulsanti && <th className="  py-2 ps-1 border fw-bold " >{!!nomePulsanti ? nomePulsanti : 'Azioni'}</th> }
                    </tr>
                    {!!filtra &&<tr>
                        {colonne.map((item,index) => {
                            return(
                                <th className=" ps-1 border table-sm" id={item.id} key={index}>

                               {!escludiFiltra.includes(item.nome) && <input 
                                    value={valueFiltra[item.nome] || ''}
                                    type={'text'}
                                    className={"p-0 form-control w-75 my-1 ps-2"}
                                    style={{fontSize:'0.8rem'}}
                                    name={item.nome}
                                    id={item.nome} 
                                    onChange={(event)=>onChangeFiltra(item.nome, event.target.value)}
                                    aria-describedby="validationServer03Feedback"              
                                />}
                      
                                </th>
                            )
                        })}
                        {Pulsanti && <th className="border ps-1" >
                            <FontAwesomeIcon className="ps-2" icon={faSearch} />
                        </th> }
                    </tr>}
                </thead>
                <tbody>
                    {(!datiTab || datiTab.length === 0) && (
                        <tr className="border">
                            <td colSpan={999} className="fs-7 text-center m-5 p-5 fst-italic" >
                                Nessun dato
                            </td>
                        </tr>
                    ) }
                    {datiTab.map((item,index) => {
                        return(
                            <tr id={item.id} key={index} className={"border table-sm "}>
                                {colonne.map((colonna,index) => {
                                    return(
                                        <th key={index} className="ps-1 fw-normal border roboto-regular py-1 " style={{fontSize :'14.4px'}} id={colonna.id}>
                                            {!!colonna.altCol ? 
                                            <>
                                                {colonna.altCol(item)}
                                            </>
                                            :
                                            (!!item[colonna.nome] && item[colonna.nome])
                                            }
                                        </th>
                                    )
                                })}
                                {Pulsanti && <Pulsanti element={item} />}
                            </tr>
                        )
                    })}

                </tbody>
            </table>

            {impaginazione !== null && <div  className="pagination fs-7 roboto-regular mt-5 d-flex align-items-center justify-content-end mb-4">
                Elementi 
                <select
                    className='ms-1 form-select  me-3 rounded-0 py-0'
                    style={{ width: '4%' , padding: '2px 7px' }}
                    value={pageSize}
                    onChange={e => {
                    setPageSize(Number(e.target.value));
                    setMyFu(myFu-1)
                    gotoPage(1)

                    }}
                >
                    {[1, 5 , 10, 20, 30, 40, 50].map(pageSize => (
                    <option className="px-2" key={pageSize} value={pageSize}>
                        {pageSize}
                    </option>
                    ))}
                </select>
                <div className=''>
                    <button className='btn btn-primary fw-bold h-50 text-center' style={{padding : '1px 10px',   color:  'white' , height: '26px'}} onClick={() => gotoPage(1)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button className='btn btn-primary fw-bold h-50 text-center' style={{padding : '1px 13px',   color:  'white' , height: '26px'}}  onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>
                    <span className="px-2" >
                    {'Pag ' + numeroPag} of {Math.ceil(totalePag)}
                    </span>
                    <button className='btn btn-primary fw-bold h-50 text-center' style={{padding : '1px 13px',   color:  'white' , height: '26px'}}  onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button className='btn btn-primary fw-bold h-50 text-center' style={{padding : '1px 10px',   color:  'white' , height: '26px'}}  onClick={() => gotoPage(Math.ceil(totalePag))} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                </div>
                
            </div>}
        </div>
    )
}