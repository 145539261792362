import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import anCliente_fetch from '../fetch/anCliente_fetch';
import AutocompliteCopertura from '../riutilizabili/autocomplite/autocompliteCopertura';
import ComuneAutocomplite from '../riutilizabili/autocomplite/ComuneAutocomplite';
import funzioniRicorrenti from '../riutilizabili/funzioniRiccorrenti.js';
import InputForm from '../riutilizabili/forms/InputForm';
import SelectForm from '../riutilizabili/forms/SelectForm';
import CheckForm from '../riutilizabili/CheckForm';
import create_autocompleteWithService from '../riutilizabili/autocomplite/create_autocompleteWithService.js';
import classZone_fetch from '../fetch/classZone_fetch.js';


export default function CreaAnagrafica({
    values ,
    onChange,
    onChangeError,
    error ,
    onChangeTipoAn ,
    visualizza = false
}) {
    const [errorCampiOb , setErrorCampiOb] = useState({})
    const [campiOb , setCampiOb] = useState({});
    
    
    const navigate = useNavigate();
    const [arrayTipoAn, setArrayTipoAn] = useState([]);
    
    useEffect( ()=>{
        tipoAnagrafica()
        searchCambiaTipoAn()
    },[])

    useEffect(()=>{verificaCampiOb()},[values])
    
    const campiObx =[
        'indirizzo',
        'numeroCivico',
        'provincia',
        'cap',
        'comuneResidenza'
    ]

    const searchCambiaTipoAn = async () => {
        if (!!values?.liberoProfessionista) {
            onChangeCambiaTipoAn('liberoProfessionista')
        }
        if (!!values?.azienda) {
            onChangeCambiaTipoAn('azienda')
        }
        if (!!values?.privato) {
            onChangeCambiaTipoAn('privato')
        }
        if (!!values?.entePubblico) {
            onChangeCambiaTipoAn('entePubblico')
        }
    }

    const onChangeCambiaTipoAn = (tipo) => {
        console.log(tipo , 'tipo' , campiObx)
        let array 
        let errors
        const keyValues = Object.keys(values)

        console.log(keyValues , ' errors keyValues' ,[...campiObx ,'pIva','ragioneSociale']);
        
        switch (tipo) {
            case 'liberoProfessionista':
                array = [...campiObx ,'pIva','ragioneSociale'].filter(item => !keyValues.includes(item) )
                errors = {}
                console.log(array , ' array');
                
                array.map(item => errors[item] = 'Campo obbligatorio' )
                setCampiOb(errors)
                setErrorCampiOb(errors)
                onChangeError(array)
                onChangeTipoAn('liberoProfessionista')
            break;
            case 'azienda':
                array = [...campiObx ,'pIva','ragioneSociale' ].filter(item => !keyValues.includes(item) )
                console.log(array , ' array')
                errors = {}
                array.map(item => errors[item] = 'Campo obbligatorio' )
                console.log(errors , ' errors')
                setCampiOb(errors)
                setErrorCampiOb(errors)
                onChangeError(array)
                onChangeTipoAn('azienda')
            break;
            case 'privato':
                array = [...campiObx ,'codiceFiscale','nome', 'cognome' ].filter(item => !keyValues.includes(item) )
                errors = {}
                array.map(item => errors[item] = 'Campo obbligatorio' )
                setCampiOb(errors)
                setErrorCampiOb(errors)
                onChangeError(array)
                onChangeTipoAn('privato')
            break;
            case 'entePubblico':
                array = [...campiObx ,'pIva','ragioneSociale' ].filter(item => !keyValues.includes(item) )
                errors = {}
                array.map(item => errors[item] = 'Campo obbligatorio' )
                setCampiOb(errors)
                setErrorCampiOb(errors)
                onChangeError(array)
                onChangeTipoAn('entePubblico')
            break;
        }
        console.log(errors , ' errors')
    }

    const verificaCampiOb = () => {
        console.log(' errorCreaerrorCrea gg' , values);

        const campiObTmp = {...campiOb}

        if (values.nazioneSigla !== 'IT') {
            ['indirizzo', 'numeroCivico', 'provincia', 'cap', 'comuneResidenza'].forEach(field => {
                delete campiObTmp[field];
            });
        }
        
        Object.keys(campiObTmp).forEach(element => {

            if (element === 'comune') {
                if(!values['comuneResidenza']) {
                    setErrorCampiOb(values => ({ ...values, [element]: 'Campo obbligatorio' }))
    
                }else {
                    let tpm = {...errorCampiOb}
                    console.log(tpm ,' tpm');
                    
                    if (!!tpm[element]) {
                        delete tpm[element] 
                        setErrorCampiOb(tpm)
                        onChangeError(tpm)
                    }
                }
            }else {
                if(!values[element]) {
                    setErrorCampiOb(values => ({ ...values, [element]: 'Campo obbligatorio' }))
                    onChangeError({ ...errorCampiOb, [element]: 'Campo obbligatorio' })
    
                }else {
                    let tpm = {...errorCampiOb}
                    console.log(tpm[element] , element ,' tpm[element]', errorCampiOb);
                    if (tpm[element]) {
                        delete tpm[element] 
                        setErrorCampiOb(tpm)
                    }
                    onChangeError(tpm)
                }

            }
            
        });
    }

    const tipoAnagrafica = () => {
        anCliente_fetch.tipoAn()
        .then(response => {
            setArrayTipoAn(response.data)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    const elabCodFiscale = (codiceFiscale) => {
        if(codiceFiscale.length === 16) {
            const {dataNascita , comuneNascita} = funzioniRicorrenti.recuperaDatiCodFiscale(codiceFiscale)
            onChange('dataNascita' , dataNascita)
            onChange('luogoNascita' , comuneNascita)
            console.log({dataNascita , comuneNascita} , codiceFiscale ,' codiceFiscale.length')
        }
        
        onChange('codiceFiscale' , codiceFiscale)
    }

    const onChangeProdottiNazione = ({ value, item }) => {
        onChange('nazione', item?.nome || '')
        onChange('nazioneSigla', item?.sigla || '')
        console.log(item?.sigla);
        
        if (item?.sigla !== 'IT') {
            let tmpError = {...errorCampiOb}
            const campiDelete = ['indirizzo', 'numeroCivico', 'provincia', 'cap', 'comuneResidenza']
            for (let index = 0; index < campiDelete.length; index++) {
                const element = campiDelete[index];
                if (!!tmpError[element]) {
                    delete tmpError[element];
                }
            }
            onChangeError(tmpError)
            setErrorCampiOb(tmpError)
        }
    }
        
    const AutocompleteProdottiNazione = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classZone_fetch.nazioneLike({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome + ' (' + item.sigla + ')',
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Seleziona nazione',
        placeholder: 'inserisci nazione',
        onChange: onChangeProdottiNazione,
        value: values?.nazione
    });

    console.log(campiOb , ' campiObs');
    console.log(errorCampiOb , ' errorCampiOb ' , values);
    console.log(values , ' values');
    return(
        <div className='border border-primary rounded p-5 m-2 border-3' >

            {/* <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!!values?.fornitore} onClick={() =>{onChange('fornitore' , 1);onChangeCambiaTipoAn('fornitore')}} />
                <label className="form-check-label" for="flexRadioDefault1">
                Fornitore
                </label>
                </div>
                <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={!values?.fornitore} onClick={() =>{onChange('fornitore' , 0)}} /> 
                <label className="form-check-label" for="flexRadioDefault2">
                Cliente
                </label>
            </div> */}
            <div className='ms-2 ps-1'>
                <label className='fw-bold' >Tipo anagrafica : </label>
                {!visualizza ? <CheckForm
                    option={[
                        {label : 'libero professionista' , nome : 'liberoProfessionista' },
                        {label : 'azienda' , nome : 'azienda'},
                        {label : 'privato' , nome : 'privato' },
                        {label : 'PA' , nome : 'entePubblico'}
                    ]}
                    values={values}
                    onChange={(nome , valore)=>onChangeCambiaTipoAn(nome)}
                />
                :
                <p>
                    {values?.tipo}
                </p>
            }
            </div>

            
            <div className='d-flex flex-wrap justify-content-between' >
                <div className='col-3' >
                    <InputForm  
                        label='Ragione Sociale'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci Ragione Sociale' : ''}
                        name = 'ragioneSociale'
                        value = {values?.ragioneSociale}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error}}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>
                <div className='col-3' >
                    <InputForm  
                        label='Nome'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci nome' : ''}
                        name = 'nome'
                        value = {values?.nome}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        classi={''}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>
                <div className='col-3' >
                    <InputForm  
                        label='cognome'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci cognome' : ''}
                        name = 'cognome'
                        value = {values?.cognome}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>
            
                {/* {values?.tipo !== '3' &&<div className='d-flex flex-column pb-3' >
                    <ComuneAutocomplite 
                        labelprops={{className : 'm-2'}}
                        label='Sede legale' 
                        value={values?.sedeLegale} 
                        onChange={({comune , item})=>onChange('sedeLegale' , item.nome)} 
                    />
                </div>} */}
                <div className='col-3'>
                    <InputForm  
                        label='Codice fiscale'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci codice fiscale' : ''}
                        name = 'codiceFiscale'
                        value = {values?.codiceFiscale}  
                        onChange = {(name , value)=> elabCodFiscale(value)} 
                        errors = {{...error }}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0 text-uppercase' : ''}
                    />
                </div>
                {values?.tipo !== '3' &&<div className='col-3'>
                    <InputForm  
                        label='Partita IVA'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci partita IVA' : ''}
                        name = 'pIva'
                        value = {values?.pIva}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />

                </div>}
                <div className='col-3'>
                    <InputForm  
                        label='Luogo di nascita'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci luogo di nascita' : ''}
                        name = 'luogoNascita'
                        value = {values?.luogoNascita}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>
                <div className='col-3'>
                    <InputForm  
                        label='Data di nascita'
                        type = 'date' 
                        placeholder ={!visualizza ? 'Inserisci data di nascita' : ''}
                        name = 'dataNascita'
                        value = {values?.dataNascita}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>
                <div className='col-3'>
                    {visualizza ?
                        <div className='m-3'>
                            <label className="form-label fw-bold"> Email </label>
                            {values?.email ? 
                                <a className='d-block' title={values?.email} href={"mailto:"+values?.email}>{values?.email}</a>
                                : 
                                <span className={"text-danger d-block font-weight-bold "}>&nbsp;</span>
                            }
                            
                            <small className={"text-danger font-weight-bold "}>&nbsp;</small>
                        </div>
                    :
                    <InputForm  
                        label='Email'
                        type = 'email' 
                        placeholder ={!visualizza ? 'Inserisci email' : ''}
                        name = 'email'
                        value = {values?.email}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {80}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />}
                </div>
                {values?.email &&<div className='col-3'>

                    {visualizza ?
                        <div className='m-3'>
                            <label className="form-label fw-bold">Seconda email </label>
                            {values?.email2 ? 
                                <a className='d-block' title={values?.email2} href={"mailto:"+values?.email2}>{values?.email2}</a>
                                : 
                                <span className={"text-danger d-block font-weight-bold "}>&nbsp;</span>
                            }
                            <small className={"text-danger font-weight-bold "}>&nbsp;</small>
                        </div>
                    :
                        <InputForm  
                        label='Seconda email'
                        type = 'email' 
                        placeholder ={!visualizza ? 'Inserisci seconda email' : ''}
                        name = 'email2'
                        value = {values?.email2}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {80}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />}
                </div>}

                {values?.email2 &&<div className='col-3'>

                    {visualizza ?
                        <div className='m-3'>
                            <label className="form-label fw-bold">Terza email </label>
                            {values?.email3 ? 
                                <a className='d-block' title={values?.email3} href={"mailto:"+values?.email3}>{values?.email3 } </a> 
                                : 
                                <span className={"text-danger d-block font-weight-bold "}>&nbsp;</span>
                            }
                            <small className={"text-danger font-weight-bold "}>&nbsp;</small>
                        </div>
                    :
                        <InputForm  
                        label='Terza email'
                        type = 'email' 
                        placeholder ={!visualizza ? 'Inserisci Terza email' : ''}
                        name = 'email3'
                        value = {values?.email3}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {80}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />}
                </div>}

                <div className='col-3'>
                    <InputForm  
                        label='Numero di telefono'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci numero di telefono' : ''}
                        name = 'numeroTelefono1'
                        value = {values?.numeroTelefono1}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {15}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>

                <div className='col-3'>
                    <InputForm  
                        label='Numero di telefono 2'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci numero di telefono 2' : ''}
                        name = 'numeroTelefono2'
                        value = {values?.numeroTelefono2}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {15}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>

                <div className='col-3'>
                    <InputForm  
                        label='Numero di telefono 3'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci numero di telefono 3' : ''}
                        name = 'numeroTelefono3'
                        value = {values?.numeroTelefono3}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {15}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>

                <div className='col-3'>
                    <InputForm  
                        label='Numero di telefono 4'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci numero di telefono 4' : ''}
                        name = 'numeroTelefono3'
                        value = {values?.numeroTelefono3}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {15}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>

                {values?.tipo !== '3' &&<div className='col-3'>
                    <InputForm  
                        label='Codice SDI'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci codice SDI' : ''}
                        name = 'codiceSDI'
                        value = {values?.codiceSDI}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {7}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>}

                {values?.tipo !== '3' &&<div className='col-3'>
                    <InputForm  
                        label='Pec'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci pec' : ''}
                        name = 'pec'
                        value = {values?.pec}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {80}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>}

                {values?.tipo !== '3' &&<div className='col-3'>
                    <InputForm  
                        label='Ruolo firmatario'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci ruolo firmatario' : ''}
                        name = 'ruoloFirmatario'
                        value = {values?.ruoloFirmatario}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {60}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>}

                {values?.tipo !== '3' &&<div className='col-3'>
                    <InputForm  
                        label='IPA'
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci ipa' : ''}
                        name = 'ipa'
                        value = {values?.ipa}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {64}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>}
{/* 
                <InputForm  
                    label='Ricarico'
                    type = 'number' 
                    placeholder ={!visualizza ? 'Inserisci ricarico' : ''}
                    name = 'ricarico'
                    value = {values?.ricarico}  
                    onChange = {(name , value)=> onChange(name , value)} 
                    errors = {{...error }}
                    maxLenght = {11}
                /> */}

                <div className='col-3'>
                    <SelectForm 
                        arrayElement={[
                                {
                                    id : 1 ,
                                    nome : "carta d'identita" ,
                                },
                                {
                                    id : 2 ,
                                    nome : "patente" ,
                                },
                                {
                                    id : 3 ,
                                    nome : "passaporto" ,
                                },

                        ]}
                        label='Documento di riconoscimento'
                        name='tipoDocRiconoscimento'
                        value={values?.tipoDocRiconoscimento || 1}
                        errors = {{...error }}
                        onChange = {(name , value)=> onChange(name , value)} 
                        visualizza
                    />
                </div>
                
                <div className='col-3'>
                    <InputForm  
                        label='Luogo rilascio documento '
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci luogo documento ' : ''}
                        name = 'luogoDocRiconoscimento'
                        value = {values?.luogoDocRiconoscimento}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {60}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>
                
                <div className='col-3'>
                    <InputForm  
                        label='Numero documento '
                        type = 'text' 
                        placeholder ={!visualizza ? 'Inserisci numero documento ' : ''}
                        name = 'numeroDocRiconoscimento'
                        value = {values?.numeroDocRiconoscimento}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {20}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>
                
                <div className='col-3'>
                    <InputForm  
                        label='Data documento'
                        type = {!visualizza ? 'date' : 'text'} 
                        placeholder ={!visualizza ? 'Inserisci data documento' : ' '}
                        name = 'dataDocRiconoscimento'
                        value = {values?.dataDocRiconoscimento}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error }}
                        maxLenght = {20}
                        disabled={!!visualizza}
                        classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                    />
                </div>
            </div>
            
            <div className='ps-1'>
                <h3 className='ms-1'>Sede Legale</h3>
                <div className="d-flex flex-wrap">
                    <div className='col-3' >
                        <InputForm  
                            label='Provincia'
                            type = 'text' 
                            placeholder ={!visualizza ? 'Inserisci provincia' : ''}
                            name = 'provincia'
                            value = {values?.provincia}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error }}
                            maxLenght = {60}
                            disabled={!!visualizza}
                            classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                        />
                    </div>
                    <div className='col-3'>
                        <InputForm  
                            label='comune'
                            type = 'text' 
                            placeholder ={!visualizza ? 'Inserisci comune' : ''}
                            name = 'comuneResidenza'
                            value = {values?.comuneResidenza}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error }}
                            maxLenght = {60}
                            disabled={!!visualizza}
                            classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                        />
                    </div>
                    <div className='col-6'>
                        <InputForm  
                            label='indirizzo'
                            type = 'text' 
                            placeholder ={!visualizza ? 'Inserisci indirizzo' : ''}
                            name = 'indirizzo'
                            value = {values?.indirizzo}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error }}
                            disabled={!!visualizza}
                            classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                        />
                    </div>
                    <div className='col-3'>
                        <InputForm  
                            label='civico'
                            type = 'text' 
                            placeholder ={!visualizza ? 'Inserisci civico' : ''}
                            name = 'numeroCivico'
                            value = {values?.numeroCivico}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error }}
                            maxLenght = {7}
                            disabled={!!visualizza}
                            classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                        />
                    </div>
                    <div className='col-3'>
                        <InputForm  
                            label='cap'
                            type = 'text' 
                            placeholder ={!visualizza ? 'Inserisci cap' : ''}
                            name = 'cap'
                            value = {values?.cap}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error }}
                            maxLenght = {5}
                            disabled={!!visualizza}
                            classInput={!!visualizza ? ' bg-white border-0 p-0' : ''}
                        />
                    </div>
                    <div className='col-3' >
                        <AutocompleteProdottiNazione />
                    </div>
                </div>
            </div>
        </div>
    )
}