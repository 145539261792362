import React , { useEffect, useState } from "react";
import CampiSezioni from "./CampiSezioni";
import sezioneService from '../fetch/classSezione'
import QuestionModal from "../riutilizabili/QuestionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faBoxesStacked, faCheck, faEdit, faEye} from "@fortawesome/free-solid-svg-icons";
import Tabella from "../riutilizabili/Tabella";
import TabellaProdotti from "../prodotti/TabellaProdotti";
import InfoModal from "../riutilizabili/InfoModal";
import FiltraSezioni from "./FiltraSezioni";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import Switch2 from "../riutilizabili/Switch2";
import classSezione from "../fetch/classSezione";
import file_fetch from "../fetch/file_fetch";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import ProdottiSezione from "./ProdottiSezione";
import Switch from "../riutilizabili/forms/Switch";
import create_autocompleteWithService from "../riutilizabili/autocomplite/create_autocompleteWithService";
import magazzini_fetch from "../fetch/magazzini_fetch";
import SwitchOr from "../riutilizabili/forms/SwitchOr";


export default function CreaSezione () { 
    const [inputs , setInputs] = useState({mobile : 0 , modulare : 1})
    const [inputsFiltra , setInputsFiltra] = useState({mobile : 0})
    const [inputsFiltraInv , setInputsFiltraInv] = useState({mobile : 0})
    const [errors , setErrors] = useState({})
    const [arraySez , setArraySez] = useState([])
    const [viewCreaSez , setViewCreaSez] = useState(false)
    const [viewModSez , setViewModSez] = useState(false)
    const [viewMostraProdSez , setViewMostraProdSez] = useState(false)
    const [arrayProdSez , setArrayProdSez] = useState([])
    const [disablePulsanteCrea , setDisablePulsanteCrea] = useState(true)
    const [viewInventario , setViewInventario] = useState(false)
    const [viewSezione , setViewSezione] = useState(false)
    const [magInventario , setMagInventario] = useState([])
    
    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)
    
    const [pageTotInv , setPageTotInv] = useState(0);
    const [pageSizeInv , setPageSizeInv] = useState(10);
    const [pageInv , setPageInv] = useState(1);
    const [azzeraVarInv , setAzzeraVarInv] = useState(0)
    const [fuInv , setFuInv] = useState(0)
    const [arrayInv , setArrayInv] = useState([])
    const [viewSezionaTutto , setViewSezionaTutto] = useState(false)
    
    const [magVuoti , setMagVuoti]= useState([])

    useEffect(()=>{
        readSez()
        magazziniVuoti()
    },[])
    useEffect(()=>{verificaCampiObbligatoriCrea()},[inputs])

    const onChange = (nome ,valore)=>{
        setInputs(values => ({ ...values, [nome]: valore }))
        verificaCampiObbligatoriCrea()
    }
    
    const magazziniVuoti = async ()=> {
        const mg = await sezioneService.getMgVuoti()
        console.log(mg , ' mg');
        setMagVuoti(mg.data)
        
    }

    const azzeraFiltra = () => {
        readSez()
        readEspInv()
        setInputs({})
    }

    const onChangeFiltraInv = (nome ,valore)=>{
        setInputsFiltraInv(values => ({ ...values, [nome]: valore }))
        filtraSezioniInv({[nome]: valore})
    }
    const onChangeFiltra = (nome ,valore)=>{
        console.log(nome ,valore , ' nome ,valore ');

        if (valore === '') {
            setInputsFiltra(values => ({ ...values, [nome]: null }))
            filtraSezioni({[nome]: null})
        }else {
            setInputsFiltra(values => ({ ...values, [nome]: valore }))
            filtraSezioni({[nome]: valore})
        }
        
    }
    //console.log(inputs , ' inputs');

    const creaSez = async ()=> {
        let tmp = {...inputs}
        delete tmp.Prod_posizione_seziones
        const sez = await sezioneService.create(tmp)
        .then(response => {
            //console.log(response , ' response');
            return response
        })
        .catch(error => {
            //console.log(error , ' error');
            return error
        })
        return sez
    }

    const readSez = async () => {
        const sezioni = await sezioneService.get({stato : 1 ,pageSize , page,obsoleto: 0,})
        //console.log(sezioni , ' sezionis');
        setInputsFiltra({})
        setArraySez(sezioni.data.data)
        setPageTot(sezioni.data.pageTot)
        setFu(fu+1)
    }

    const filtraSezioniInv = async (param) => {
        const sezioni = await sezioneService.get({...inputsFiltra ,pageSize , page, obsoleto: 0, ...param})
        //console.log(sezioni , ' sezionis');
        setArrayInv(sezioni.data.data)
        setPageTotInv(sezioni.data.pageTot)
        setFuInv(fuInv+1)
    }

    const filtraSezioni = async (param) => {
        const sezioni = await sezioneService.get({...inputsFiltra ,pageSize , page,obsoleto: 0, ...param})
        //console.log(sezioni , ' sezionis');
        setArraySez(sezioni.data.data)
        setPageTot(sezioni.data.pageTot)
        setFu(fu+1)
    }

    const impaginazione = async (impaginazione)=> {
        console.log(impaginazione , ' impaginazione');
        
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtraSezioni({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltra })
            setAzzeraVar(0)
        }
    }


    const onCloseCreate = async (stato) => {
        if (stato) {
            const sezione = await creaSez();
            if (sezione.status === 200) {
                await readSez()
                setViewCreaSez(false)
                setErrors({})
                setInputs({})
            }else {
                console.log(sezione.response.data , ' sezione');
                setErrors(sezione.response.data)
            }
        } else {
            setViewCreaSez(false)
            setInputs({})
        }
    }

    const onCloseModifica = async (stato) => {
        if (stato) {
            const sezione = await creaSez();
            //console.log(sezione , ' sezione mod');
            if (sezione.status === 200) {
                await filtraSezioni({...inputsFiltra , pageSize , page})
                setViewModSez(false)
                setInputs({})
            }else {

                setErrors(sezione.response.data)
            }
        } else {
            setViewModSez(false)
            setInputs({})
        }
    }

    const columns = [
            {
                id:0,
                label: 'Codice',
                nome: 'codice',
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Codice
                        </div>
            },
            {
                id : 1,
                label: 'Nome',
                nome: 'nome',
                altCol: (item)=> {
                    return(
                        <span className={item.sezionePadre === null  ? 'fw-bold text-start col-12 d-block ps-3' : ' text-start col-12 d-block ps-3'} >{item.nome} </span>
                    )
                },
                altLabel: <div className="col-12 text-start ps-3 pt-0">
                            Nome
                        </div>
                    
                
            },
            {
                id : 2,
                label: 'Descrizione',
                nome: 'descrizione',
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Descrizione
                        </div>
            },
            {
                id : 3,
                label: 'Capienza/Portata',
                nome: 'capienza',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item?.capienza === 1000000000 ? 'Illimitato' : item.capienza}
                        </>
                    )
                },
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Capienza/Portata
                        </div>
            },
            {
                id : 4,
                label: 'Unita misura',
                nome: 'unitaMisura',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item?.UnitaMisura?.nome}
                        </>
                    )
                },
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Unita misura
                        </div>
            },
            {
                id : 5,
                label: 'Tipo Magazzino',
                nome: 'mobile',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item.mobile === 1 ? 'mobile' : 'fisico'}
                        </>
                    )
                },
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Tipo Magazzino
                        </div>
            },
            // {
            //     id : 7,
            //     label: 'Spazio modulare',
            //     nome: 'modulare',
            //     altCol: (item)=> {
            //         //console.log(original , 'original');
            //         return(
            //             <>
            //                 {item.modulare === 1 ? <> <FontAwesomeIcon icon={faCheck} />  </> : <FontAwesomeIcon icon={faBan} />}
            //             </>
            //         )
            //     }
            // },
            {
                id : 8,
                label: 'Magazzino',
                nome: 'sezionePadre',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item.magazzino === null ? '-' 
                            : 
                            (item.magazzino.nome === item.nome ? '-' : item.magazzino.nome)
                            }
                        </>
                    )
                },
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Magazzino
                        </div>
            },
            {
                id : 8,
                label: 'Sezione padre',
                nome: 'sezionePadre',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <>
                            {item.sezionePadre === null ? 'Nessuno' 
                            : 
                            (!item.sezionePadre.padreId ? '-' : item.sezionePadre.nome)
                            }
                        </>
                    )
                },
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Sezione padre
                        </div>
            },
            {
                id : 10,
                label: 'Obsoleto',
                nome: 'Obsoleto',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <div className="d-flex justify-content-center">
                           
                            {magVuoti.includes(item.id) && <SwitchOr
                                label=''
                                classe={' m-0 p-0 ms-4 pt-3 '}
                                stato={item.obsoleto}
                                number={item}
                                onChangeValue={onChangeValueSelezionaObsoleto}
                            />
                            
                            }   
  
                        </div>
                    )
                },
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Dismessi
                        </div>
            },
            {
                id : 9,
                label: 'Sezione padre',
                nome: 'azioni',
                altCol: (item)=> {
                    //console.log(item , 'item');
                    return(
                        <td className=" my-0 py-0 d-flex">
                            <button className='btn button_fix m-0 p-0 mx-1' >
                                <FontAwesomeIcon className='fs-6 m-0 p-0' onClick={()=>setViewSezione(item)} icon={faEye} />
                            </button>
                            <button className='btn button_fix m-0 p-0  mx-1' >
                                <FontAwesomeIcon className='fs-6 m-0 p-0' title="modifica sezione/magazzino" icon={faEdit} onClick={async () =>await mostraModifica(item)} />
                            </button>
                            {/* <button className='btn button_fix my-0 py-0' >
                                <FontAwesomeIcon className='fs-6 my-0 py-0' title="visualizza sezioni/prodotti" icon={faBoxesStacked} onClick={async () =>await mostraProdSez(item)} />
                            </button> */}
                            <ProdottiSezione sezioneId={item.id} nomeSezione={item.nome} icona={<FontAwesomeIcon icon={faBoxesStacked} />} />
                        </td>
                    )
                },
                altLabel: <div className="col-12 text-start px-3 pt-0 mt-0">
                            Azioni
                        </div>
            },
    ]

    const mostraModifica = (element)=> {
        console.log(element , ' element');
        
        let dati = {...element}
        dati.nomeSezionePadre = dati?.sezionePadre?.nome;
        dati.nomeUnitaMisura = dati?.UnitaMisura?.nome; 
        if (!!dati?.padreId) {
            dati.sezione = dati?.UnitaMisura?.nome; 
        }
        if (!!dati?.sezionePadre) {
            dati.sezione = 1
        }
        dati.tipoMag = '0'
        if (dati.opera === 1) {
            dati.tipoMag = '1'
        }
        if (dati.mobile === 1) {
            dati.tipoMag = '2'
        }
        if (dati.bts === 1) {
            dati.tipoMag = '3'
        }
        //console.log(dati, 'dati');
        setInputs(dati);
        verificaCampiObbligatoriCrea()
        setErrors({})
        setViewModSez(true)
    }

    const onCloseMostraProd = () => {
        setViewMostraProdSez(false)
    }

    const mostraProdSez = (element) => { 
        console.log(element , ' element');
        
        const prodotti = [...element.Prod_posizione_seziones]
        //console.log(element , ' element');
        let prodottiAttivi =[]
        prodotti.forEach(prod => {
            if (prod.stato === 1) {
                prodottiAttivi.push(prod)
            }
        });
        //console.log(element , ' arrayProdSez 2');

        setArrayProdSez({prodotti : prodottiAttivi , sezioneFigli : element.sezioniFigli , nome : element.nome})
        setViewMostraProdSez(true)
    }

    const onChangeValueSelezionaObsoleto = async (stato , items) => {
        try {
            const sez = await sezioneService.create({...items , obsoleto : stato+''})
            await filtraSezioni({...inputsFiltra , pageSize , page})
        } catch (error) {
            console.log(error , ' error obsoleto');
            
        }
    }

    const onChangeValueSelezionaMg = (stato , id) => {
        let tmp =[...magInventario]
        if (!!stato) {
            tmp.push(id)
        }else {
            const posizione = tmp.indexOf(id);
            tmp.splice(posizione , 1)
        }
        setMagInventario(tmp)
    }
    
    const Pulsanti = ({element}) => {
        //console.log(element , ' element');
        return(
            <td className=" my-0 py-0 d-flex">
                <button className='btn button_fix m-0 p-0 mx-1' >
                    <FontAwesomeIcon className='fs-6 m-0 p-0' onClick={()=>setViewSezione(element)} icon={faEye} />
                </button>
                <button className='btn button_fix m-0 p-0  mx-1' >
                    <FontAwesomeIcon className='fs-6 m-0 p-0' title="modifica sezione/magazzino" icon={faEdit} onClick={async () =>await mostraModifica(element)} />
                </button>
                {/* <button className='btn button_fix my-0 py-0' >
                    <FontAwesomeIcon className='fs-6 my-0 py-0' title="visualizza sezioni/prodotti" icon={faBoxesStacked} onClick={async () =>await mostraProdSez(element)} />
                </button> */}
                <ProdottiSezione sezioneId={element.id} nomeSezione={element.nome} icona={<FontAwesomeIcon icon={faBoxesStacked} />} />
            </td>
        )
    }
    
    const PulsantiSeleziona = ({element}) => {

        //console.log(element , ' element');
        return(
            <td>
                <Switch2
                    stato={magInventario.includes(element.id)}
                    number={element.id}
                    onChangeValue={onChangeValueSelezionaMg}
                />
            </td>
        )
    }

    const mostraSottoSezione = async (id) => {
        setViewMostraProdSez(false)
        setArrayProdSez({})
        const sezioni = await sezioneService.get({id : id ,obsoleto: 0});
        mostraProdSez(sezioni.data[0])

    }

    const verificaCampiObbligatoriCrea = () => {
        const campi = [
            'umId',
            'nome',
        ]
        const error = funzioniRiccorrenti.validazioneCampi(campi , inputs);
        console.log(error);
        if (Object.keys(error).length > 0) {
            setErrors(error)
            setDisablePulsanteCrea(true)
        }else {
            setErrors({})
            setDisablePulsanteCrea(false)
        }
        //console.log(inputs , ' inputs ver');
        // const error = funzioniRiccorrenti.verificaCampiObbligatori(Object.keys(inputs) ,campi);
        // if (error === true) {
        //     setErrors({})
        //     setDisablePulsanteCrea(false)
        // }else {
        //     setErrors(error)
        //     setDisablePulsanteCrea(true)
        // }
    }
    //console.log(arrayProdSez , ' arrayProdSezs');
    console.log(viewSezione , ' viewSezione');
        
    const azzera = async ()  =>{
        setInputsFiltra({qtDisp :1})
        setAzzeraVar(1)
        await readSez()
    }

    const esportaDati = async () => {
        //await classSezione.getInventarioMg({magazzini : magInventario})
        console.log(magInventario , ' magInventario');
        
        for (let index = 0; index < magInventario.length; index++) {
            const element = magInventario[index];
            const mag = await classSezione.get({id : element})
            if (mag?.data[0]?.nome) {
                await file_fetch.scaricaInventario({magazzini : element},mag.data[0].nome)
            }
        }
        
    }

    const esportaDatiExcell = async () => {
        //await classSezione.getInventarioMg({magazzini : magInventario})
        console.log(magInventario , ' magInventario');

        for (let index = 0; index < magInventario.length; index++) {
            const element = magInventario[index];
            const mag = await classSezione.get({id : element})
            if (mag?.data[0]?.nome) {
                await file_fetch.inventarioMgExcel({magazzini : element},mag.data[0].nome)
            }
        }
        
    }

    const readEspInv = ()=> {
        sezioneService.get({pageSize , page, magazzinoPrincipale: 1 ,obsoleto: 0, magazzinoPrincipaleSelect :1})
        .then(response => {
            setInputsFiltraInv({magazzinoPrincipale : 1, magazzinoPrincipaleSelect :1})
            console.log(response.data.data , ' response.data.data');
            setArrayInv(response.data.data)
            setPageTotInv(response.data.pageTot)
            setAzzeraVar(1)
            setFuInv(fuInv+1)
        })
    }

    const apriEsportaInvetario = () => {
        readEspInv()
        setViewInventario(true);
    }
    
    const impaginazioneInv = async (impaginazione)=> {
        console.log(impaginazione , ' impaginazione');
        
        setPageInv(impaginazione.numeroPag)
        setPageSizeInv(impaginazione.pageSize)
        if ( pageInv !==impaginazione.numeroPag ||  pageSizeInv !== impaginazione.pageSize) {
            await filtraSezioniInv({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputsFiltraInv , magazzinoPrincipale: 1 ,magazzinoPrincipaleSelect :1 })
            setAzzeraVar(0)
        }
    }

    const selezionaTutto = async (stato) => {
        setAzzeraVar(0)
        
        if (stato) {
            const sez = await sezioneService.get({ magazzinoPrincipale: 1 ,obsoleto: 0, magazzinoPrincipaleSelect :1 })
            const sezId = await sez.data.map(item => item.id)
            setMagInventario(sezId)
        } else {
            setMagInventario([])
        }
        setViewSezionaTutto(!!stato)
        setFuInv(fuInv+1)
        setAzzeraVar(1)
    }


    console.log(magInventario , ' magInventario');
    
    return( 
        <div className='pt-2'>
                <PaginazioneTitoloPulsanteCrea titolo={'Magazzini o sezioni'} funCrea={()=>{setViewCreaSez(true); verificaCampiObbligatoriCrea()}} />

            <div className="w-100">

                <div className="w-100 m-4 ps-4 pe-5 ">
                    <div style={{marginRight : ' 2rem' ,marginTop :'2.4rem'}}>
                        <div className="border_filtra p-3 my-5 ms-4 w-100 " >
                            <FiltraSezioni 
                                value={inputsFiltra}
                                onChange={onChangeFiltra}
                            />
                            <div className="ms-4">
                                <PulsantiFiltra
                                    filtra={filtraSezioni}
                                    azzera={azzera}
                                />
                            </div>
                        </div>
                    </div>
                        
                    <div className="m-4 mb-5" >
                        <button 
                            className="btn btn-primary" 
                            onClick={apriEsportaInvetario}
                        >
                            Scarica invetario magazzini
                        </button>
                    </div>

                    <div className="w-100 ms-2 ps-1 pe-5">
                        <TabellaPaginazione
                            colonne={columns}
                            dati={arraySez}
                            totalePag={pageTot}
                            azzera={azzeraVar}
                            impaginazione={impaginazione}
                            fu={fu}
                            // Pulsanti={Pulsanti}
                        />
                    </div>

                </div>

                {/* <Tabella
                    data={arraySez}
                    columns={columns}
                    Pulsanti={Pulsanti}
                /> */}


                {viewCreaSez && <QuestionModal
                    title='Crea magazzino/sezione'
                    text={
                        <div>
                            <CampiSezioni      
                                values={inputs}
                                onChange={onChange}
                                errors={errors}
                                classiDiv=' d-flex flex-wrap col-12'
                            />
                        </div>
                    }
                    YesLabel='Crea'
                    yesDisabled={disablePulsanteCrea}
                    onClose={onCloseCreate}
                    classi={'  modal-xxl '}
                />}

                {viewModSez && <QuestionModal
                    title='Modifica magazzino/sezione'
                    text={
                        <div>
                            <CampiSezioni
                                values={inputs}
                                onChange={onChange}
                                errors={errors}
                                classiDiv=' d-flex flex-wrap'
                                edit={true}
                            />
                        </div>
                    }
                    YesLabel='Salva'
                    onClose={onCloseModifica}
                    classi={'  modal-xxl '}
                />}

                {!!viewMostraProdSez &&<InfoModal
                    title={"Articoli all'interno della magazzino/sezione "+ arrayProdSez.nome  +" e sottosezioni"}
                    text={
                        <div className="d-flex flex-column align-items-center " >
                            {arrayProdSez?.prodotti.length > 0 ?
                                <div className="w-100 border border-primary border-3 p-3 rounded-4 m-3" >
                                    <h3>Articoli</h3>

                                    <TabellaProdotti 
                                        array={arrayProdSez.prodotti.filter(prod => prod.bollaUscitaId === null)} 
                                       
                                    />
                                </div>
                                :
                                <>
                                    non ci sono articoli in questa sezione
                                </>
                            }
                            { arrayProdSez?.sezioneFigli.length > 0 ?
                                <div className="border border-primary border-3 p-3 rounded-4 m-3 w-50" >
                                    <h3>SottoSezioni</h3>
                                     {arrayProdSez.sezioneFigli.map((item)=> {
                                        return(
                                            <div>
                                                <p> {item.nome} <button className="btn text-primary" onClick={()=> mostraSottoSezione(item.id)} > <FontAwesomeIcon icon={faEye} /> </button> </p>
                                            </div>
                                        )
                                     })}
                                </div>
                                :
                                <>
                                    non ci sono sottosezzioni in questa sezione
                                </>
                            }
                        </div>
                    }
                    className=' modal-xxl '
                    canClose
                    handleClose={onCloseMostraProd}
                    onClose={()=>setViewMostraProdSez(false)}
                />}

            </div>

            {!!viewInventario &&
                <InfoModal
                    classi={' modal-xxl '}
                    title={'Esporta inventario magazzino'}
                    text={<div className="w-100" >
                        <div className="border_filtra p-3 my-5" >
                            <FiltraSezioni 
                                value={inputsFiltraInv}
                                onChange={onChangeFiltraInv}
                            />
                            <PulsantiFiltra
                                filtra={filtraSezioni}
                                azzera={apriEsportaInvetario}
                            />
                        </div>
                        <div className="w-100">
                            <Switch
                                number='selezionaTutto'
                                stato={viewSezionaTutto}
                                label={'Seleziona Tutto'}
                                onChangeValue={(stato , nome)=>selezionaTutto(!!stato)}
                                classe={' p-0 float-end '}
                                width="10%"
                            />
                        </div>

                        <TabellaPaginazione
                            colonne={columns}
                            dati={arrayInv}
                            totalePag={pageTotInv}
                            azzera={azzeraVar}
                            impaginazione={impaginazioneInv}
                            fu={fuInv}
                            Pulsanti={PulsantiSeleziona}
                        />
         

                    </div>}
                    alternativeFooter={
                        <div>
                            <button className="btn btn-primary m-3" onClick={esportaDati}>
                                esporta
                            </button>
                            <button className="btn btn-primary m-3" onClick={esportaDatiExcell}>
                                esporta in excel
                            </button>
                        </div>
                    }
                    canClose
                    handleClose={()=>setViewInventario(false)}
                />
            }

            {!!viewSezione &&<InfoModal
                size='xl'
                title={'Anagrafica Magazzino/Sezione'}
                text={
                    <div>
                        <div className="d-flex flex-column border border-primary border-3 m-3 p-3 rounded">
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Nome :  
                                </span>
                                {viewSezione?.nome}
                            </span>
                            <span className="col-6">
                            <span className="fw-bold pe-2">
                                Descrizione :  
                            </span>
                                 {viewSezione?.descrizione}
                            </span>
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Codice identificativo : 
                                </span>
                                {viewSezione?.codice}
                            </span>
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Capienza : 
                                </span>
                                {viewSezione?.capienza}
                            </span>
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    unita di misura : 
                                </span>
                                {viewSezione?.UnitaMisura?.nome}
                            </span>
                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    RFID :
                                </span>
                                {viewSezione?.rfid}
                            </span>

                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Modulare {!!viewSezione?.modulare ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                                </span>
                            </span>

                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Magazzino in opera {!!viewSezione?.opera ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                                </span>
                            </span>

                            <span className="col-6">
                                <span className="fw-bold pe-2">
                                    Mobile {!!viewSezione?.mobile ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                                </span>
                            </span>
                        </div>

                        <div className="d-flex flex-column border border-primary border-3 m-3 p-3 rounded"> 
                            {viewSezione.padreId !== null ?<span className="col-6">
                                Sezione padre : {viewSezione?.sezionePadre?.nome}
                            </span>
                            :
                            <>
                            <h3 className="mt-4" >Ubicazione impianto : </h3>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        provincia : 
                                    </span>
                                    {viewSezione?.provincia}
                                </span>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        comune : 
                                    </span>
                                    {viewSezione?.comune}
                                </span>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        indirizzo : 
                                    </span>
                                    {viewSezione?.indirizzo}
                                </span>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        civico :
                                    </span>
                                    {viewSezione?.numeroCivico}
                                </span>
                                <span className="col-6">
                                    <span className="fw-bold pe-2">
                                        cap : 
                                    </span>
                                    {viewSezione?.cap}
                                </span>
                            </>}
                        </div>
                    </div>
                }

                canClose
                handleClose={()=>setViewSezione(false)}
            />}
        </div>
    )
}