/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import datiDaschboard_fetch from "../fetch/datiDaschboard_fetch"
import Card from "../riutilizabili/Card"
import QuestionModal from "../riutilizabili/QuestionModal"
import TabellaProdotti from "../prodotti/TabellaProdotti"
import prodSezioneService from "../fetch/classProdSezzione"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBox } from "@fortawesome/free-solid-svg-icons"
import SelezionaSezioneDest from "../prodotti/prodottiSezione/sezioni/SelezionaSezioneDest"
import Switch2 from "../riutilizabili/Switch2"
import InputForm from "../riutilizabili/forms/InputForm"
import { Link } from "react-router-dom"
import classProdSezzione from "../fetch/classProdSezzione"
import InfoModal from "../riutilizabili/InfoModal"
import Tabella from "../riutilizabili/Tabella"
import AssegnaSezioneProd from "../Magazzino/AssegnaSezioneProd"


export default function View() {

    useEffect(()=>{
        aggiornaCardProdotti()
        aggiornaCardBolle()
        aggiornaCardSezioni()
    },[])
    const [datiProds , setDatiProds] = useState({});
    const [datibolle , setDatibolle] = useState({});
    const [datiSezioni , setDatiSezioni] = useState({});
    const [viewProdNonAllocati , setViewProdNonAllocati] = useState(false);
    const [viewProdSez , setViewProdSez] = useState(false);
    const [viewProdEs , setViewProdEs] = useState(false);
    const [arrayProds, setArrayProds] = useState([]);
    const [sezioneSel, setSezioneSel] = useState(null);
    const [qtProd, setQtProd] = useState({});
    
    const aggiornaCardProdotti = async () => {
        const prods = await datiDaschboard_fetch.getProdotti();
        setDatiProds(prods.data)
    }

    const aggiornaCardBolle = async () => {
        const bolle = await datiDaschboard_fetch.getBolla();
        setDatibolle(bolle.data)
    }

    const aggiornaCardSezioni = async () => {
        const sezioni = await datiDaschboard_fetch.getSezioni();
        //console.log(sezioni , 'sezioni');
        setDatiSezioni(sezioni.data)
    }

    const onCloseProdNonAllocati = (stato) => {

        if (!!viewProdSez) {
            setViewProdSez(false)
        }else {
            setViewProdNonAllocati(false)
        }
        
    }

    const readProdSezione = async () => {
        const arrayProds = await prodSezioneService.get({ sezioneId: 4});
        console.log(arrayProds.data , ' arrayProds');
        setArrayProds(arrayProds.data )
        setViewProdNonAllocati(true)
        setViewProdSez(false)
    }

    const PulsantiTabProdNonAllocati = (id) => {
        const element = id.id.original;
        //console.log(element , 'ele');
        return(
            <td>
                {/* <InputForm
                    type={'number'}
                    name={'quantita'}
                    label='quantità da assegare'
                    value={!!qtProd['P'+element.id] ? qtProd['P'+element.id] : 1}
                    errors={{}}
                    onChange={(name , value)=>setQtProd(values => ({...values , ['P'+element.id]: value}))}
                    max={element?.quantitaResidua}
                /> */}
                <button className="btn border-0 fs-4" title='assegna a sezione' onClick={()=>setViewProdSez(element)} >
                    <FontAwesomeIcon icon={faBox} />
                </button>
            </td>
      
        )
    }

    const addProdOnSezione = async (sezioneId) => {
        setSezioneSel(sezioneId);
        let prod = {...viewProdSez};
        prod.sezioneId = sezioneId;
        // prod.quantitaResidua = qtProd['P'+prod.id].toString()
        //console.log(prod , ' prod');
        const aggiornaSezione = await prodSezioneService.create(prod);
        //console.log(aggiornaSezione , ' aggiornaSezione');
        if (aggiornaSezione.status === 200) {
            setViewProdSez(false)
            setQtProd({})
            setSezioneSel(null)
            await aggiornaCardProdotti()
            await aggiornaCardBolle()
            await aggiornaCardSezioni()
            setViewProdNonAllocati(false)
        }
    }

    const PulsantiTabSez = (id) => {
        const element = id.id.original;
        //console.log(element , 'ele2');
        return(
            <td>
                <Switch2
                    stato={!!sezioneSel ? (element.sezioneId === sezioneSel) : false }
                    onChangeValue={()=>addProdOnSezione(element.sezioneId)}
                    number={element.id}
                    label=''
                />
            </td>
      
        )
    }

    const serchProdSezioneQtMin = async () => {
        const prod = await classProdSezzione.getProdSezioneQtMin()
        console.log(prod.data, ' .data');
        setViewProdEs(prod.data)
    }

    const columns =[
        {
        Header: 'Nome',
        accessor: 'nome',
        },
        {
        Header: 'descrizione',
        accessor: 'descrizione',
        },
        {
        Header: 'Quantità residua',
        accessor: 'quantitaResidua',
        },
        {
        Header: 'Quantità minima',
        accessor: 'qtMin',
        },
    ]
    
    return(
        <div className=" d-flex flex-wrap justify-content-around" >
            <div className="col-2 m-3" >
                <Card
                    title={'DDT'}
                    text={
                        <div>
                            <div className="text-black-50 fw-bold">Informazioni :</div>
                            <span className="fw-bold">Totali ddt registrati : </span>{datibolle?.bolleTotali} <br/>
                            <span className="fw-bold">Totali ddt in entrata registrate :</span> {datibolle?.bollaEntrata} <br/>
                            <span className="fw-bold">Totali ddt in uscita registrate : </span> {datibolle?.bollaUscita} <br/>
                            <div className="fa-9x text-center d-flex flex-column">
                                <Link className="fs-6 link-underline-light btn btn-primary my-4" to='/creaBollaEntrata' >
                                    Crea ddt in entrata
                                </Link>
                                <Link className="fs-6 link-underline-light btn btn-primary my-4" to='/creaBollaUscita' >
                                    Crea ddt in uscita
                                </Link>
                            </div>
                        </div>
                    }
                />
            </div>

            <div className="col-2 m-3" >
                <Card
                    title={'Articoli'}
                    text={
                        <div>
                            <div className="text-black-50 fw-bold">Informazioni :</div>
                            <span className="fw-bold">Articoli non assegnati a sezzione: </span>{datiProds?.prodottiSezNull} <br/>
                            <span className="fw-bold">Articoli disponibili :</span> {datiProds?.qtProdDisp} <br/>
                            <span className="fw-bold">Articoli in esaurimento scorte :</span> <button className="btn text-primary fw-bold fs-5" onClick={()=> serchProdSezioneQtMin()} >{datiProds?.prodQtEs}</button>  <br/>
                            <span className="fw-bold">Valore stimato articoli :</span> {datiProds?.valore} €<br/>
                        </div>
                    }
                />
            </div>

            <div className="col-2 p-2 m-3" >
                <Card
                    title={'Sezioni'}
                    text={
                        <div>
                            <div className="text-black-50 fw-bold">Informazioni :</div>
                            <span className="fw-bold">Totali sezioni presenti :</span> {datiSezioni?.sezTot} <br/>
                            <span className="fw-bold">Sezioni occupate :</span> {datiSezioni?.sezioniOccupate} <br/>
                            <span className="fw-bold">Sezioni disponibili :</span> {datiSezioni?.sezioniLibere} <br/>
                        </div>
                    }
                />
            </div>
            {/* <div className="col-2 m-3" >
                <Card
                    title={'Articoli non allocati'}
                    text={
                        <div className="fs-1 text-center">
                            <a 
                                href="javascript:void(0)" 
                                className="text-primary pe-auto link-underline-light"
                                onClick={readProdSezione} 
                            >
                                {datiProds?.prodottiSezNull}
                            </a>
                        </div>
                    }
                />
            </div> */}
{/* 
            <div className="col-3 m-3" >
                <Card
                    title={'Crea bolla'}
                    text={
                        <div className="fa-9x text-center d-flex flex-column">
                            <Link className="fs-4 link-underline-light btn btn-primary my-4" to='/creaBollaEntrata' >
                                Bolla entrata
                            </Link>
                            <Link className="fs-4 link-underline-light btn btn-primary my-4" to='/creaBollaUscita' >
                                Bolla uscita
                            </Link>
                        </div>
                    }
                />
            </div> */}

            <div className="col-7 m-3"></div>

            {!!viewProdNonAllocati && <QuestionModal
                title={'Articoli non allocati'}
                text={
                    <div>
                        {/* {!viewProdSez ?<TabellaProdotti
                            array={arrayProds}
                            Pulsanti={PulsantiTabProdNonAllocati}
                        />
                        :
                        <SelezionaSezioneDest
                            quantita={!!qtProd['P'+viewProdSez.id] ? qtProd['P'+viewProdSez.id] : '1'}
                            unitaMisuraId={viewProdSez.umId}
                            Pulsanti={PulsantiTabSez}
                        />} */}

                        <AssegnaSezioneProd prodSfusi={true} />
                    </div>
                }
                onClose={onCloseProdNonAllocati}
            />}

            {!!viewProdEs && <InfoModal
                size ='xl'
                title='Articoli in esaurimento'
                text={
                    <div>
                        <Tabella
                            data={viewProdEs}
                            columns={columns}

                        />
                    </div>
                }
                canClose
                handleClose={()=>setViewProdEs(false)}
            />}


        </div>
    )
}