import { useState } from "react";
import InputForm from "../riutilizabili/forms/InputForm";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import prodSituazione_fetch from "../fetch/prodSituazione_fetch";
import InfoModal from "../riutilizabili/InfoModal";
import SelezionaSezioneDest from "../prodotti/prodottiSezione/sezioni/SelezionaSezioneDest";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuestionModal from "../riutilizabili/QuestionModal";



export default function SpostaProd({
    prodottoId ,
    sezionePartenzaId ,
    qtaMax ,
    classiBtn = '' ,
    itemButton ,
    aggiorna
}) {
    const [inputsSposta , setInputsSposta] = useState({})
    const [situazioneOld , setSituazioneOld] = useState({})
    const [viewSposta , setViewSposta] = useState(false)
    const [viewSelezionaDest , setViewSelezionaDest] = useState(false)
    
    const onChangeSposta = (name , value) => {
        console.log(name , value , ' name , value');
        setInputsSposta(values => ({...values, [name]: value})) ; 
    }

    const readSituzione = async () => {
        const response = await prodSituazione_fetch.get({prodottoId , sezioneId : sezionePartenzaId})
        setSituazioneOld(response.data[0])
        setViewSposta(true)
    }
   
    const colonne = [
        {
            label: 'Quantità',
            nome: 'qtaMax',
            altCol : (item) => {
                return (
                    <InputForm
                        label=' '
                        type='number'
                        placeholder='Inserisci quantità'
                        name='qta'
                        value={inputsSposta.qta || qtaMax}
                        max={qtaMax}
                        onChange={(name , value) => onChangeSposta(name , value)}
                        errors={{}}
                        classi={' col-12 ms-0'}
                    />
                )
            }
        },
        {
            label: 'Prodotto',
            nome: 'prodottoId',
            altCol : (item) =>  {
                return (
                    <div style={{height: '5.6rem'}} className="d-flex justify-content-center align-items-center px-2" >
                        {item?.Prodotti?.nome} 
                    </div>
                )
            }
        },
        {
            label: 'Posizione attuale',
            nome: 'sezionePartenzaId',
            altCol : (item) => {
                return(
                    <div style={{height: '5.6rem'}} className="d-flex justify-content-center align-items-center px-2" >
                        {item?.Sezioni?.nome}

                    </div>
                )
            }
        },
        {
            label: 'Destinazione',
            nome: 'sezionePartenzaId',
            altCol : (item) => {
                return(
                    <div style={{height: '5.6rem'}} className="d-flex justify-content-center align-items-center px-2" >
                        {inputsSposta?.sezioneNome || ' Nessuna destinazione selezionata'}

                    </div>
                )
            }
        },
        {
            label: ' ',
            nome: 'sezioneArrivoId',
            altCol : (item) => {
                return (
                    <div className="d-flex justify-content-center mt-5">
                        <button onClick={()=>setViewSelezionaDest(true)} className="btn btn-primary" >
                            Seleziona destinazione
                        </button>
                    </div>
                )
            }
        },
    ]

    const PulsantiSelezionaSezioneDest = (id)=> {
        console.log(id.id.original, ' element');
        
        return(
            <td>
                <button className="btn" onClick={()=>{
                    console.log(id.id.original , ' id.id.original');
                    
                    onChangeSposta('sezioneDestinazioneId' ,id.id.original.id)
                    onChangeSposta('sezioneNome' ,id.id.original.nome)
                    setViewSelezionaDest(false)
                }}>
                    <FontAwesomeIcon icon={faCheck} />
                </button>
            </td>
        )
    }

    console.log(inputsSposta , ' inputsSposta');

    const onClose = async (stato) => {
        if (stato) {
            await prodSituazione_fetch.spostaProdSituazione({
                prodottoId ,
                sezionePartenzaId ,
                qta : qtaMax,
                sezioneDestinazioneId : sezionePartenzaId ,
                ...inputsSposta 
            })
            aggiorna()
        }
        setViewSposta(false)
    }
    
    
    return(
        <>
            <button onClick={readSituzione} title="Sposta" className={"btn "+classiBtn}>
                {itemButton}
            </button>
        
            {!!viewSposta && 
            <QuestionModal
                title={'Sposta articolo '+situazioneOld?.Prodotti?.nome}
                text={
                    <div className="p-4">
                        <div className="col-12">
                            <TabellaPaginazione 
                                impaginazione={null}
                                colonne={colonne}
                                dati={[situazioneOld]}
                            />
                        </div>
                    </div>
                }
                onClose={onClose}
                classi={' modal-xxl '}
            />
            }
            {!!viewSelezionaDest && <InfoModal
                    title={'Seleziona sezione di destinazione'}
                    text={<SelezionaSezioneDest
                            Pulsanti={PulsantiSelezionaSezioneDest}
                            quantita={null}
                            unitaMisuraId={null}
                            sezioneId={situazioneOld?.sezioneId}
                        />
                    }
                    canClose
                    handleClose={()=>setViewSelezionaDest(false)}
                    size='xl'
                />}
        </>
    )

}