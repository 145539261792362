import { useState } from "react";
import InputForm from "../riutilizabili/forms/InputForm";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import seriali_fetch from "../fetch/seriali_fetch";
import TabellaPaginazione from "../riutilizabili/TabellaPaginazione";
import { format } from "date-fns";



export default function ViewSeriali() {
    const [inputFiltra, setInputFiltra] = useState({})
    const [dati, setDati] = useState(false)

    const onChange = (nome,valore) => {
        setInputFiltra({...inputFiltra, [nome]: valore})
        filtra({...inputFiltra, [nome]: valore})
    }

    const filtra = async (param) =>  {
        const test = await seriali_fetch.controllaSeriali(param)
        setDati(test.data) 
    }

    const azzera = () => {
        setInputFiltra({})
        setDati(false)
        filtra({})
    }

    const colonne = [
        {
            id: 1,
            label: 'Tipo',
            nome: 'tipo',
            altCol : (item)=> {
                return(
                    <div style={{width: '10rem'}} className='m-auto' >
                        {item.Prod_posizione_sezione.bollaEntrata === null ? 'DDT in uscita' : 'DDT in entrata'}
                    </div>
                )
            }
        },
        {
            id: 2,
            label: 'DDT',
            nome: 'DDT',
            altCol : (item)=> {
                return(
                    <div style={{width: '10rem'}} className='m-auto' >
                        {item.Prod_posizione_sezione.bollaEntrata === null ? 
                            item.Prod_posizione_sezione.bollaUscita.idProg+'/'+item.Prod_posizione_sezione.bollaUscita.annoId+(item.Prod_posizione_sezione.bollaUscita.codiceMag === null ? '' : '-'+item.Prod_posizione_sezione.bollaUscita.codiceMag)
                        : 
                            item.Prod_posizione_sezione.bollaEntrata.numero
                        }
                    </div>
                )
            }
        },
        {
            id: 3,
            label: 'Fornitore/Cliente',
            nome: 'fornitoreCliente',
            altCol : (item)=> {
                return(
                    <div style={{width: '10rem'}} className='m-auto' >
                        {item.Prod_posizione_sezione.bollaEntrata === null ? 
                            item.Prod_posizione_sezione.bollaUscita.mittente.viewName
                        : 
                            item.Prod_posizione_sezione.bollaEntrata.mittente.viewName
                        }
                    </div>
                )
            }
        },
        {
            id: 4,
            label: 'Data e ora',
            nome: 'dataCreate',
            altCol : (item)=> {
                return(
                    <div style={{width: '10rem'}} className='m-auto' >
                        {format(item.dataCreate, "dd-MM-yyyy HH:mm")}
                    </div>
                )
            }
        }
    ]

    return(
        <div className="pt-2">
            <PaginazioneTitoloPulsanteCrea titolo={'Seriali'} funCrea={null} />
            <div className="p-4 ps-5">
                <div className='border_filtra p-3 mx-4 px-4' >
                    <InputForm
                        type={'text'}
                        name={'seriale'}
                        placeholder={'Inserisci seriale'}
                        label={'Seriale'}
                        classi={' col-3 '}
                        value={inputFiltra?.seriale || ''}
                        onChange={onChange}
                        errors={{}}
                    />
                    <div className="ps-2">
                        <PulsantiFiltra azzera={azzera}  />
                    </div>
                </div>

                {dati ?<TabellaPaginazione
                    colonne={colonne}
                    dati={dati}
                    impaginazione={null}
                />
                : <div className='text-center mt-5'></div>
                }
            </div>
        </div>
    )
}