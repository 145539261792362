import React, { useState, useEffect } from 'react';
import user from '../fetch/user_fetch';
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faInfo } from '@fortawesome/free-solid-svg-icons' 
import { useNavigate } from 'react-router-dom';
import InfoModal from './InfoModal';
import InputForm from './forms/InputForm';
import PulsantiFiltra from './PulsantiFiltra';
import user_fetch from '../fetch/user_fetch';
import TabellaPaginazione from './TabellaPaginazione';

export default function SelezionaUser({ onCancel, onSuccess, utentiDaEscludere }) {
    const navigate = useNavigate();
    //console.log(utentiDaEscludere , ' utentiDaEscludere');
    const [inputs, setInputs] = useState([]);
    const [dati, setDati] = useState([]);
    const [listReady, setListReady] = useState(false);

    const [pageTot , setPageTot] = useState(0);
    const [pageSize , setPageSize] = useState(10);
    const [page , setPage] = useState(1);
    const [azzeraVar , setAzzeraVar] = useState(0)
    const [fu , setFu] = useState(0)

    useEffect( ()=>{
        filtrare({page , pageSize})
      },[])

    const handleChange = async (name,value) => {
        setInputs(values => ({...values, [name]: value}))
        filtrare({[name]: value})
    }

    const azzeraFiltra = async () => {
        await filtrare({pageSize , page : 1})
        setInputs({})
    }

    const filtrare = async (params) => {
        const utenti = await user_fetch.get({ page , pageSize , inputs , ...params })
        console.log(utenti , ' utenti');
        setDati(utenti.data.data)
        setPageTot(utenti.data.pageTot)
        setFu(fu+1)
    }
    const columns =[
        {
          id : 1, 
          label: 'Username',
          nome: 'username',
        },
        {
          id : 2, 
          label: 'Tipo',
          nome: 'tipo',
          altCol : (item)=>item.tipo ===1 ? 'Amministratore' : (item.tipo ===2 ? 'Superutenti' : 'Base' )
        },
        {
          id : 3, 
          label: 'Email',
          nome: 'email',
        },
        {
          id : 4, 
          label: 'Stato',
          nome: 'status',
          altCol: (item)=> {

            return(
              <div className='d-flex justify-content-center' >
                  {item.status === 1 ?
                    <div 
                      className="border rounded-circle" 
                      style={{width: '25px', height: '25px', backgroundColor: 'green', marginLeft: '56px'}}
                    >
                      &nbsp;
                    </div>
                    :
                    <div 
                      className="border rounded-circle" 
                      style={{width: '25px', height: '25px', backgroundColor: 'red', marginLeft: '56px'}}
                    >
                      &nbsp;
                    </div>
                  }
              </div>
          )
          }
        }
  ]

    const impaginazione = async (impaginazione)=> {
        setPage(impaginazione.numeroPag)
        setPageSize(impaginazione.pageSize)
        if ( page !==impaginazione.numeroPag ||  pageSize !== impaginazione.pageSize) {
            await filtrare({pageSize:impaginazione.pageSize , page : impaginazione.numeroPag , ...inputs })
            setAzzeraVar(0)
        }
    }


      const Pulsanti = ({element}) => {
        return(
            <td className='p-0 m-0'>
                <button className='btn p-0 m-0 button_fix' onClick={()=> onSuccess(element.id ,element.username)} >
                    <FontAwesomeIcon className="btn"  style={{fontSize: '1.6rem' ,color: '#006FE6'}} icon={faPlus} />
                </button>
            </td>
        )
      }

    return(
        <InfoModal
            classi=' modal-xxl '
            title='Seleziona utente'
            text={
            <div className=''>
                <div className="border_filtra p-3 mx-4 px-4 my-4">
                    <div className='d-flex flex-wrap' >
                        <InputForm
                            label={'Username'}
                            name={'username'}
                            value={inputs?.username || ''}
                            onChange={handleChange}
                            errors={[]}
                            classi={' col-2 '}
                        />
                        <InputForm
                            label={'Email'}
                            name={'email'}
                            value={inputs?.email || ''}
                            onChange={handleChange}
                            errors={[]}
                            classi={' col-2 '}
                        />
                        <InputForm
                            label={'Numero di telefono'}
                            name={'tell'}
                            value={inputs?.tell || ''}
                            onChange={handleChange}
                            errors={[]}
                            classi={' col-2 '}
                        />
                    </div>
                    <PulsantiFiltra
                        azzera={azzeraFiltra}
                    />
                </div>
                <div className='mx-3 pe-1'>
                    <TabellaPaginazione
                        colonne={columns}
                        dati={dati}
                        impaginazione={impaginazione}
                        azzera={azzeraFiltra}
                        totalePag={pageTot}
                        Pulsanti={Pulsanti}
                        fu={fu}
                    />
                </div>
                
            </div>
            }
            canClose
            handleClose={onCancel}
        />
    )

    // return(
    //     <>
    //         <Modal.Dialog>
    //             <Modal.Header >
    //                 <Modal.Title>Seleziona utente</Modal.Title>
    //                 <Button className='btn' style={{fontSize: '2rem' }}  variant="btn btn_serch" onClick={handleClose}>
    //                     <FontAwesomeIcon icon={faTimes} />
    //                 </Button>
    //             </Modal.Header>

    //             <Modal.Body>
                    
    //                 <div className="container">
    //                     <form onSubmit={cerca}>
                            
    //                         <div className="mb-3">
    //                             <label htmlFor="username" className="form-label">Username</label>
    //                             <br/>
    //                             <input name="username" type="text" id="username" aria-describedby="emailHelp" onChange={handleChange} />
    //                         </div>

    //                         <div className="mb-3">
    //                             <label htmlFor="email" className="form-label">Email</label>
    //                             <br/>
    //                             <input name="email" type="text" id="email" aria-describedby="emailHelp" onChange={handleChange} />
    //                         </div>

    //                         <div className="mb-3">
    //                             <label htmlFor="tell" className="form-label">Numero di telefono</label>
    //                             <br/>
    //                             <input name="tell" type="text" id="tell" aria-describedby="emailHelp" onChange={handleChange} />
    //                         </div>
                            
                        
    //                         <button type="submit" className="btn btn_green">cerca</button>
    //                     </form>

    //                     <div className="container">
                            
    //                     {listReady && <table className="table table-striped">
    //                     <thead>
    //                         <tr>

    //                             <th scope="col">id</th>
    //                             <th scope="col">username</th>
    //                             <th scope="col">email</th>
    //                             <th scope="col-2">azione</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {dati.map((item, key) => {
    //                             return (
    //                                 <tr key={key}>
    //                                     <td>{item.id}</td>
    //                                     <td>{item.username}</td>
    //                                     <td>{item.email}</td>
    //                                     {/* <td><a className="btn btn-primary" ><FontAwesomeIcon icon={faInfo} /> </a></td> */}
    //                                     <td><a className="btn"  style={{fontSize: '1.6rem' ,color: '#006FE6'}}  onClick={() => onSuccess(item.id ,item.username)}><FontAwesomeIcon icon={faPlus} /></a></td>
    //                                 </tr>
    //                             )
    //                         })}
    //                     </tbody>
    //                 </table>
    //                 }
    //                     </div>
    //                 </div>
                    
                    
    //             </Modal.Body>

    //             <Modal.Footer>
    //             </Modal.Footer>
    //         </Modal.Dialog>
    //     </>
    // )
}