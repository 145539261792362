import { useState } from "react";
import SelectAnMultiple from "../../anagrafica/SelectAnMultiple";
import QuestionModal from "../../riutilizabili/QuestionModal";
import GeneraCampiText from "../../riutilizabili/forms/GeneraCampiText";
import anCliente_fetch from "../../fetch/anCliente_fetch";
import create_autocompleteWithService from "../../riutilizabili/autocomplite/create_autocompleteWithService";
import anFornitore_fetch from "../../fetch/anFornitore_fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import CreaAnagrafica from "../../anagrafica/CreaAnagrafica";
import ViewSingolaAnFornitore from "../../anagrafica/fornitore/ViewSingolaAnFornitore";
import InfoModal from "../../riutilizabili/InfoModal";
import classSezione from "../../fetch/classSezione";
import FormError from "../../riutilizabili/FormError";
import Switch from "../../riutilizabili/forms/Switch";
import SwitchOr from "../../riutilizabili/forms/SwitchOr";
import ViewAnagrafica from "../../anagrafica/ViewAnagrafica";


export default function AnagraficaFornitore({onChange , value , errors , modifica}) {
    const [viewSelezionaFornitore , setViewSelezionaFornitore]=useState(false)
    const [inputs, setInputs] = useState({ fornitore : 1 ,...value});
    const [viewAnCliente , setViewAnCliente]=useState(false)
    
    const [datiNewAn , setDatiNewAn]= useState({liberoProfessionista: 1, azienda: 0, privato: 0, entePubblico: 0})
    const [errorCrea , setErrorCrea]= useState({cap:"Campo obbligatorio",comuneResidenza:"Campo obbligatorio",indirizzo:"Campo obbligatorio",numeroCivico:"Campo obbligatorio",pIva:"Campo obbligatorio",provincia:"Campo obbligatorio",ragioneSociale:"Campo obbligatorio"})

    const onCloseSelezionaFornitore = async (stato) => {
        if (stato) {
            try {
                const an = await anFornitore_fetch.create(datiNewAn)
                onChangeFornitore({item :an.data , value :an.data.viewName})
                setViewSelezionaFornitore(false)
            } catch (error) {
                console.log(error , ' error');
                
                setErrorCrea(error.response.data)
            } 
            
        } else {
            setViewSelezionaFornitore(false) 
            setDatiNewAn({}) 
        }
    }

    const onChangeAn = (nome , valore)=> {
        onChange('an'+nome.charAt(0).toUpperCase()+nome.slice(1) , valore)
    }
    
    const selezionaUser = async (value)=> {
        if (!!inputs.fornitoreId && inputs.fornitoreId === value ) {
           let tmp = {...inputs};
           delete tmp.fornitoreId ;
           delete tmp['ag'+value] ;
           return setInputs(tmp)
        }
        onChange('fornitoreId', value)
        setInputs(values => ({ ...values, 'fornitoreId': value }))
        setInputs(values => ({ ...values, ['ag'+value]: true }))
        let datiFornitore = await anCliente_fetch.get({id : value});
        datiFornitore = datiFornitore.data[0]
        delete datiFornitore.id
        for (let index = 0; index < Object.keys(datiFornitore).length; index++) {
            const element = Object.keys(datiFornitore)[index];
            
            if (element.substring(0 ,2) !== 'an') {
                onChange('an'+element.charAt(0).toUpperCase()+element.slice(1), datiFornitore[element])
            }
        }
    }
    console.log(value , 'value');
    const campi = {
        text : [
            {
                label : 'Ragione sociale',
                name : 'ragioneSociale',
                lungCamp :'40%'
            },
            {
                label : 'nome',
                name : 'nome',
                lungCamp :'25%'
            },
            {
                label : 'cognome',
                name : 'cognome',
                lungCamp :'25%'
            },
            {
                name : 'indirizzo',
                label : 'indirizzo',
                lungCamp :'50%'
            },
            {
                label : 'comune',
                name : 'comune',
                lungCamp :'30%'
            },
            {
                label : 'provincia',
                name : 'provincia',
                lungCamp :'10%'
            },
 
            {
                label : 'sede legale',
                name : 'sedeLegale',
                lungCamp :'30%'
            },
            {
                label : 'codice fiscale',
                name : 'codiceFiscale',
                lungCamp :'40%'
            },
            {
                label : 'paetita iva',
                name : 'pIva',
                lungCamp :'40%'
            },
            {
                label : 'email',
                name : 'email',
                lungCamp :'40%'
            },

        ],
        number : [
            {
                label : 'numero civico',
                name : 'numeroCivico',
                lungCamp :'10%'
            },
            {
                label : 'cap',
                name : 'cap',
                lungCamp :'14%'
            },
            {
                label : 'numero telefono',
                name : 'numeroTelefono1',
                lungCamp :'20%'
            }
        ],
        date : [
            // {
            //     name : 'dataFattura',
            //     label : 'data fattura'
            // }
        ],
        'datetime-local' : [
            // {
            //     name : 'dataOraPartenza',
            //     label : 'data e ora partenza'
            // }
        ]
    }

    const pulisciCampiAn =(oggetto) => {
        //console.log(oggetto);
        let tmp = {};
        for (let index = 0; index < Object.keys(oggetto).length; index++) {
            const element = Object.keys(oggetto)[index];
            if (element.substring(0 ,2) !== 'an') {
                tmp[element]= oggetto[element]
            }else {
                let nome = element.substring(2 ,element.length);
                //console.log(nome);
                tmp[nome.charAt(0).toLocaleLowerCase()+nome.slice(1)]= oggetto[element]
            }
           // console.log(tmp , ' tmp');
        }
        return tmp
    }

   // console.log(inputs , ' inputs');

   const onChangeFornitore = ({item , value}) => {
    if (item) {
        console.log(item , value);
        onChange('anNominazioneFornitore' , value)
        onChange('fornitoreId', item.id)
        Object.keys(item).forEach(element => {
            // console.log('an'+element.charAt(0).toUpperCase() + element.slice(1));
            onChange('an'+element.charAt(0).toUpperCase() + element.slice(1) ,item[element] )
        });
    }
   }

   const AutocompleteFornitore = create_autocompleteWithService({
    freeinput: false,
    service: (myValue, serviceValues) => anFornitore_fetch.autocompliteAn({
        nome: myValue,
        ...serviceValues,
    }),
    itemCompare: (item, inputValue) => item.viewName?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
    itemToLi: item => item.viewName,
    itemToString: item => item.viewName,
    itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.viewName.toLowerCase() === value?.toLowerCase() ? curr : null), null),
    onChange: onChangeFornitore ,
    //redflag : redflagFonia,
    label: 'Seleziona Fornitore :*',
    placeholder: 'inserisci Fornitore',
    value: value?.anNominazioneFornitore,
});

const eliminaAn = () => {
    onChange('fornitoreId',null)
    onChange('anNominazioneFornitore',null)
}
const onChangeTipoAn = (tipoAn) => {
    switch (tipoAn) {
      case 'liberoProfessionista':
        setDatiNewAn(values => ({ ...values, liberoProfessionista: 1 }))
        setDatiNewAn(values => ({ ...values, azienda: 0 }))
        setDatiNewAn(values => ({ ...values, privato: 0 }))
        setDatiNewAn(values => ({ ...values, entePubblico: 0 }))
      break;
      case 'azienda':
        console.log(' values azienda');
        
        setDatiNewAn(values => ({ ...values, liberoProfessionista: 0 }))
        setDatiNewAn(values => ({ ...values, azienda: 1 }))
        setDatiNewAn(values => ({ ...values, privato: 0 }))
        setDatiNewAn(values => ({ ...values, entePubblico: 0 }))
      break;
      case 'privato':
        setDatiNewAn(values => ({ ...values, liberoProfessionista: 0 }))
        setDatiNewAn(values => ({ ...values, azienda: 0 }))
        setDatiNewAn(values => ({ ...values, privato: 1 }))
        setDatiNewAn(values => ({ ...values, entePubblico: 0 }))
      break;
      case 'entePubblico':
        setDatiNewAn(values => ({ ...values, liberoProfessionista: 0 }))
        setDatiNewAn(values => ({ ...values, azienda: 0 }))
        setDatiNewAn(values => ({ ...values, privato: 0 }))
        setDatiNewAn(values => ({ ...values, entePubblico: 1 }))
      break;
  }
}
    
    const onChangeMagazzino = async ({ value, item }) => {
        onChange('magazzinoNome', item?.nome)
        onChange('magazzinoId', item?.id)
    }

    const AutocompleteMagazzino = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classSezione.get({
            magazzinoPrincipale :1 ,
            nome: myValue,
            ...serviceValues,
            autoComplite: 1
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Seleziona magazzino',
        placeholder: 'inserisci magazzino',
        onChange: onChangeMagazzino,
        value: value?.magazzinoNome,
        disabled : modifica ,
        className : ' mb-0 '
    });

    
    const AutocompleteAn = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anCliente_fetch.autocompliteAn({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.viewName?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.viewName,
        itemToString: item => item.viewName,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.viewName.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        onChange: onChangeFornitore ,
        //redflag : redflagFonia,
        label: 'Seleziona cliente :*',
        placeholder: 'inserisci cliente',
        value: value?.anNominazioneFornitore,
    });
console.log(errorCrea , ' errorCreaerrorCrea');

    return(
        <div>
            {/* <button className="btn btn-primary ms-3" onClick={()=>setViewSelezionaFornitore(true)}>
                Seleziona anagrafica fornitore
            </button> */}
            <div className="border border-primary border-3 p-3 rounded-4 m-3" >
                <h3 className="text-primary" >Anagrafica fornitore </h3>
                <div className="w-100">
                    <SwitchOr
                        label={'Rientro'}
                        stato={value.mittenteEntita === 'c'}
                        number='mittenteEntita'
                        onChangeValue={(stato , nome)=> {onChange('mittenteEntita' , !!stato ? 'c' : 'f'); onChange('anNominazioneFornitore' ,''); onChange('fornitoreId' ,'')}}
                        classe={' ps-1 ms-4 py-3'}
                        width="7%"
               
                    />
                </div>
                <div className="w-75 d-flex align-items-center">
                    <div className="col-5">
                        {value.mittenteEntita !== 'c'?<AutocompleteFornitore /> : <AutocompleteAn />}
                        <FormError errors={errors} field='fornitoreId' classi='pt-0 mt-0 ms-4 ps-1 ' />
                    </div>
                    {!!value?.fornitoreId && 
                    <>
                        <button className="btn pb-5" onClick={()=>setViewAnCliente({ id : value.fornitoreId ,viewName : value.anNominazioneFornitore })} >
                            <FontAwesomeIcon icon={faEye} />
                        </button>
                        <button className="btn pb-5" onClick={eliminaAn}>
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </>
                    }
                   {value.mittenteEntita !== 'c'  && <button onClick={()=>setViewSelezionaFornitore(true)} className="border p-2 rounded-5 m-3 px-3 mb-5 bg-primary text-white">
                        <FontAwesomeIcon icon={faPlus} />
                    </button>}
                    <div className="col-4 offset-2">
                        <AutocompleteMagazzino />
                        <FormError errors={errors} field='magazzinoId' classi='pt-0 mt-0 ms-4 ps-1 ' />
                    </div>
                </div>

                {/* <GeneraCampiText
                    campi={campi}
                    inputs={pulisciCampiAn(value)}
                    onChange={onChangeAn}
                    errors={errors}
                /> */}
                {viewSelezionaFornitore && <QuestionModal
                    title='Crea fornitore'
                    text={
                        <CreaAnagrafica 
                            values={datiNewAn}
                            onChange={(nome , valore)=> setDatiNewAn(values => ({...values , [nome]: valore}))}
                            error={errorCrea}
                            onChangeError={(error)=> setErrorCrea(error)}
                            onChangeTipoAn={onChangeTipoAn}
                        />
                    }
                    yesDisabled={Object.keys(errorCrea).length > 0}
                    onClose={onCloseSelezionaFornitore}
                />}

            {!!viewAnCliente &&
            <InfoModal
                title={'Anagrafica cliente '+viewAnCliente.viewName}
                text={
                    <>
                        {value.mittenteEntita === 'c' ?
                        <ViewAnagrafica id={viewAnCliente.id} />
                        :
                        <ViewSingolaAnFornitore id={viewAnCliente.id} />}
                    </>
                }
                canClose
                handleClose={()=>setViewAnCliente(false)}
                classi={' modal-xxl '}
            />}
                
                {/* {viewSelezionaFornitore && <QuestionModal
                    title='Seleziona fornitore'
                    text={<SelectAnMultiple onChange={selezionaUser} values={inputs} />}
                    onClose={onCloseSelezionaFornitore}
                />} */}
            </div>
        </div>
    )
}