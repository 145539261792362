import anCliente_fetch from "../fetch/anCliente_fetch";
import anFornitore_fetch from "../fetch/anFornitore_fetch";
import bollaService from '../fetch/bolla_fetch';
import create_autocompleteWithService from "../riutilizabili/create_autocompleteWithService";
import GeneraCampiText from "../riutilizabili/forms/GeneraCampiText";
import InputForm from "../riutilizabili/forms/InputForm";



export default function FiltraBolla({
    inputs ,
    onChangeValue ,
    errors
}) {
    
    const FornitoreAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anFornitore_fetch.autocompliteAn({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.ragioneSociale ? item.ragioneSociale : item.nome+' '+item.cognome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Fornitore',
        placeholder: 'inserisci Fornitore',
        value : inputs?.nomeFornitore ,
        onChange : ({value, item}) => onChangeFornitore(item)
    });

    const onChangeFornitore = (item) => {
        if (item) {
            onChangeValue('fornitoreId' , item.id)
            onChangeValue('nomeFornitore' , item.ragioneSociale ? item.ragioneSociale : item.nome+' '+item.cognome) ; 
            onChangeValue('fornitoreId' , item.id)
        } else {
            onChangeValue('fornitoreId' , "" )
            onChangeValue('nomeFornitore' , "") ;
        }
    }

    const campi = {
        text : [
            {
                name : 'numero',
                label : 'Codice DDT',
                lungCamp : '26%'
            },
            // {
            //     name : 'agente',
            //     label : 'agente',
            //     lungCamp : '20%'
            // },
            // {
            //     name : 'codCliente',
            //     label : 'Codice cliente',
            //     lungCamp : '26%'
            // }
        ]
    }

    const assMetPag = ({item , value})=> {
        console.log(item , ' item');
        console.log(value , ' value');
        
        onChangeValue('tipoPagId', item?.id)
        onChangeValue('tipoPag', value)
    }

    const MetodiPagAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => bollaService.getMetodiPag({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome,
        itemToString: item => item.nome,
        value: inputs?.tipoPag,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Metodo di pagamento',
        placeholder: 'inserisci metodo di pagamento',
        onChange : assMetPag,
        className :'w-25'
    });
    
    
    return(
        <div>
            <div className="d-flex flex-wrap">
                <div className="w-25 m-3" >
                    <FornitoreAutocomplite />
                </div>

                <InputForm
                    label={' Numero DDT Cliente'}
                    type={'text'} 
                    placeholder={'Inserisci '+'Numero DDT Cliente'}
                    name={'numero'}
                    value={!!inputs?.numero ? inputs.numero :''}
                    onChange={(name , value) => onChangeValue(name , value)}
                    classi=' w-25 '
                    errors={{}}
                />
                <InputForm
                    label={'Seriale'}
                    type={'text'} 
                    placeholder={'Inserisci '+'seriale'}
                    name={'seriale'}
                    value={!!inputs?.seriale ? inputs.seriale :''}
                    onChange={(name , value) => onChangeValue(name , value)}
                    classi=' w-25 '
                    errors={{}}
                />

                {/* <div className="w-25 m-3" >
                    <MetodiPagAutocomplite />
                </div> */}
            </div>

                {/* <GeneraCampiText
                    campi={campi}
                    inputs={inputs}
                    onChange={onChangeValue}
                    errors={errors}
                /> */}

        </div>
    )
}