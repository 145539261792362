import React from "react";
import { format } from "date-fns";
import TableSemp from "../../riutilizabili/TableSemp";
import ImgTabella from "../../prodotti/ImgTabella";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faBoxesStacked } from "@fortawesome/free-solid-svg-icons";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import { daCentEu } from "../../riutilizabili/daEurCent";

export default function ReadCampiBolla({
    inputs,
    cliente = false,
    infoAddView = true
}) {


    const campiAnagrafica = [
            {
                label : 'Ragione sociale',
                name : 'ragioneSociale'
            },
            {
                label : 'nome',
                name : 'nome'
            },
            {
                label : 'cognome',
                name : 'cognome'
            },
            {
                name : 'indirizzo',
                label : 'indirizzo'
            },
            {
                label : 'comune',
                name : 'comune'
            },
            {
                label : 'provincia',
                name : 'provincia'
            },
 
            {
                label : 'sede legale',
                name : 'sedeLegale'
            },
            {
                label : 'codice fiscale',
                name : 'codiceFiscale'
            },
            {
                label : 'paetita iva',
                name : 'pIva'
            },
            {
                label : 'email',
                name : 'email'
            },
            {
                label : 'numero civico',
                name : 'numeroCivico'
            },
            {
                label : 'cap',
                name : 'cap'
            },
            {
                label : 'numero telefono',
                name : 'numeroTelefono1'
            }
    ]


    const convertiData = (data) => {
        //console.log(format(new Date(data) ,'dd-MM-yyyy hh:mm') , ' Date ' , data);
        //console.log(data ,' Date 2' , (data+'').indexOf('Z'));
        if ((data+'').indexOf('Z') !== -1) {
            return format(new Date(data) ,'dd-MM-yyyy hh:mm') 
        }
        return format(new Date(data) ,'dd-MM-yyyy') 
    }

    //
    console.log(inputs , 'inputs leggi');

    const colonne = [
        {
            label: 'Descrizione',
            nome: 'descrizione',
            altCol : (original)=> {
                console.log(original , ' original');
                
                return(
                    <span className="d-block p-0 m-0" style={{width: '14rem'}} > {original?.descrizione} </span>
                )
            }
        },
        {
            label: 'Codice interno',
            nome: 'rifInterno',
        },
        // {
        //     label: 'Sezione',
        //     nome: 'infoSezione',
        //     altCol : (original)=> {
        //         //console.log(original , 'original'); 
        //         return(
        //             <span style={{width: '12%'}} className="d-block p-0 m-0"  >
        //                 {original?.infoSezione?.nome}
        //             </span>
        //         )
        //     }
        // },
        {
            label: 'Produttore',
            nome: 'produttore',
        },
        {
            label: 'Codice a barre',
            nome: 'codBarre',
            altCol : (original)=> {
                //console.log(original , 'original'); 
                return(
                    <span className="d-block p-0 m-0" style={{width: '10%'}} >
                        {original?.codBarre}
                    </span>
                )
            }
        },
        {
            label: 'Codice articolo',
            nome: 'codiceArt',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className="d-block p-0 m-0" style={{width: '15%'}} >
                        {original?.codiceArt}
                    </span>
                )
            }
        },
        {
            label: 'IVA (%)',
            nome: 'iva',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">
                        {original?.iva}
                    </span>
                )
            }
        },
        {
            label: 'Prezzo di vendita',
            nome: 'prezzoUnitarioCent',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">

                        {(original?.prezzoUnitarioCent  / 100).toFixed(2).replace('.', ',')}
                    </span>
                )
            }
        },
        {
            label: 'Costo non ivato',
            nome: 'costo',
        },
        {
            label: 'Quantità',
            nome: 'quantita',

        },
        {
            label: 'Unità di misura',
            nome: 'infoUnitaMisura',
            altCol : (original) => {
                console.log(original , ' original');
                
                return original.infoUnitaMisura.nome
            }

        }

    ]

    return(
        <div>

            <div className="border border-primary border-3 p-3 rounded-4 m-3   d-flex flex-wrap">
                <div className="col-12 d-flex flex-wrap">
                <div className=" col-12">
                    <div className="col-12 d-flex">
          
                        <div className="col-6">
                            <span className="text-primary fs-4">
                                <FontAwesomeIcon icon={faUser} className="pe-2" />
                                Destinatario 
                            </span>
                            {!!cliente ?<>
                            <div className="d-flex flex-column" style={{fontSize : '1.1rem'}}>
                                <span><span className="fw-bold pe-2"> Ragione sociale :</span>  {!!inputs?.mittente?.ragioneSociale ? inputs?.mittente?.ragioneSociale +', ' : inputs?.mittente?.nome +' '+inputs?.mittente?.cognome +', ' }</span> 
                                <span><span className="fw-bold pe-2"> Indirizzo : </span>{inputs?.mittente?.indirizzo} </span>
                                <span><span className="fw-bold pe-2"> Numero civico :</span> {inputs?.mittente?.numeroCivico} </span>
                                <span><span className="fw-bold pe-2"> Comune : </span>{inputs?.mittente?.comuneResidenza} </span>
                                <span><span className="fw-bold pe-2"> Provincia : </span>{inputs?.mittente?.provincia} </span>
                                <span><span className="fw-bold pe-2"> CAP : </span>{inputs?.mittente?.cap} </span>
                                <span><span className="fw-bold pe-2">P.IVA :</span> {inputs?.mittente?.pIva} </span>
                            </div>
                            </>
                            :
                            <>
                              <div className="d-flex flex-column" style={{fontSize : '1.1rem'}}>
                                <span><span className="fw-bold pe-2"> Ragione sociale :</span>  
                                {!!inputs?.mittente?.ragioneSociale ? inputs?.mittente?.ragioneSociale +', ' : inputs?.mittente?.nome +' '+inputs?.mittente?.cognome +', ' }
                                </span> 
                                <span><span className="fw-bold pe-2">Indirizzo :</span> {inputs?.mittente?.indirizzo} </span>
                                <span><span className="fw-bold pe-2">Numero civico :</span> {inputs?.mittente?.numeroCivico} </span>
                                <span><span className="fw-bold pe-2">Comune :</span> {inputs?.mittente?.comuneResidenza} </span>
                                <span><span className="fw-bold pe-2">Provincia :</span> {inputs?.mittente?.provincia} </span>
                                <span><span className="fw-bold pe-2">CAP :</span> {inputs?.mittente?.cap} </span>
                            </div>
                            </>}

                        </div>
                        <div className="col-6 " style={{fontSize : '1.1rem'}}>
                            {!!inputs?.indirizzoDestinazioneDiverso ?
                            <div>
                                <span className="text-primary fs-4">
                                    <FontAwesomeIcon icon={faUser} className="pe-2" />
                                    Destinazione Merce 
                                </span>
                                
                                {!!cliente ?<>
                                    <div className="d-flex flex-column">
                                        <span><span className="fw-bold pe-2">Ragione sociale :</span>  {inputs?.ragioneSocialeDestinatario }</span>
                                        <span><span className="fw-bold pe-2">Indirizzo :</span> {inputs?.indirizzoDestinazioneDiverso} </span>
                                        <span><span className="fw-bold pe-2">Numero civico :</span> {inputs?.civicoDestinatario} </span>
                                        <span><span className="fw-bold pe-2">Comune :</span> {inputs?.comuneDestinatario} </span>
                                        <span><span className="fw-bold pe-2">Provincia :</span> {inputs?.provianciaDestinatario} </span>
                                        <span><span className="fw-bold pe-2">CAP :</span> {inputs?.capDestinatario} </span>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="flex flex-column">
                                        <span>
                                            {!!inputs?.mittente?.ragioneSociale ? inputs?.mittente?.ragioneSociale +', ' : inputs?.mittente?.nome +' '+inputs?.mittente?.cognome +', ' }
                                        </span>
                                        <span>Indirizzo : {inputs?.mittente?.indirizzo} </span>
                                        <span>Numero civico : {inputs?.mittente?.numeroCivico} </span>
                                        <span>Comune : {inputs?.mittente?.comuneResidenza} </span>
                                        <span>Provincia : {inputs?.mittente?.provincia} </span>
                                        <span>Cap : {inputs?.mittente?.cap} </span>
                                    </div>
                                </>}
                            </div>
                            // : <p className="text-primary fs-4">Destinazione e destinatario combaciano</p>
                            :
                            <div>
                                 <span className="text-primary fs-4">
                                    <FontAwesomeIcon icon={faUser} className="pe-2" />
                                    Destinazione Merce 
                                </span>
                                <div className="d-flex flex-column">
                                        <span><span className="fw-bold pe-2">Idem</span></span>
                                    </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                    {/* {Object.keys(campiDettagliBolla).map((item, key)=> {
                        return(
                            <>
                                {campiDettagliBolla[item].map((item2 , key2)=> {
                                    const valore = inputs[item2.name] ? (item === 'date' ? convertiData(inputs[item2.name]) : inputs[item2.name]) : ''
                                    return(
                                        <span className="col-6" key={key2} >
                                            {item2.label + ' : '+ valore}
                                        </span>
                                    )
                                })}
                            </>
                        )
                    })} */}
                </div>
            </div>

            <div className="border border-primary border-3 p-3 rounded-4 m-3 d-flex flex-wrap">
                <div className="col-12" >
                    <h3 className="text-primary col-12" >
                        <FontAwesomeIcon  className="me-3 text-primary" icon={faBoxesStacked} />
                        Dettagli DDT 
                    </h3>
                    <span><span className="fw-bold pe-2"> Spese di spedizione : </span>{daCentEu(inputs?.speseSpedizioneCent)+ ' €' } </span>
        
                </div>
            </div>

            
            <div className="border border-primary border-3 p-3 rounded-4 m-3 d-flex flex-wrap">
                <div className="col-12" >
                    <h3 className="text-primary col-12" >
                        <FontAwesomeIcon  className="me-3 text-primary" icon={faBoxesStacked} />
                        Dettagli articoli DDT 
                    </h3>
                    <span style={{fontSize: '0.8rem'}} className="p-auto col-12" >
                        <TableSemp
                            colonne={colonne}
                            dati={inputs.prods}
                            infoAdd={
                                <>
                                    {!!infoAddView &&<div className="col-12 my-5 d-flex justify-content-end" >
                                        <div className=" btn btn-primary h-100 m-2 ms-4">Imponibile : € {inputs?.imponibileCent && (inputs?.imponibileCent/100).toFixed(2).replace('.', ',')} </div>
                                        <div className="col-2 btn btn-primary h-100 m-2 ms-4">Imposta : € {inputs?.impostaCent && (inputs?.impostaCent/100).toFixed(2).replace('.', ',')} </div> 
                                        <div className="col-2 btn btn-primary h-100 m-2 ms-4">Importo : € {!!inputs?.totFatturaCent &&(inputs?.totFatturaCent/100).toFixed(2).replace('.', ',')} </div> 
                                    </div>}
                                </>
                            }
                            secondaRiga={ (item) =>{
                                console.log(item , ' item secondaRiga');
                                
                                return (
                                    <>
                                        {item.ProdSerialis.length > 0 &&  <>
                                            Seriali :
                                            {item.ProdSerialis.map(element => {
                                                console.log(element , ' element ProdSerialis');
                                                
                                                return (
                                                    <>
                                                    {
                                                        element.seriale 
                                                        +((!!element.provisioning && !!element.seriale) ? ' , ' : ''  )
                                                        + (!!element.provisioning ? element.provisioning : ' ' )
                                                        + ' ; '
                                                    }
                                                    </>
                                                )
                                            })}
                                        </>}
                                    </>
                                )
                            }}
                        />
                    </span>
                </div>
            </div>

            {/* <div className="border border-primary border-3 p-3 rounded-4 m-3 d-flex flex-wrap">
                <div className="w-25"> <FontAwesomeIcon className="h-75 m-5 text-primary" icon={faMoneyCheckDollar} /> </div>
                <div className="w-75 d-flex flex-wrap">
                    <h3 className="text-primary col-10" >Dettagli pagamento bolla </h3>
                    <span className="col-6">
                        Banca di appoggio : {inputs.bancaAppoggio ? inputs.bancaAppoggio : ''}
                    </span>

                    <span className="col-6">
                        Totale fattura : {inputs.totFatturaCent ? (parseInt(inputs.totFatturaCent)/100) : ''}
                    </span>

                    <span className="col-6">
                        Totale pagamento fattura : {inputs.totPagCent ? (parseInt(inputs.totPagCent)/100) : ''}
                    </span>

                    <span className="col-6">
                        Sconto in percentuale fattura : {inputs.scontoPerc ? inputs.scontoPerc : ''}
                    </span>
                    <span className="col-6">&nbsp;</span>
                    <span className="col-6">&nbsp;</span>
                    <span className="col-6">&nbsp;</span>
                    <span className="col-6">&nbsp;</span>
                </div>

            </div> */}

            
        </div>

    )
}