import http from "./controllerFetch";

class prodottiService {
    getAll() {
        return http.get('Prodotto/readProdotto');
    }
    getCatAll() {
        return http.get('Categoria/readCategoria');
    }

    create({imgProd , datiCrea ,docProd}) {
        let dati = {
            ...datiCrea,
            img : imgProd.map(img => img.id) ,
            doc : docProd.map(doc => doc.id) 
        }
        return http.post('Prodotto/creaProdotto',  dati ,
        {
            headers: {
                'content-type': 'multipart/form-data',
                //'Authorization' : 'Bearer ' + localStorage.getItem('accessToken')
            }
        });
    }

    update(imgProd ,datiModifica ,docProd) {
        let dati = {
            ...datiModifica,
            img : imgProd.map(img => img.id) ,
            doc : docProd.map(doc => doc.id) 
        }
        return http.put('Prodotto/updateProdotto', dati);
    }

    get( params = {}) {
        return http.get('Prodotto/readProdotto', { params });
    }

    createAssPosMg(data) {
        return http.post('AssPosMg/creaAssPosMg', data);
    }

    updateAssPosMg(data) {
        return http.put('AssPosMg/updateAssPosMg', data);
    }

    getAssPosMg( params = {}) {
        return http.get('AssPosMg/readAssPosMg', { params });
    }

}

export default new prodottiService();