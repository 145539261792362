import impostazioni from "../../cofig.js";
import http from "./controllerFetch";
const api_url = impostazioni.api_url;
const FileSaver = require('file-saver');
const { format } = require('date-fns');

let data =  format(new Date(), "yyyyMMdd");
class file {
    scaricaAllegato (param) {
        console.log('ci sono');
        return http.get(api_url + 'bollaUscita/scaricaPdfBolla', {
            params: param,
            responseType: 'arraybuffer',
            headers: {
              'Accept': 'application/pdf'
            }
        })
        
    }
    scaricaAllegatoNoPrezzi (param) {
        console.log('ci sono');
        return http.get(api_url + 'bollaUscita/scaricaPdfBollaNoPrezzi', {
            params: param,
            responseType: 'arraybuffer',
            headers: {
              'Accept': 'application/pdf'
            }
        })
    }
    scaricaInventario (param , nomeMag) {
        console.log('ci sono');
        http.get(api_url + 'Sezioni/inventarioMg', {
            params: param,
            responseType: 'arraybuffer',
            headers: {
              'Accept': 'application/pdf'
            }
        })
        .then(response => {
            console.log(response);
            // response.data is an empty object

            const blob = new Blob([response.data], {
              type: 'application/pdf',
            });
            FileSaver.saveAs(blob, data+'_inventario_'+nomeMag+'.pdf' );
        });
    }
    inventarioMgExcel (param , nomeMag) {
        console.log('ci sono');
        http.get(api_url + 'Sezioni/inventarioMgExcel', {
            params: param,
            responseType: 'arraybuffer',
            headers: {
              'Accept': 'application/pdf'
            }
        })
        .then(response => {
            console.log(response);
            // response.data is an empty object

            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            FileSaver.saveAs(blob, data+'_inventario_'+nomeMag+'.xlsx' );
        });
    }
}

export default new file();