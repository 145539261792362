import React from 'react';

function FormError({ errors, field , classi}) {
    //console.log(errors , errors[field] , ' errors && errors[field]');
    return (
        <>
            {errors ? 
            (errors[field] ? (
                <small className={"text-danger font-weight-bold " + classi} dangerouslySetInnerHTML={{ __html: errors[field] }}></small>
            ) :
            <small className={"text-danger font-weight-bold " + classi}>&nbsp;</small>)
            : <small className={"text-danger font-weight-bold " + classi}>&nbsp;</small>
            }
        </>
    )
}

export default FormError;