import { useEffect, useState } from "react"
import caricaFile_fetch from "../fetch/caricaFile_fetch"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons"
const FileSaver = require('file-saver');


export default function ViewDocProd({
    prodottoId ,
    visualizza = false
}) {
    const [prodDocs , setProdDocs ] = useState([])

    useEffect(()=> {
        readDoc()
    },[])
    
    const readDoc = async () => {
        const docs = await caricaFile_fetch.readDocProd(prodottoId)
        setProdDocs(docs.data)
    }

    const scarica = async (prod) => {
        const file = await caricaFile_fetch.scaricaDocumento(prod.id)
        let base64File = file.data.scr
        const byteCharacters = atob(base64File);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {
            type: prod.tipo,
          });
        FileSaver.saveAs(blob, prod.nome );
    }

    const deleteDoc = async (id) => {
        await caricaFile_fetch.eliminaDoc(id)
        await readDoc()
    }

    return( 
        <div className="mt-5" >
            {prodDocs.map(item => {
                return(
                    <div className=" d-inline-block position-relative">
                        <button onClick={()=>scarica(item)} className=" btn d-inline-flex flex-column align-items-center">
                            <FontAwesomeIcon className="fs-1" icon={faFile} />
                            <span className="fa-2xs mt-4">{item.nome}</span>
                        </button>

                        {!visualizza &&<button className="btn text-danger position-absolute top-0 end-0 p-0 m-0" onClick={() => deleteDoc(item.id)} >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>}
                    </div>
                )
            })}
        </div>
    )
}