import React from "react"
import InputForm from "../riutilizabili/forms/InputForm";
import sezioneService from '../fetch/classSezione'
import create_autocompleteWithService from "../riutilizabili/autocomplite/create_autocompleteWithService";
import TextAreaForm from "../riutilizabili/forms/TextAreaForm";
import Switch from "../riutilizabili/forms/Switch";
import AutocompliteCopertura from "../riutilizabili/autocomplite/autocompliteCopertura";
import SwitchOr from "../riutilizabili/forms/SwitchOr";
import classZone_fetch from "../fetch/classZone_fetch";
import FormError from "../riutilizabili/FormError";
import RadiusForm from "../riutilizabili/forms/RadiusForm";
import create_autocompleteWithServiceRow from "../riutilizabili/autocomplite/create_autocompleteWithServiceRow";

export default function CampiSezioni({classiDiv , values , onChange , errors ,edit}) {

    console.log(values , ' values');

    const SezioneAutocomplite = create_autocompleteWithServiceRow({
        freeinput: false,
        service: (myValue, serviceValues) => sezioneService.getAll({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona magazzino o sezione di riferimento' ,
        value: values.nomeSezionePadre ,
        //onChange : ({value, item}) => //console.log({value, item}, ' gino') ,
        onChange : ({value, item}) =>{onChange('padreId' ,  item?.id ? item.id : ''); onChange('nomeSezionePadre' , item?.nome ? item.nome : '')} ,
        placeholder: '',
        redflag : !!errors?.padreId,
        labelprops : 'className:" col-4"'
    });

    const UnMisuraAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => sezioneService.getUnMisura({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona unità di misura della capienza' ,
        value: values.nomeUnitaMisura ,
        onChange : ({value, item}) => {onChange('umId' , item?.id ? item.id : ''); onChange('nomeUnitaMisura' , item?.nome ? item.nome : '')},
        placeholder: 'inserisci unità di misura della capienza',
        redflag : !!errors?.umId,
        className:' col-4 '
    });

    
    const onChangeProdottiProvincia = ({value , item}) => {
        
        if (!!item) {
            onChange('provincia', item.sigla)
            onChange('comune', '')
        }else{
            onChange('provincia', '')
            onChange('comune', '')
            onChange('indirizzo', '')
            onChange('numeroCivico', '')
            onChange('cap', '')
   
        }
        
    }

    const onChangeProdottiComune = ({value , item}) => {
        if (!!item){
            onChange('comune', item.nome)
        }else{
            onChange('comune', '')
            onChange('indirizzo', '')
            onChange('numeroCivico', '')
            onChange('cap', '')
        }
        
    }

    const AutocompleteProdottiComune = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classZone_fetch.comuneLike({
            prov :values?.provincia ,
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => {
            return item?.nome?.toLowerCase().indexOf((inputValue || '').toLowerCase()) !== -1
        },
        itemToLi: item => item?.nome,
        itemToString: item => item?.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        onChange: onChangeProdottiComune,
        redflag : errors?.comune,
        label: 'Seleziona comune :' ,
        placeholder: 'Inserisci comune',
        value: values?.comune,
        //className: visualizza ? ' border-0 bg-white ' :' '
    });

    const AutocompleteProdottiProvincia = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classZone_fetch.provinciaLike({
            sigla: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => {
            return item?.nome?.toLowerCase().indexOf((inputValue || '').toLowerCase()) !== -1
        },
        itemToLi: item => item?.nome,
        itemToString: item => item?.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.toLowerCase() === value.toLowerCase() ? curr : null), null),
        onChange: onChangeProdottiProvincia,
        redflag : errors?.provincia ,
        label: 'Seleziona provincia :' ,
        placeholder: 'Inserisci provincia',
        value: values?.provincia,
        className: ' mb-0 '
    });

    const onChangeTipoMagazzino = (name , value) => {
        console.log(value , ' value');
        switch (value) {
            case '0':
                onChange('opera', '0')
                onChange('mobile', '0')
                onChange('bts', '0')
                break;
            case '1':
                onChange('opera', '1')
                onChange('mobile', '0')
                onChange('bts', '0')
                break;
            case '2':
                onChange('bts', '0')
                onChange('mobile', '1')
                onChange('opera', '0')
                break;
            case '3':
                onChange('bts', '1')
                onChange('mobile', '0')
                onChange('opera', '0')
                break;
        }
        
         onChange('tipoMag', value)
    }

    return(
        <div 
       // className='d-flex  justify-content-around flex-wrap ' 
        >
            <div className={classiDiv}>
                <div className="w-100 d-flex align-items-center">
                    {/* <SwitchOr
                        label={'Sezione di un magazzino esistente'}
                        number={'sezione'}
                        stato={values?.sezione}
                        onChangeValue={(stato, nome) => onChange(nome , stato)}
                        classe='d-flex p-0 m-0 ms-3'
                        width="27%"
                    /> */}
                    <RadiusForm
                        label={'Tipo :'}
                        arrayElement={[
                            {label: 'Magazzino' ,valore: 0},
                            {label: 'Sezione' ,valore: 1}
                        ]}
                        name='sezione'
                        value={values?.sezione || 0}
                        onChange={onChange}
                        classi=' ps-1 col-3 me-0'
                        errors={errors}
                    />
                     {!!values?.sezione && <div className=" col-5 d-flex align-items-center">
                        <SezioneAutocomplite />
                        <span className="ps-5" >
                            <FormError errors={errors} field={'padreId'} />
                        </span>
                    </div>}
                    
                </div>
                {!values?.sezione && <RadiusForm
                        label={'Tipo magazzino :'}
                        arrayElement={[
                            {label: <span>Magazzino standard</span> ,valore: '0'},
                            {label: <span>Magazzino in opera <span className="fst-italic" >( Se è un cantiere )</span></span> ,valore: '1'},
                            {label: <span>Mobile <span className="fst-italic" >( In carico a un fornitore )</span></span> ,valore: '2'},
                            {label: <span>POP/BTS <span className="fst-italic" >( Installazione )</span></span> ,valore: '3'},
                        ]}
                        name='tipoMag'
                        value={values?.tipoMag || '0'}
                        onChange={onChangeTipoMagazzino}
                        classi=' ps-1 col-12 '
                        errors={errors}
                    />}
                <InputForm
                    label='Nome' 
                    type='text' 
                    placeholder='Inserisci nome'
                    name='nome'
                    value={values.nome ? values.nome : ''}
                    onChange={(name , value) => onChange(name , value)}
                    classi='w-25'
                    errors={errors}
                />
                {!values?.sezione && <InputForm
                    label='codice identificativo' 
                    type='text' 
                    placeholder='Inserisci codice identificativo'
                    name='codice'
                    value={values.codice ? values.codice : ''}
                    onChange={(name , value) => onChange(name , value.toUpperCase())}
                    classi='w-25'
                    errors={errors}
                    maxLenght={10}
                    disabled={edit}
                />}
                
                <div className="col-12">
                    <TextAreaForm
                        label='Descrizione' 
                        name='descrizione'
                        value={values.descrizione ? values.descrizione : ''}
                        onChange={(name , value) => onChange(name , value)}
                        classi='col-11'
                        errors={errors}
                    />
                </div>
                <div className="d-flex flex-wrap align-items-center" >
                <InputForm
                    label='Capienza' 
                    type='number' 
                    placeholder='Inserisci capienza'
                    name='capienza'
                    value={values.capienza ? values.capienza : ''}
                    onChange={(name , value) => onChange(name , value)}
                    classi='col-2'
                    errors={errors}
                />
                <div className="col-6 mx-2" >
                    <UnMisuraAutocomplite />
                </div>       
                <InputForm
                    label='RFID' 
                    type='text' 
                    placeholder='Inserisci RFID'
                    name='rfid'
                    value={values.rfid ? values.rfid : ''}
                    onChange={(name , value) => onChange(name , value)}
                    classi='col-3'
                    errors={errors}
                />
                </div>
                <div className="d-flex flex-wrap align-items-center" >

                    {values?.sezione ? <div className="ms-5 w-25">
                        
                    </div>
                    : 
                    <div className="mt-4 ms-3">
                        <h3>Ubicazione Magazzino/Impianto</h3>
                        <div className="d-flex flex-wrap">
                            <div className='col-3 me-3 position-relative mt-3' >
                                {/* <InputForm  
                                    label='Provincia'
                                    type = 'text' 
                                    placeholder = 'Inserisci provincia'
                                    name = 'provincia'
                                    value = {values?.provincia}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    maxLenght = {60}
                                /> */}
                                <AutocompleteProdottiProvincia />
                                <span className="position-absolute" style={{top: '68%'}} >
                                    <FormError errors={errors} field={'provincia'} />
                                </span>
                            </div>
                            <div className='col-3 me-3 position-relative mt-3'>
                                {/* <InputForm  
                                    label='comune'
                                    type = 'text' 
                                    placeholder = 'Inserisci comune'
                                    name = 'comune'
                                    value = {values?.comune}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    maxLenght = {60}
                                /> */}
                                <AutocompleteProdottiComune />
                                <span className="position-absolute mt-3" style={{top: '68%'}} >
                                    <FormError errors={errors} field={'comune'} />
                                </span>
                            </div>
                            <div className='col-5 mt-3'>
                                <InputForm  
                                    label='indirizzo'
                                    type = 'text' 
                                    placeholder = 'Inserisci indirizzo'
                                    name = 'indirizzo'
                                    value = {values?.indirizzo}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    classi={' mt-0 '}
                                />
                            </div>
                            <div className='col-2 ms-0 ps-0'>
                                <InputForm  
                                    label='civico'
                                    type = 'text' 
                                    placeholder = 'Inserisci civico'
                                    name = 'numeroCivico'
                                    value = {values?.numeroCivico}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    maxLenght = {7}
                                    classi={' ms-0 ps-0'}
                                />
                            </div>
                            <div className='col-2'>
                                <InputForm  
                                    label='cap'
                                    type = 'text' 
                                    placeholder = 'Inserisci cap'
                                    name = 'cap'
                                    value = {values?.cap}  
                                    onChange = {(name , value)=> onChange(name , value)} 
                                    errors = {{...errors }}
                                    maxLenght = {5}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        </div>
    )
}