import React , { useEffect, useState } from "react"
import SelezionaProdSezione from "../../prodotti/SelezionaProdSezione.js"
import QuestionModal from "../../riutilizabili/QuestionModal"
import funzioniRicorrenti from "../../riutilizabili/funzioniRiccorrenti"
import Tabella from "../../riutilizabili/Tabella"
import InputForm from "../../riutilizabili/forms/InputForm"
import FormError from "../../riutilizabili/FormError"
import ImgTabella from "../../prodotti/ImgTabella"
import { daCentEu, daEuCent } from "../../riutilizabili/daEurCent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faList, faTrash } from "@fortawesome/free-solid-svg-icons"
import TableSemp from "../../riutilizabili/TableSemp"
import parseInputFloatValue from "../../riutilizabili/forms/parseInputFloatValue.js"
import classSezione from "../../fetch/classSezione.js"
import create_autocompleteWithService from "../../riutilizabili/create_autocompleteWithService.js"
import SelectForm from "../../riutilizabili/forms/SelectForm.js"
import ViewProdPopUp from "../../prodotti/ViewProdPopUp.js"
import InfoModal from "../../riutilizabili/InfoModal.js"
import SelezionaProdSituazioneDisp from "../../ProdSituazione/SelezionaProdSituazioneDisp.js"
import CodiciForms from "../sezioni/CodiciForms.js"


export default function ProdottiBollaUscita({
    onChange,
    inputs,
    errors
}) {
    const [arrayProds , setArrayProds] = useState([])
    const [viewSelectProds , setViewSelectProds] = useState(false)
    const [selectProds , setSelectProds] = useState([])
    const [arrayUnitaMisura , setArrayUnitaMisura] = useState([])
    const [mostraSeriali , setMostraSeriali] = useState(false) ;

    const [countRigaCodici , setCountRigaCodici] = useState({})
    const [codici , setCodici] = useState({})

    useEffect(()=> {
        editSerialiProdUscta()
    },[])

    useEffect(()=> {
        if (inputs?.prods) {
            console.log(inputs.prods , ' inputs.prods');
            
            setSelectProds(inputs.prods)
        }
    },[inputs,selectProds])

    useEffect(()=>{
        calcTotFattura()
        getUnMisura()
        //console.log('entro b');
    },[inputs?.prods])

    console.log(inputs , ' inputs DettagliPagamento');

    const editSerialiProdUscta = async () => {
        const prods = inputs?.prods || []
        let codice = {}
        let righe = {}

        prods.forEach(element => {
            element.ProdSerialis.forEach((cod, key) => {
                if (cod?.seriale) {
                    codice[cod.prodSezioneId + 'S' + parseInt(key)] = cod.seriale;
                }
                if (cod?.provisioning) {
                    codice[cod.prodSezioneId + 'P' + parseInt(key)] = cod.provisioning;
                }
                if (!righe[cod.prodSezioneId]) {
                    righe[cod.prodSezioneId] = {}
                }
                righe[cod.prodSezioneId][parseInt(key)] =1
                
            });
           
        });
        setCountRigaCodici(righe)
        setCodici(codice)
    }

    const calcTotFattura = async () => {
        let totale = 0;
        let totaleIvato = 0;
        let totaleIva = 0;
        if (!!inputs?.prods) {
            for (let index = 0; index < inputs.prods.length; index++) {
                const element = inputs.prods[index];
                totale += element.prezzoUnitarioCent*element.quantita;

                let prezzoUnitarioMil = element.prezzoUnitarioCent*10
                console.log(prezzoUnitarioMil , ' prezzoUnitarioMil');
                let iva = ((prezzoUnitarioMil/100)*element.iva)*element.quantita
                console.log('totale iva : ', iva);
                totaleIva += iva
                let prezzoUnitarioMilIvato = (prezzoUnitarioMil*element.quantita) +iva
                console.log(prezzoUnitarioMilIvato , ' prezzoUnitarioMilIvato');
                totaleIvato += prezzoUnitarioMilIvato
                //console.log(element.prezzoUnitarioCent);
            }
        }
        //onChange('totFatturaCent', totale)
        const totFatturaCent = funzioniRicorrenti.arrotondaNumero(totaleIvato/1000 , 5)
        const totPagCent = funzioniRicorrenti.arrotondaNumero(totaleIvato/1000 , 5)
        const imponibileCent = funzioniRicorrenti.arrotondaNumero(totale/100 , 5)
        const impostaCent = funzioniRicorrenti.arrotondaNumero(totaleIva/1000 , 5) 
        onChange('totFatturaCent', totFatturaCent+'' )
        onChange('totPagCent',totPagCent+'' )
        onChange('imponibileCent',imponibileCent+'' )
        onChange('impostaCent', impostaCent+'' )
        
        console.log(totaleIvato/1000 , totaleIva/1000 ,totale);

    }


    const cambiaPrezzo = (prodotto , valoreNew) => {
        const posizioneProd = selectProds.indexOf(prodotto);
        //console.log(posizioneProd , ' posizioneProd');
        let tpm = [...selectProds]
        tpm[posizioneProd].prezzoUnitarioCent = daEuCent(valoreNew)+'' ;
        tpm[posizioneProd].prezzoUnitario = valoreNew+'' ;
        setSelectProds(tpm)
        onChange('prods',tpm)
    }

    const cambiaQt = (prodotto , valoreNew ) => {
        //console.log(prodotto , valoreNew);
        if (valoreNew) {
           // console.log(prodotto , valoreNew  , ' prodotto , valoreNew , quantitaResidua');
            const posizioneProd = selectProds.indexOf(prodotto);
          //  console.log(posizioneProd , ' posizioneProd');
            let tpm = [...selectProds]
            // tpm[posizioneProd].quantita = quantitaResidua ;
          //  console.log(tpm ,' tpm[posizioneProd].quantita');
            tpm[posizioneProd].quantita = parseInt(valoreNew) ;
            setSelectProds(tpm)
            onChange('prods',tpm)
            
        }
        
    }

    const selezionaProdotto = (value)=>{
        console.log(value , ' selezionaProdotto value' ,selectProds ,selectProds.map(prod =>prod.pid) ,value.id || value.pid);
        
        const idProd = selectProds.map(prod =>prod.pid)
        const posizioneSeEsiste = idProd.indexOf(value.id || value.pid)
        if (posizioneSeEsiste === -1) {
            let tpm = {...value}
            tpm.quantita = 1
            tpm.pid = tpm.id
            delete tpm.id
            tpm.nomeUnitaMisura = value?.Prodotti?.infoUnitaMisura?.nome
            setSelectProds(values => ([...values, tpm]));
            onChange('prods',[...selectProds ,tpm])
        }else {
            let tpm = [...selectProds]
            tpm.splice(posizioneSeEsiste, 1)
            setSelectProds(tpm)
            onChange('prods', tpm)
        }
    }

    const cambiaRicarico = ( prodotto , valore) =>{
        let valoreNew = parseInputFloatValue({value : valore})
        console.log(valoreNew , 'valoreNew');
        if (!!valoreNew) {
            let tpm = [...selectProds]
            console.log(tpm , ' tpm')
            const posizioneProd = tpm.indexOf(prodotto);
            let info = !!tpm[posizioneProd]?.infoProd ? ' infoProd' : 'Prodotti'
            console.log(tpm[posizioneProd] , 'tpm[posizioneProd] ' , !!tpm[posizioneProd]?.infoProd);
            
            tpm[posizioneProd].ricarico = valoreNew ;
            const prezzoConRicarico =tpm[posizioneProd][info].costoCent +((tpm[posizioneProd][info].costoCent * parseFloat(valoreNew))/100)
            //const prezzoConRicarico =tpm[posizioneProd].costoCent +((tpm[posizioneProd].costoCent * parseFloat(valoreNew))/100)
            tpm[posizioneProd].prezzoUnitarioCent = prezzoConRicarico * parseInt(tpm[posizioneProd].quantita)
            tpm[posizioneProd].prezzoUnitario = daCentEu(prezzoConRicarico * parseInt(tpm[posizioneProd].quantita))
            setSelectProds(values => ([...values, tpm]));
            onChange('prods', tpm)
        } else {
            let tpm = []
            console.log(selectProds, 'selectProds');

            selectProds.forEach(element => {
                let temp = {...element} ;
                console.log(temp , ' temp');
                const ricarico = parseFloat(!!temp?.ricarico ? temp?.ricarico : (!!inputs?.anRicarico ?  parseFloat(inputs?.anRicarico) : 0))
                console.log(ricarico , ' ricaricoricarico' , temp.prezzoUnitarioCent);
                 
                const prezzoConRicarico = (temp.prezzoUnitarioCent || temp.prezzoUnitarioCent === 0 ) ? (temp.prezzoUnitarioCent +((temp.prezzoUnitarioCent * ricarico)/100)) : (temp.Prodotti.infoProd.costoCent +((temp.Prodotti.infoProd.costoCent * ricarico)/100))
                temp.prezzoUnitarioCent = prezzoConRicarico * parseInt(temp.quantita)
                temp.prezzoUnitario = daCentEu(prezzoConRicarico * parseInt(temp.quantita))
                if (temp.id === prodotto.id ) {
                    temp.ricarico = valoreNew;
                }
                tpm.push(temp)
            });
            // const posizioneProd = tpm.indexOf(prodotto);
            // console.log(tpm[posizioneProd] , posizioneProd , ' tpm[posizioneProd]');
            // tpm[posizioneProd].ricarico = valoreNew;
            //setSelectProds(values => ([...values, tpm]));
            onChange('prods', tpm)
        }

    }

    const cambiaPrezzoProd = (prodotto , valoreNew) => {
        console.log(valoreNew );
        
            let tpm = [...selectProds];
            const posizioneProd = tpm.indexOf(prodotto);
            tpm[posizioneProd].prezzoUnitario = parseInputFloatValue({value : valoreNew});
            if (valoreNew === '') {
                tpm[posizioneProd].prezzoUnitarioCent = ''
            } else {
                tpm[posizioneProd].prezzoUnitarioCent = daEuCent(parseInputFloatValue({value : valoreNew})) ===0 ? '0' : daEuCent(parseInputFloatValue({value : valoreNew}));     
            }
            onChange('prods', tpm)
        
    }

    const cambiaIvaProd = (prodotto , valoreNew) => {
        console.log(valoreNew );
        
            let tpm = [...selectProds];
            console.log(tpm ,' tpm');
            
            const posizioneProd = tpm.indexOf(prodotto);
            tpm[posizioneProd].iva = parseInputFloatValue({value : valoreNew});
            onChange('prods', tpm)
        
    }

    const getUnMisura = async () => {
        const array = await classSezione.getUnMisura()
        console.log(array.data , ' array.data');
        setArrayUnitaMisura(array.data)
    }

    console.log(arrayUnitaMisura , ' arrayUnitaMisura');

    const onChangeUmId = (prodotto , valore) => {
        let tpm = [...selectProds];
        const posizioneProd = tpm.indexOf(prodotto);
        tpm[posizioneProd].umId = parseInt(valore)
        onChange('prods', tpm)
    }
    

    const colonne = [
        {
            label: 'Codice interno',
            nome: 'rifInterno',
            altCol : (original)=> original?.Prodotti?.rifInterno || original?.rifInterno
        },
        {
            label: 'EAN',
            nome: 'ean',
            altCol : (original)=> original?.Prodotti?.ean || original?.ean
        },
        {
            label: 'Descrizione',
            nome: 'descrizione',
            altCol : (original)=> {
                return(
                    <ViewProdPopUp
                        icona={original?.Prodotti?.descrizione || original?.descrizione}
                        prodAnId={original?.prodottoId}
                    />
                )
            }
        },
        {
            label: 'Sezione',
            nome: 'infoSezione',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">
                        {original?.Sezioni?.nome || original?.infoSezione?.nome}
                    </span>
                )
            }
        },
        {
            label: 'Codice a barre',
            nome: 'codBarre',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">
                        {original?.Prodotti?.codBarre || original?.codBarre}
                    </span>
                )
            }
        },
        {
            label: 'Ricarico (%)',
            nome: 'ricarico',
            altCol : (original)=> {
                console.log(original , 'original ricarico');
                return(
                    <span className=" p-0 m-0">
                        {/* <input
                            name={'ricarico'}
                            type='text'
                            value={!!original?.ricarico ? original?.ricarico : (!!inputs?.anRicarico ?  parseFloat(inputs?.anRicarico) : 0)}
                            onChange={(event)=> cambiaRicarico(original , event.target.value)}
                            className="rounded h-100 w-50 text-center"
                        /> */}
                        <InputForm
                            label=' '
                            name={'ricarico'}
                            placeholder=''
                            type='text'
                            value={original?.ricarico}
                            onChange={(nome , valore)=> cambiaRicarico(original ,valore)}
                            className="rounded h-100 w-50 text-center"
                            errors={{}}
                        />
                    </span>
                )
            }
        },
        {
            label: 'Prezzo non ivato',
            nome: 'prezzoUnitario',
            altCol : (original)=> {
                console.log(original , 'original ricarico');
                return(
                    <span className=" p-0 m-0">
                        {/* <input
                            name={'prezzoUnitario'}
                            type='number'
                            defaultValue={'0'}
                            value={original?.prezzoUnitarioCent/100}
                            onChange={(event)=> cambiaPrezzoProd(original , event.target.value)}
                            className="rounded h-100 w-50 text-center"
                        /> */}
                        <InputForm
                            label=' '
                            name={'prezzoUnitario'}
                            placeholder=''
                            type='text'
                            value={original?.prezzoUnitario}
                            onChange={(nome , valore)=> cambiaPrezzoProd(original ,valore)}
                            className="rounded h-100 w-50 text-center"
                            errors={{}}
                        />
                    </span>
                )
            }
        },
        {
            label: 'IVA',
            nome: 'iva',
            altCol : (original)=> {
                console.log(original , 'original');
            return(
                <span className=" p-0 m-0">
                    <InputForm
                        label=' '
                        name={'iva'}
                        placeholder=''
                        type='text'
                        value={original?.iva}
                        onChange={(nome , valore)=> cambiaIvaProd(original ,valore)}
                        className="rounded h-100 w-50 text-center"
                        errors={{}}
                    />
                </span>
            )
        }
        },
        // {
        //     label: "Seriali",
        //     nome: 'Seriali',
        //     altCol : (item)=> {
        //         console.log(item , 'item');
        //         return(
        //             <>
        //                 <button className="btn" onClick={()=>setMostraSeriali(item)} >
        //                     <FontAwesomeIcon icon={faList} />
        //                 </button>
        //             </>
        //         )
        //     }
        // },
        {
            label: 'Quantità',
            nome: 'quantita',
            altCol : (original)=> {
             //   console.log(original , 'original f' ,original?.prezzoVendita);
                return(
                    <div>

                    {original?.quantita >=0 &&<input
                            name={'quantita'}
                            type='number'
                            defaultValue={'0'}
                            value={!!original?.quantita ? original?.quantita : 0}
                            max={!!original?.qta && original?.qta }
                            onChange={(event)=> cambiaQt(original , event.target.value)}
                            className="rounded h-100 w-50 text-center"
                        />}
                        <FormError errors={errors} field={'quantita'} />
                    </div>
                )
            }
        },
        {
            label : 'Unità di misura',
            nome: 'umId',
            altCol : (original)=> {
                console.log(original.umId , ' original.umId');
                
                return <div className=' m-2'>
                {!!original?.umId && <SelectForm
                    arrayElement={arrayUnitaMisura}
                    value={original.umId}
                    name='umId'
                    onChange={(nome , valore)=>onChangeUmId(original , valore)}
                    errors={[]}
                />}
            </div>
            }
        },
        {
            label: 'Elimina',
            nome: 'Elimina',
            altCol : (original)=> {
                return(
                    <>
                        <button onClick={()=>selezionaProdotto(original)} className="btn text-danger p-0">
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </>
                )
            }
        }

    ]

    
    const copiaSeriali = (event , prodSezId) => {
        const prods = [...selectProds];
        const prodsIds = prods.map(item=>  item.id)
        const position = prodsIds.indexOf(prodSezId)

        prods[position] ={...prods[position] , seriali : event.target.value}
        console.log(prodsIds, prodSezId, position , prods[position] , ' prodsIds, position , prods[position]');
        setSelectProds(prods)
        onChange('prods', prods)
    }


    console.log(inputs , ' inputs');

    const addRigaCodici = (id ,codice , qt)=> {
        let count = {...countRigaCodici}
        console.log(qt ,codice, ' qt ,codice');
        
        if (qt > (parseInt(codice))) {
            if (!count[id]) {
                count[id] = {
                    1 : 1 , 
                    2 : 1 , 
                }
            }else {
                count[id][codice] = 1
            }
        }
        
        setCountRigaCodici(count)
    }

    const addCodiceSeriale = (id , posizione , seriale) => {
        let tmp = {...codici}
        
        tmp[id+'S'+posizione] = seriale
        setCodici(tmp)
        onChange('codici' , tmp)
    }

    const addCodiceProvisioning = (id , posizione , codice) => {
        let tmp = {...codici}
        
        tmp[id+'P'+posizione] = codice
        setCodici(tmp)
        onChange('codici' , tmp)
    }

    console.log(countRigaCodici ,' countRigaCodici');
    console.log(codici , 'codici');
    
    

    return(
        <div  className="border border-primary border-3 p-3 rounded-4 m-3 ">
            <div className="d-flex flex-wrap align-items-center ms-4 mt-5">
                {/* <button className="btn btn-primary me-4" onClick={()=>setViewSelectProds(true)} >Seleziona Articoli</button> */}
                <SelezionaProdSituazioneDisp
                prodsIds={selectProds.map(item => item.pid)}
                classiBtn={' btn-primary '}
                iconButton='Seleziona articoli'
                selezionaProdotto={selezionaProdotto}
                magazzinoId={inputs?.magazzinoId}
                inputs={inputs}
            />
                <p className="p-0 m-0 text-danger fst-italic" >*Selezionare almeno un articolo per salvare </p>
            </div>


            {/* <Tabella
                data={selectProds}
                columns={columns}
            /> */}
            <TableSemp
                colonne={colonne}
                dati={selectProds}
                infoAdd={
                    <div className="col-12 my-5 d-flex justify-content-end" >
                        <div className=" btn btn-primary h-100 m-2 ms-4">Imponibile : € {(inputs?.imponibileCent/100).toFixed(2).replace('.', ',')} </div>
                        <div className="col-2 btn btn-primary h-100 m-2 ms-4">Imposta : € {(inputs?.impostaCent/100).toFixed(2).replace('.', ',')} </div> 
                        <div className="col-2 btn btn-primary h-100 m-2 ms-4">Importo : € {(inputs?.totFatturaCent/100).toFixed(2).replace('.', ',')} </div> 
                    </div>
                }
                  secondaRiga={ (item) => {
                    console.log(item , 'item seriali');
                    
                    // return(
                    //     <td colSpan="11">
                    //         <div className="d-flex flex-wrap align-items-center">
                    //             <div className="col-12 d-flex flex-wrap align-items-center">
                    //                 <p className="p-0 m-0 col-2 ps-5 ms-5 me-5 ">
                    //                     Seriali :
                    //                 </p>
                    //                 <p className="p-0 m-0 col-3 ps-5 ms-5">
                    //                     Provisioning :
                    //                 </p>

                    //             </div>
                    //             <div className="col-8 py-3">
                    //                 {/* <textarea className="form-control" name="" id="" onChange={(e)=>copiaSeriali(e ,item.id)} >
                    //                     {item.seriali}
                    //                 </textarea> */}
                    //                 {countRigaCodici[item.id || item.prodottoId] ? 
                    //                 <>
                    //                 {Object.keys(countRigaCodici[item.id || item.prodottoId ]).map(codice => {
                    //                     console.log(codice , ' codice' , (item.id || item.prodottoId)+'S'+codice);
                                        
                    //                     return(
                    //                         <div className="d-flex flex-wrap" key={codice}>
                    //                             <InputForm
                    //                                 name={'seriali'}
                    //                                 type='text'
                    //                                 label={' '}
                    //                                 value={codici[(item.id || item.prodottoId)+'S'+codice]}
                    //                                 onChange={(nome , valore)=>addCodiceSeriale(item.id || item.prodottoId , codice , valore)}
                    //                                 classi=" col-4 m-0 p-0 d-flex "
                    //                                 errors={{}}
                    //                                 onClick={()=>addRigaCodici(item.id || item.prodottoId ,parseInt(codice)+1 ,item.quantita )}
                    //                             />
                    //                             <InputForm
                    //                                 name={'seriali'}
                    //                                 type='text'
                    //                                 label={' '}
                    //                                 value={codici[(item.id || item.prodottoId)+'P'+codice]}
                    //                                 onChange={(nome , valore)=>addCodiceProvisioning(item.id || item.prodottoId , codice , valore)}
                    //                                 classi=" col-4 m-0 p-0 d-flex "
                    //                                 errors={{}}
                    //                                 onClick={()=>addRigaCodici(item.id || item.prodottoId ,parseInt(codice)+1,item.quantita)}
                    //                             />
                    //                         </div>
                    //                     )
                    //                 })
                    //                 }
                    //                 </>
                    //                 :
                    //                 <div className="d-flex flex-wrap" >
                    //                             <InputForm
                    //                                 name={'seriali'}
                    //                                 type='text'
                    //                                 label={' '}
                    //                                 value={codici[(item.id || item.prodottoId)+'S'+0]}
                    //                                 onChange={(nome , valore)=>addCodiceSeriale(item.id || item.prodottoId , 1 , valore)}
                    //                                 classi=" col-4 m-0 p-0 d-flex "
                    //                                 errors={{}}
                    //                                 onClick={()=>{addRigaCodici(item.prodottoId , 1 , item.quantita )}}
                    //                             />
                    //                             <InputForm
                    //                                 name={'seriali'}
                    //                                 type='text'
                    //                                 label={' '}
                    //                                 value={codici[(item.id || item.prodottoId)+'P'+0]}
                    //                                 onChange={(nome , valore)=>addCodiceProvisioning(item.prodottoId , 1 , valore)}
                    //                                 classi=" col-4 m-0 p-0 d-flex "
                    //                                 errors={{}}
                    //                                 onClick={()=>addRigaCodici(item.prodottoId , 1 , item.quantita)}
                    //                             />
                    //                 </div>
                    //                 }
                    //             </div>
                    //         </div>
                    //         <p>
                    //             (Inserire ";" alla fine di ogni seriale)
                    //         </p>
                    //         <FormError errors={errors} field='seriali' />
                    //     </td>
                    // )

                    return (
                        <CodiciForms item={item} onChange={onChange} prods={inputs?.prods} errors={errors} />
                    )
                }}
            />
            <FormError errors={errors} field='prodotti' />

            {viewSelectProds &&<QuestionModal
                title={'Seleziona articolo dal '+inputs?.magazzinoNome+inputs?.tipoMag !== 'Magazzino' ? (' - Magazzino ti tipo '+inputs?.tipoMag ) :'' }
                text={
                    <SelezionaProdSezione magazzinoId={inputs?.magazzinoId} prodIdSelezionati={selectProds.map(item => item.id)} onChange={selezionaProdotto} />
                }
                onClose={()=>{setViewSelectProds(false); cambiaRicarico(false , false) }}
                classi={'modal-xxl'}
            />}

            {!!mostraSeriali && 
             <InfoModal
                size='xl'
                title={'Seriali prodotto '+mostraSeriali.nome+' - '+mostraSeriali.rifInterno}
                text={
                    <div>
                        {mostraSeriali.ProdSerialis.length > 0 ?
                        <ul>
                            {mostraSeriali.ProdSerialis.map((item , key) => {
                                return(
                                    <li>
                                        {item.seriale}
                                    </li>
                                )
                            })}
                        </ul>
                        :
                        <div>
                            Nessun seriale presente
                        </div>
                        }
                    </div>
                }
                canClose={true}
                handleClose={()=>setMostraSeriali(false)}
             />
            }

        </div>
    )
}
