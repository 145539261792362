import catProd_fetch from "../fetch/catProd_fetch";
import create_autocompleteWithService from "../riutilizabili/autocomplite/create_autocompleteWithService";
import InputForm from "../riutilizabili/forms/InputForm";



export default function Campi({
    values ,
    onChange,
    errors
}) {

    const Categorie = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => catProd_fetch.get({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona categoria padre' ,
        value: values?.categoria_nome ,
        onChange : ({value, item}) => onChangeCategorie(item) ,
        placeholder: 'inserisci nome categoria padre',
        redflag : !!errors?.categoriaId ,

    });

    const onChangeCategorie = (item) => {
        if (item) {
            onChange('catPadreId' , item.id)
            onChange('categoria_nome' , item.nome ) ; 
            onChange('catPadreId' , item.id)
        }else {
            onChange('categoria_nome' , "") ;
            onChange('catPadreId' , "" )
        }
    }

    return(
        <div className="d-flex flex-wrap" >
            <InputForm
                label='Nome'
                name='nome'
                value={!!values?.nome ? values?.nome : ''}
                onChange={onChange}
                errors={errors}
                classi={' col-2 '}
            />
            <div className="col-3 m-3">
                <Categorie />
            </div>
        </div>
    )
    
}